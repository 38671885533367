import React, {useEffect, useState} from "react";
import {Col, Row} from "antd";
import CoordinacionSinTituloTable from "./CoordinacionSinTituloTable";
import {showCoord} from "../../Servicios/CoordService";
import {hasRol} from "../../Servicios/AuthService";
import CoordinacionSinTituloFilter from "./CoordinacionSinTituloFilter";
import moment from "moment/moment";
import roles from "../../utils/roles";
import ShowWhen from "../../Componentes/ShowWhen/ShowWhen";
import {updateVehiculoTituloEstado} from "../../Servicios/VehicleService";
import localStorageFilter from "../../shared/utils/local-storage-filter";
import OrigenTab from "../../Componentes/OrigenTab/OrigenTab";
import {useMainContext} from "../../contexts/MainContext";

const XLSX = require("xlsx");

interface CoordinacionFilters {
  origen: number;
  pageCount: number;
  currentPage: number;
  vehiculoTituloEstado: string;
  search: string;
  aduanaId: number;
  transporteId: number;
  countriesId: string;
  currentTab: string;
  subastaId: number;
  vendedorId: number;
  fromDate: Date | undefined;
  toDate: Date | undefined;
}

const CoordinacionSinTituloPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [origenName, setOrigenName] = useState(false);
  const [totalElements, setTotalElements] = useState(0);

  const [coordi, setCoordi] = useState([]);

  const { origenes } = useMainContext();

  const [coordinacionFilters, setCoordinacionFilters] = useState({
    origen: localStorageFilter('coordinacionRecibidosSinTitulo:filter', 'origen') ?? 1,
    pageCount: localStorageFilter('coordinacionRecibidosSinTitulo:filter', 'pageCount') ?? 100,
    currentPage: localStorageFilter('coordinacionRecibidosSinTitulo:filter', 'currentPage') ?? 1,
    vehiculoTituloEstado: localStorageFilter('coordinacionRecibidosSinTitulo:filter', 'vehiculoTituloEstado') ?? "",
    search: localStorageFilter('coordinacionRecibidosSinTitulo:filter', 'search') ?? "",
    aduanaId: localStorageFilter('coordinacionRecibidosSinTitulo:filter', 'aduanaId') ?? 0,
    transporteId: localStorageFilter('coordinacionRecibidosSinTitulo:filter', 'transporteId') ?? 0,
    countriesId: localStorageFilter('coordinacionRecibidosSinTitulo:filter', 'countriesId') ?? "",
    currentTab: localStorageFilter('coordinacionRecibidosSinTitulo:filter', 'currentTab') ?? "0",
    subastaId: localStorageFilter('coordinacionRecibidosSinTitulo:filter', 'subastaId') ?? 0,
    vendedorId: localStorageFilter('coordinacionRecibidosSinTitulo:filter', 'vendedorId') ?? 0,
    fromDate: localStorageFilter('coordinacionRecibidosSinTitulo:filter', 'fromDate', true),
    toDate: localStorageFilter('coordinacionRecibidosSinTitulo:filter', 'toDate', true),
  });

  useEffect(() => {
    showCoordinaciones(coordinacionFilters).then();

    const filters = localStorageFilter();
    if (JSON.stringify(coordinacionFilters) !== JSON.stringify(filters)) {
      localStorage.setItem('coordinacionRecibidosSinTitulo:filter', JSON.stringify(coordinacionFilters));
    }

    const origen = origenes.find(origen => +origen.id === +coordinacionFilters.origen);
    setOrigenName(origen ? origen.nameOrigen : "");
  }, [coordinacionFilters]);

  const showCoordinaciones = async (filters: CoordinacionFilters) => {
    let origenId = filters.origen;
    if (hasRol(roles.FLOFFICE)) origenId = 1;
    else if (hasRol(roles.CAOFFICE)) origenId = 2;
    else if (hasRol(roles.PAOFFICE)) origenId = 3;

    setIsLoading(true);
    const data = await searchDomestics(origenId, filters, filters.currentPage, filters.pageCount);
    setIsLoading(false);

    if (data.status) {
      setCoordi(data.list);
      setTotalElements(data.totalElements);
    }
  };

  const searchDomestics = async (origenId, filters, currentPage, pageCount) => {
    return showCoord(
      origenId,
      "",
      0,
      0,
      currentPage,
      pageCount,
      filters.search,
      "",
      filters.aduanaId,
      0,
      0,
      0,
      filters.fromDate ? filters.fromDate.getTime() : 0,
      filters.toDate ? filters.toDate.getTime() : 0,
      filters.countriesId,
      filters.subastaId,
      0,
      filters.transporteId,
      1,
      1,
      filters.vehiculoTituloEstado,
      filters.currentTab === '0' || filters.currentTab === '2'
        ? 0 // filtrar por titulos pendientes
        : 1, // filtrar por titulos recibidos
      filters.vendedorId,
      "",
      filters.currentTab === '2' || filters.currentTab === '3'
        ? 1 // filtrar por entregas en bodega por cliente
        : -1 // filtrar sin entregas en bodega por cliente
    );
  }

  const exportToExcel = async () => {
    setIsLoading(true);
    const data = await searchDomestics(coordinacionFilters.origen, coordinacionFilters, 0, 0);
    setIsLoading(false);

    if (data && data.status) {
      const list = data.list.map(e => {
        return {
          'Lote': e.lote,
          'Fecha': e.vehiculo && e.vehiculo.fecha ? moment(e.vehiculo.fecha).format('l') : '---',
          'Vehiculo': e.vehiculoName,
          // 'Vin': e.vin,
          // 'Color': e.vehiculo?.color,
          '# Buyer': e.buyer,
          'Ubic./Subasta': e.subs,
          'Importadora': e.import,
          'Vendedor': e.vendedor,
          'Estado': e.vehiculo.tituloEstado ?? '---',
          'Destino': e.aduanaName,
          //'Transporte': e.transporteName ?? 'No Asignado',
          //'# Telefonico': e.transporte ? e.transporte.telefono : 'No Asignado',
        }
      });

      const worksheet = XLSX.utils.json_to_sheet(list);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Coordinaciones Sin Titulo");

      // Genera el archivo Excel
      XLSX.writeFile(workbook, "coordinaciones.xlsx", {
        bookType: "xlsx",
        type: "blob",
      });
    }
  }

  const handleChangeTab = tab => {
    setCoordinacionFilters({
      ...coordinacionFilters,
      origen: tab
    })
  };

  const titulo = () => {
    if (hasRol(roles.FLOFFICE)) return ' - Florida';
    if (hasRol(roles.CAOFFICE)) return ' - California';
    if (hasRol(roles.PAOFFICE)) return ' - Pennsylvania';

    if (!coordinacionFilters.origen) return "";
    return ' - ' + origenName;
  }

  const handleUpdateVehiculoTituloEstado = async (input) => {
    setIsLoading(true);
    const data = await updateVehiculoTituloEstado(input.id, input);
    setIsLoading(false);

    if (data && data.status) {
      await showCoordinaciones(coordinacionFilters);
    }
  }

  return(
    <>
      <Row>
        <Col xs={24} md={24} lg={24} className="">
          <h5 className="mb-2">Historial de Coordinaciones Recibidas sin Título {titulo()}</h5>
          <ShowWhen show={!hasRol(roles.FLOFFICE, roles.CAOFFICE)}>
            <OrigenTab selectedOrigen={coordinacionFilters.origen} onChange={handleChangeTab} />
          </ShowWhen>
        </Col>
      </Row>
      <CoordinacionSinTituloFilter coordinacionFilters={coordinacionFilters}
                                   onFilter={filter => setCoordinacionFilters(filter)} />
      <CoordinacionSinTituloTable totalElements={totalElements}
                                  coordi={coordi}
                                  isLoading={isLoading}
                                  exportToExcel={exportToExcel}
                                  coordinacionFilters={coordinacionFilters}
                                  setCoordinacionFilters={filter => setCoordinacionFilters(filter)}
                                  onUpdateVehiculoTituloEstado={handleUpdateVehiculoTituloEstado} />
    </>
  );
}

export default CoordinacionSinTituloPage;
