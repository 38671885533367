const roles = {
  ADMIN: 'ADMIN',

  VENTAS: 'VENTAS',
  VENTAS_SV: 'VENTAS-SV',
  VENTAS_GT: 'VENTAS-GT',
  VENTAS_CR: 'VENTAS-CR',
  VENTAS_HN: 'VENTAS-HN',

  CAOFFICE: 'CAOFFICE',
  FLOFFICE: 'FLOFFICE',
  PAOFFICE: 'PAOFFICE',

  RECIVING_CA: 'RECIVING-CA',
  RECIVING_FL: 'RECIVING-FL',
  RECIVING_PA: 'RECIVING-PA',

  COBROS: 'COBROS',
  VEHICULOS: 'VEHICULOS',
  SUPERVISOR: 'SUPERVISOR',

  ATENCION_CLIENTE: 'ATENCION-AL-CLIENTE',

  WR_OFFICE_FL: 'WR-OFFICE-FL',
  WR_OFFICE_CA: 'WR-OFFICE-CA',
  WR_OFFICE_PA: 'WR-OFFICE-PA',
}

export default roles;
