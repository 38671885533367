

import{server} from '../environments/environment';
import {ApiService} from "./ApiService";


export const getTracking = async (lote) => {
    const response = await fetch(`${server}Tracking/${lote}`);
    const data = await response.json();
    return data;
};


export const getTrackingByLoteOrVim = async (lote_or_vim) => {
    const response = await ApiService.get(`${server}tracking/private/${lote_or_vim}`);
    const data = await response.json();
    console.log(data, 'data tracking');
    return data;
};
    
