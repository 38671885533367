import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Grid,
  Modal,
  Skeleton,
  Button,
  DatePicker,
  Empty,
} from "antd";
import Typography from "@mui/material/Typography";
import WalletCountCard from "../../../Componentes/widgets/wallletCountCard";
import { useTheme } from "@mui/material/styles";
import todoterreno from "../../../image/todoterreno.png";
import type { DatePickerProps } from "antd";
import dayjs from "dayjs";

import {
  totalContenedoresPorOrigen,
  totalContenedoresPorDestino,
} from "../../../Servicios/Home";

const { useBreakpoint } = Grid;

const TotalContenedoresPorOrigen = ({ user }) => {
  const theme = useTheme();

  const [totalVehicle, setTotalVehicle] = useState(null);
  const [totalVehicleDestino, setTotalVehicleDestino] = useState(null);
  const [detalleTotalCountry, setDetalleTotalCountry] = useState(null);
  const [dateOrigin, setDateOrigin] = useState(dayjs().valueOf());
  const [showDetailDestino, setShowDetailDestino] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDestino, setLoadingDestino] = useState(false);
  const [open, setOpen] = useState(false);
  const screens = useBreakpoint();

  useEffect(() => {
    console.log(dateOrigin, "origen");
    setLoading(true);
    totalContenedoresPorOrigen(dateOrigin)
      .then((data) => {
        setTotalVehicle(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dateOrigin]);

  const HandleOpen = (idPais) => {
    setLoadingDestino(true);
    setOpen(true);
    totalContenedoresPorDestino(idPais, dateOrigin, 0)
      .then((data) => {
        setTotalVehicleDestino(data);
      })
      .finally(() => {
        setLoadingDestino(false);
      });
  };

  const HandleOpenDetalle = (destinos) => {
    setDetalleTotalCountry(destinos);
    setShowDetailDestino(true);
  };

  const HandleBack = () => {
    setShowDetailDestino(false);
  };

  const onChange = (date, dateString) => {
    setDateOrigin(dayjs(dateString).valueOf());
  };

  return (
    <Card
      title="Detalle de embarques"
      style={{
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        height: "100%",
      }}
      loading={loading}
    >
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <DatePicker
            onChange={onChange}
            style={{
              width: "100%",
            }}
            defaultValue={dayjs(dateOrigin)}
            allowClear={false}
          />
        </Col>
      </Row>
      <br />
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row gutter={24}>
            {totalVehicle?.map((item, index) => (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                offset={
                  totalVehicle?.length % 2 === 1 && // Si el número total de tarjetas es impar
                  index === totalVehicle?.length - 1 && // Si es la última tarjeta
                  (screens.md || screens.lg || screens.xl) // Y el tamaño actual es md o mayor
                    ? 6
                    : 0
                }
                style={{
                  marginBottom: "20px",
                }}
                key={index}
              >
                <WalletCountCard
                  primary={item.origen}
                  secondary={item.total}
                  tertiary={item.totalVehiculos}
                  textTertiary="Contenedores: "
                  showtertiary={true}
                  color="linear-gradient(135deg,rgb(0, 38, 255) 0%,rgb(24, 155, 255) 100%)"
                  iconPrimary={todoterreno}
                  pressable={true}
                  onClick={() => HandleOpen(item.origenId)}
                />

                <Modal
                  title="Detalles total de embarque por pais"
                  open={open}
                  onCancel={() => setOpen(false)}
                  onOk={() => setOpen(false)}
                  footer={null}
                  width={"80%"}
                >
                  <Skeleton loading={loadingDestino} active>
                    {!showDetailDestino ? (
                      <>
                        <Row gutter={24} justify="center">
                          <h1>Paises</h1>
                        </Row>
                        <Row gutter={24}>
                          {totalVehicleDestino
                            ?.filter((item) => item.total > 0)
                            .map((item, index) => (
                              <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={12}
                                xl={12}
                                offset={
                                  totalVehicleDestino?.length % 2 === 1 &&
                                  index === totalVehicleDestino?.length - 1 &&
                                  (screens.md || screens.lg || screens.xl)
                                    ? 6
                                    : 0
                                }
                                style={{
                                  marginBottom: "20px",
                                }}
                                key={index}
                              >
                                <WalletCountCard
                                  primary={item.pais}
                                  secondary={item.total}
                                  tertiary={item.totalVehiculos}
                                  textTertiary="Contenedores: "
                                  showtertiary={true}
                                  color="linear-gradient(135deg,rgb(0, 195, 255) 0%,rgb(24, 155, 255) 100%)"
                                  iconPrimary={todoterreno}
                                  pressable={true}
                                  onClick={() =>
                                    HandleOpenDetalle(item.destinos)
                                  }
                                />
                              </Col>
                            ))}
                        </Row>
                        {totalVehicleDestino?.filter((item) => item.total > 0)
                          .length === 0 && (
                          <Row gutter={24} justify="center">
                            <Empty />
                          </Row>
                        )}
                      </>
                    ) : (
                      <>
                        <Row
                          gutter={[24, 24]}
                          justify="space-between"
                          style={{ margin: "5px" }}
                        >
                          <h1>Aduanas</h1>
                          <Button type="primary" onClick={HandleBack}>
                            Volver
                          </Button>
                        </Row>
                        <Row gutter={24}>
                          {detalleTotalCountry
                            ?.filter((item) => item.total > 0)
                            .map((item, index) => (
                              <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={12}
                                xl={12}
                                offset={
                                  detalleTotalCountry?.length % 2 === 1 && // Si el número total de tarjetas es impar
                                  index === detalleTotalCountry?.length - 1 && // Si es la última tarjeta
                                  (screens.md || screens.lg || screens.xl) // Y el tamaño actual es md o mayor
                                    ? 6
                                    : 0
                                }
                                style={{
                                  marginBottom: "50px",
                                }}
                                key={index}
                              >
                                <WalletCountCard
                                  primary={item.destino}
                                  secondary={item.total}
                                  tertiary={item.totalVehiculos}
                                  textTertiary="Contenedores: "
                                  showtertiary={true}
                                  color="linear-gradient(135deg,rgb(255, 145, 0) 0%,rgb(255, 128, 24) 100%)"
                                  iconPrimary={todoterreno}
                                />
                              </Col>
                            ))}
                        </Row>
                      </>
                    )}
                  </Skeleton>
                </Modal>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default TotalContenedoresPorOrigen;
