import {Button, Col, Form, Input, Row, Select} from "antd";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import {showStates} from "../../Servicios/WerehouseService";
import {showOrigen} from "../../Servicios/CombosService";
import {addSubasta, editSub} from "../../Servicios/ComponedoresService";
import {errorMessage, successMessage} from "../../utils/toast-message";
import Spinner from "../../shared/spinner";
import selectFilterOptions from "../../shared/utils/select-filter-options";

const SubastaForm = ({ subasta, onCancel, afterSave, titulo }) => {
  const [form] = Form.useForm();
  const [states, setStates] = useState([]);
  const [origenes, setOrigenes] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const regex = /^\(\d{3}\) \d{3}-\d{4}$/;

  useEffect(() => {
    form.setFieldsValue({
      subName: subasta ? subasta?.subName : '',
      addressLine1Sub: subasta ? subasta?.addressLine1Sub : '',
      addressLine2Sub: subasta ? subasta?.addressLine2Sub : '',
      city: subasta ? subasta?.city : '',
      state: subasta ? subasta?.state : '',
      zipCode: subasta ? subasta?.zipCode : '',
      identf: subasta ? subasta?.identf : '',
      phoneNumber: subasta ? formatearTelefono(subasta?.phoneNumber) : '',
      origen: subasta ? subasta?.origen : null,
      identfType: subasta ? subasta?.identfType : '',
      contactFirstName: subasta ? subasta?.contactFirstName : '',
      contactLastName: subasta ? subasta?.contactLastName : '',
    });
  }, [subasta]);

  useEffect(() => {
    init().then();
  }, []);

  const formatearTelefono = (numero: string) => {
    if (!numero) return numero;
    if (regex.test(numero)) {
      return numero;
    }

    // Elimina cualquier carácter que no sea un dígito
    const soloNumeros = numero.replace(/\D/g, "");

    // Verifica que tenga exactamente 10 dígitos
    if (soloNumeros.length !== 10) {
      return soloNumeros.toString();
    }

    // Aplica el formato (000) 000-0000
    return soloNumeros.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }

  const init = async () => {
    const states = await showStates();
    setStates(states);

    const origenes = await showOrigen();
    setOrigenes(origenes);
  }

  const handleSubmit = (values) => {
    if (!regex.test(values.phoneNumber)) {
      errorMessage('El Teléfono esta mal escrito');
      return;
    }

    if (values.contactFirstName && values.contactFirstName.trim().split(' ').length > 1) {
      errorMessage('Solo puede escribir un nombre de contacto');
      return;
    }

    if (values.contactLastName && values.contactLastName.trim().split(' ').length > 1) {
      errorMessage('Solo puede escribir un apellido de contacto');
      return;
    }

    if (subasta) {
      editarSubasta(values).then();
    } else {
      insertarSubasta(values).then();
    }
  }

  const insertarSubasta = async (values) => {
    try {
      const input = {
        ...values,
        state: +values.state,
        origen: +values.origen,
      }

      setLoading(true);
      const data = await addSubasta(input);
      setLoading(false);

      if (data) {
        successMessage(`${titulo ?? 'Subasta'} agregado correctamente`);
        form.resetFields();
        if (afterSave) afterSave(data);
      }
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  };

  const editarSubasta = async (values) => {
    try {
      const input = {
        ...values,
        state: +values.state,
        origen: +values.origen,
      };

      setLoading(true);
      const data = await editSub(subasta.subId, input);
      setLoading(false);

      if (data) {
        successMessage(`${titulo ?? 'Subasta'} modificado correctamente`);
        form.resetFields();
        if (afterSave) afterSave(data);
      }
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  }

  const handleChange = (event) => {
    // Elimina cualquier carácter no numérico
    let input = event.target.value.replace(/\D/g, "");

    // Limita el número de caracteres a 10
    if (input.length > 10) {
      input = input.slice(0, 10);
    }

    // Aplica el formato (000) 000-0000
    if (input.length > 6) {
      input = `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6)}`;
    } else if (input.length > 3) {
      input = `(${input.slice(0, 3)}) ${input.slice(3)}`;
    } else if (input.length > 0) {
      input = `(${input}`;
    }

    form.setFieldValue('phoneNumber', input);
  };

  return(
    <>
      <Spinner loading={isLoading}>
        <Form form={form}
              layout={'vertical'}
              onFinish={handleSubmit}>
          <Form.Item name="subName" label={`Nombre ${titulo ?? 'Subasta'}`}
                     rules={[
                       { required: true, message: 'El nombre es un campo requerido'}
                     ]}>
            <Input placeholder={`Nombre ${(titulo ?? 'Subasta')}`}/>
          </Form.Item>

          <Row gutter={16}>
            <Col md={8}>
              <Form.Item name="identfType" label={'Tipo de Identificación'}
                         rules={[
                           { required: true, message: 'El Tipo de Identificación es un campo requerido'}
                         ]}>
                <Select className="w-100"
                        options={[
                          {value: '# EIN', label: '# EIN'},
                          {value: '# Pasaporte', label: '# Pasaporte'}
                        ]}>
                </Select>
              </Form.Item>
            </Col>
            <Col md={16}>
              <Form.Item name="identf"
                         label={'Identificación'}
                         rules={[
                           { required: true, message: 'La Identificación es un campo requerido'}
                         ]}>
                <Input placeholder="Identifitacion"/>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="addressLine1Sub" label={'Address line 1'}
                     rules={[
                       { required: true, message: 'La Dirección es un campo requerido'},
                       { max: 30, message: 'Ha alcanzado el maximo permitido en este campo' },
                     ]}>
            <Input placeholder="Address line 1"/>
          </Form.Item>

          <Form.Item name="addressLine2Sub" label={'Address line 2'}
                     rules={[
                       { max: 30, message: 'Ha alcanzado el maximo permitido en este campo' },
                     ]}>
            <Input placeholder="Address line 2"/>
          </Form.Item>

          <Row gutter={16}>
            <Col md={12}>
              <Form.Item name="city" label={'City'}
                         rules={[
                           { required: true, message: 'La Ciudad es un campo requerido'}
                         ]}>
                <Input placeholder="City"/>
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label={'Zip Code'} name="zipCode"
                         rules={[
                           { required: true, message: 'El Zip Code es un campo requerido' },
                         ]}>
                <Input placeholder="Zip Code"/>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label={'Phone Number'} name="phoneNumber"
                     rules={[
                       { required: true, message: 'El Teléfono es un campo requerido'},
                       { pattern: /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/, message: 'El formato del Teléfono debe ser (000) 000-0000' }
                     ]}>
            <Input id={'phoneNumber'} placeholder="(000) 000-0000" onChange={handleChange} />
          </Form.Item>

          <Row gutter={16}>
            <Col md={12}>
              <Form.Item label={'State'} name="state"
                         rules={[
                           { required: true, message: 'El Estado es un campo requerido'}
                         ]}>
                <Select showSearch={true}
                        filterOption={selectFilterOptions}
                        options={states && states?.length
                  ? states?.map(state => ({label:`${state?.smallState} - ${state?.state}`, value: state?.stateId}))
                  : []}/>
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label={'Origen - Office Matus'} name="origen"
                         rules={[
                           { required: true, message: 'El Origen es un campo requerido'}
                         ]}>
                <Select options={origenes && origenes?.length
                  ? origenes?.map(origen => ({label: origen?.nameOrigen, value: origen?.id}))
                  : []}/>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={12}>
              <Form.Item label={'Contact First Name'} name="contactFirstName"
                         rules={[
                           { required: true, message: 'El Contacto es un campo requerido'}
                         ]}>
                <Input placeholder="Contact First Name"/>
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label={'Contact Last Name'} name="contactLastName"
                         rules={[
                           { required: true, message: 'El Contacto es un campo requerido'}
                         ]}>
                <Input placeholder="Contact Last Name"/>
              </Form.Item>
            </Col>
          </Row>
          <div className="text-end mt-3">
            <Button type="default" htmlType={'button'} className="me-3" onClick={() => {
              form.resetFields();
              if (onCancel) onCancel();
            }}>Cancelar</Button>
            <Button type="primary" htmlType={'submit'}>
              <FontAwesomeIcon className="me-2" icon={faFloppyDisk}/> {subasta ? 'Actualizar' : 'Guardar'}
            </Button>
          </div>
        </Form>
      </Spinner>
    </>
  );
}

export default SubastaForm;
