import { useState } from "react";
import { Modal, Typography } from "antd";
import PropTypes from "prop-types";

export const ImagePreview = ({ src, alt, type = "image", className }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      {type === "image" ? (
        <button onClick={() => setVisible(true)} aria-label={`Preview ${alt}`}>
          <img src={src} alt={alt} className={className}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </button>
      ) : (
        <Typography.Link onClick={() => setVisible(true)} underline>
          Hacer click, para ver foto
        </Typography.Link>
      )}
      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={800}
      >
        <img
          src={src}
          alt="preview"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Modal>
    </div>
  );
};

ImagePreview.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  type: PropTypes.string,
};
