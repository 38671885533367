import { server } from "../environments/environment";
import {ApiService} from "./ApiService";
export const getContainer = async () => {
  const response = await ApiService.get(`${server}Container`);
  const { list } = await response.json();
  return list;
};

export const getColor = async () => {
  const response = await ApiService.get(`${server}ConosClrs`);
  const { list } = await response.json();
  return list;
};

export const getExportationList = async (currentPage = 0, pageCount = 0, origen = 0, state = "", cbpStatus = "",
                                         search = "", idCountries = "") => {
  let params = {
    origen: origen,
    pageCount: pageCount,
    currentPage: currentPage,
  };

  if (state !== "") params['state'] = state;
  if (search !== "") params['search'] = search;
  if (cbpStatus !== "") params['cbpStatus'] = cbpStatus;
  if (idCountries !== "") params['idCountries'] = idCountries;

  const queryParams = new URLSearchParams(params).toString();
  const response = await ApiService.get(`${server}Exportation/list?${queryParams}`);

  const data = await response.json();
  if (!data.status)
    throw new Error(data.message);
  return data;
};

export const getExportationDetailList = async (idExportation) => {
  const response = await ApiService.get(`${server}DetalleExport/list/${idExportation}`);

  const data = await response.json();
  if (!data.status)
    throw new Error(data.message);
  return data;
};

export const getExportationDetailByContainerList = async (idExportationContainer) => {
  const response = await ApiService.get(`${server}DetalleExport/list/${idExportationContainer}/exportationContainer`);

  const data = await response.json();
  if (!data.status)
    throw new Error(data.message);
  return data;
};

export const getDetalleExport = async (createdDate = 0, loadingDate = 0, currentPage = 0, pageCount = 0,
                                       exportationContainerState = "", exportationContainerAduanaId = 0,
                                       exportationContainerCarrierId = 0, fromDate = 0, toDate = 0,
                                       exportationContainerOrigenId = 0, countryId = 0, departureFromDate = 0,
                                       departureToDate = 0) => {
  const params = {
    createdDate,
    loadingDate,
    currentPage,
    pageCount,
    exportationContainerAduanaId,
    exportationContainerCarrierId,
    fromDate,
    toDate,
    exportationContainerOrigenId,
    countryId,
    departureFromDate,
    departureToDate
  };

  if (exportationContainerState !== "") params['exportationContainerState'] = exportationContainerState;

  const queryParams = new URLSearchParams(params).toString();
  const response = await ApiService.get(`${server}DetalleExport?${queryParams}`);

  return response.json();
};

export const getDetalleExportById = async (id) => {
  const response = await ApiService.get(`${server}DetalleExport?codesec=${id}`);
  return response.json();
};

export const getWareHouse = async (idDestino, idOrigen, available = false ) => {
  let url =  `${server}Werehouse`+(idDestino ? `?idDestino=${idDestino}` : '')+`${idDestino?'&':'?'}`+(idOrigen ? `idOrigen=${idOrigen}` : '')+(available ? `&available=${available}` : '');
  const response = await ApiService.get(`${url}`);
  const { list } = await response.json();
  return list;
};

export const getAduana = async () => {
  const response = await ApiService.get(`${server}Aduana`);
  const { list } = await response.json();
  return list;
};

export const getSubastas = async () => {
  const response = await ApiService.get(`${server}Subasta`);
  const { list } = await response.json();
  return list;
}

export const getImportadora = async () => {
  const response = await ApiService.get(`${server}Importadora`);
  const { list } = await response.json();
  return list;
}

export const getServiceType = async () => {
  const response = await ApiService.get(`${server}Servic`);
  const { list } = await response.json();
  return list;
}

export const getModeTransportation = async () => {
  const response = await ApiService.get(`${server}ModeExport`);
  const { list } = await response.json();
  return list;
}

export const getPortCode = async () => {
  const response = await ApiService.get(`${server}PortCode`);
  const { list } = await response.json();
  return list;
}

export const getPortCodeporId = async (id) => {
  const response = await ApiService.get(`${server}PortCode/${id}`);
  const { data } = await response.json();
  return data;
}

export const addPortCode = async (params) => {
  const body = {
    ...params,
    state: true,
  };

  const response = await ApiService.post(`${server}PortCode`, body);
  const data = await response.json();

  if (data && data.status) {
    return data.data;
  }

  throw new Error(data.message);
}

export const updatePortCode = async (id, params) => {
  const body = {
    ...params,
    state: true,
  };

  const response = await ApiService.put(`${server}PortCode/${id}`, body);
  const data = await response.json();

  if (data && data.status) {
    return data.data;
  }

  throw new Error(data.message);
}

export const addPortCodeDestination = async (params) => {
  const body = {
    ...params,
    state: true,
    typeState: 'Destination',
  };

  const response = await ApiService.post(`${server}PortCode`, body);
  const { data } = await response.json();

  return data;
}


export const saveExportation = async (params) => {
  const response = await ApiService.post(`${server}Exportation`, params);
  const resp = await response.json();

  if (resp && resp.status) {
    return resp.data;
  }

  throw new Error(resp.message);
};

export const updateExportation = async (exportationId, params) => {
  const response = await ApiService.put(`${server}Exportation/${exportationId}`, params);
  const resp = await response.json();

  if (resp && resp.status) {
    return resp.data;
  }

  throw new Error(resp.message);
}

export const updateExportationAviso = async (id, params) => {
  const response = await ApiService.put(`${server}Exportation/${id}/aviso`, params);
  const resp = await response.json();

  if (resp && resp.status) {
    return resp.data;
  }

  throw new Error(resp.message);
}

export const exportationAddBillLadingItem = async (id, params) => {
  const response = await ApiService.put(`${server}Exportation/${id}/billLadingItem`, params);
  const resp = await response.json();

  if (resp && resp.status) {
    return resp.data;
  }

  throw new Error(resp.message);
}

export const exportationRemoveBillLadingItem = async (id) => {
  const response = await ApiService.delete(`${server}Exportation/${id}/billLadingItem`);
  const resp = await response.json();

  if (resp && resp.status) {
    return resp.data;
  }

  throw new Error(resp.message);
}

export const saveDetailExportation =  async (params) => {
    try {
        const response = await ApiService.post(`${server}DetalleExport`, params);
      
          const resp = await response.json(); 
          return resp;
    } catch (error) {
        
    }
}

export const sendGsf = async (id, params) => {
  const response = await ApiService.post(`${server}Gsf/generateCBP/${id}`, params);
  return response.json();
}

export const updateStatusDetailExportation =  async (params, id) => {
  try {
      const response = await ApiService.put(`${server}DetalleExport/${id}`, params);
        const resp = await response.json(); 
        return resp;
      } catch (error) {
    
      }
}

export const updateDetalleExportConsignatarioFinal = async (id, params) => {
  const response = await ApiService.put(`${server}DetalleExport/${id}/consignatarioFinal`, params);
  const resp = await response.json();

  if (resp && resp.status)
    return resp.data;
  throw new Error(resp.message);
}

export const updateDetalleExportBillLadingInfo = async (id, params) => {
  const response = await ApiService.put(`${server}DetalleExport/${id}/blInfo`, params);
  const resp = await response.json();

  if (resp && resp.status)
    return resp.data;
  throw new Error(resp.message);
}

export const updateDetalleExportGoodsDetails = async (id, params) => {
  const response = await ApiService.put(`${server}DetalleExport/${id}/goodsDetails`, params);
  const resp = await response.json();

  if (resp && resp.status)
    return resp.data;
  throw new Error(resp.message);
}

export const getItn = async (exp) => {
  const response = await ApiService.get(`${server}Gsf/getItn/${exp}`);
  return response.json();
}

export const getExportationIdData = async (id) => {
    try {
        const response = await ApiService.get(`${server}Exportation/${id}`);
      
        const {data} = await response.json();

          return data;
    } catch (error) {
        console.log(error);
    }
}

export const getNumeroExportacion = async (type) => {
  const response = await ApiService.get(`${server}SecuenceWR?description=${type}`); //FLORIDAEXPO
  const { list } = await response.json();
  return list[0].secuence;
}

export const getListCarrier = async () => {
  const response = await ApiService.get(`${server}CarrierExp`);
  const { list } = await response.json();
  return list;
}

export const addCarrier = async (carrier) => {
  const response = await ApiService.post(`${server}CarrierExp`, carrier);
  return response.json();
}

export const updateCarrier = async (id, carrier) => {
  const response = await ApiService.put(`${server}CarrierExp/${id}`, carrier);
  return response.json();
}

export const getDockReceipt = async (exp) => {
  const response = await ApiService.get(`${server}Exportation/getDockReceipt/${exp}`);
  return response.json();
}

export const getExportationById = async (id) => {
  const response = await ApiService.get(`${server}Exportation/${id}`);
  const data = await response.json();

  if (data && data.status) {
    return data.data;
  }

  throw new Error(data.message);
}

export const getExportationDetailById = async (id) => {
  const response = await ApiService.get(`${server}DetalleExport/${id}`);
  const data = await response.json();

  if (data && data.status) {
    return data.data;
  }

  throw new Error(data.message);
}

export const getBillOfLadingMaster = async (exp) => {
  const response = await ApiService.get(`${server}Exportation/getBillOfLadingMaster/${exp}`);
  return response.json();
}

export const getLetterOfIntent = async (exp) => {
  const response = await ApiService.get(`${server}DetalleExport/getLetterOfIntent/${exp}`);
  return response.json();
}

export const getCoverLetter = async (expCode, currentUser) => {
  const coverLetterPdf = currentUser.ref === "CA" ? 'getCoverLetterCA' : 'getCoverLetterFL'; //->> change this for production
  const response = await ApiService.get(`${server}DetalleExport/${coverLetterPdf}/${expCode}`);
  const { data } = await response.json();
  return data;
}

export const getBillOfLading = async (expCode) => {
  const response = await ApiService.get(`${server}DetalleExport/getBillOfLading/${expCode}`);
  const data = await response.json();

  if (data && data.status) {
    return data.data;
  }

  throw new Error(data.message);
}

export const getCartaEndoso = async (expCode) => {
  const response = await ApiService.get(`${server}DetalleExport/getCartaEndoso/${expCode}`);
  const { data } = await response.json();
  return data;
}

export const getCargoManifest = async (expCode) => {
  const response = await ApiService.get(`${server}DetalleExport/getCargoManifest/${expCode}`);
  const { data } = await response.json();
  return data;
}

export const getNotificacionEmbarque = async (expCode) => {
  const response = await ApiService.get(`${server}DetalleExport/getNotificacionEmbarque/${expCode}`);
  const data = await response.json();

  if (data && data.status)
    return data.data;
  throw new Error(data.message);
}

export const getNotificacionArribo = async (expCode) => {
  const response = await ApiService.get(`${server}DetalleExport/getNotificacionArribo/${expCode}`);
  const data = await response.json();

  if (data && data.status)
    return data.data;
  throw new Error(data.message);
}

export const getListDateLoadingProgram = async (origenId = 0) => {
  const response = await ApiService.get(`${server}exportationContainer/loadingProgram/dates?origenId=${origenId}`);
  const { list } = await response.json();
  return list;
}

export const shippingInstruction = async (id) => {
  const response = await ApiService.post(`${server}exportation/shippingInstruction/${id}`);
  return response.json();
}

export const getExportationPdf = async (id) => {
  const response = await ApiService.get(`${server}Exportation/exportationPdf/${id}`);
  return response.json();
}

export const exportationContainerAsignar = async (id, params) => {
  const response = await ApiService.post(`${server}Exportation/${id}/exportationContainerAsignar`, params);
  const resp = await response.json();

  if (resp && resp.status) {
    return resp.data;
  }

  throw new Error(resp.message);
};

export const exportationContainerRemover = async (id, params) => {
  const response = await ApiService.post(`${server}Exportation/${id}/exportationContainerRemover`, params);
  const resp = await response.json();

  if (resp && resp.status) {
    return resp.data;
  }

  throw new Error(resp.message);
};

export const exportationPortConfig = async () => {
  const response = await ApiService.get(`${server}Exportation/exportationPortConfig`);
  const resp = await response.json();

  if (resp && resp.status) {
    return resp.list;
  }

  throw new Error(resp.message);
};
