import {getVehicleInStock} from "../Servicios/VehicleService";
import React, {useEffect, useState} from "react";
import Spinner from "../shared/spinner";
import {Button, Col, Row, Select, Table, Tag} from "antd";
import localStorageFilter from "../shared/utils/local-storage-filter";
import InputSearch from "../Componentes/InputSearch/InputSearch";
import useCurrentUser from "../Hooks/UserHook";
import {hasRol} from "../Servicios/AuthService";
import roles from "../utils/roles";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import moment from "moment";
import {useMainContext} from "../contexts/MainContext";
import {faDownload, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OrigenTab from "../Componentes/OrigenTab/OrigenTab";
import SelectYear from "../Componentes/SelectYear/SelectYear";
import SelectMonth from "../Componentes/SelectMonth/SelectMonth";
const XLSX = require("xlsx");

interface Filters {
  pageCount: number;
  currentPage: number;
  search: string;
  origenId: number;
  aduanaId: number;
  countryId: number;
  month: number;
  year: number;
}

const Inventario = () => {
  const currentUser = useCurrentUser();
  const showTab = hasRol(roles.ADMIN, roles.SUPERVISOR);

  const [loading, setLoading] = useState(false);

  const [vehiculos, setVehiculos] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const { aduanas, countries, loadingMain, origenes } = useMainContext();

  const [filters, setFilters] = useState({
    pageCount: localStorageFilter('inventario:filter', 'pageCount') ?? 100,
    currentPage: localStorageFilter('inventario:filter', 'currentPage') ?? 1,
    search: localStorageFilter('inventario:filter', 'search') ?? "",
    origenId: localStorageFilter('inventario:filter', 'origenId') ?? currentUser?.idubc,
    aduanaId: localStorageFilter('inventario:filter', 'aduanaId') ?? 0,
    countryId: localStorageFilter('inventario:filter', 'countryId') ?? 0,
    month: localStorageFilter('inventario:filter', 'month') ?? new Date().getMonth() + 1,
    year: localStorageFilter('inventario:filter', 'year') ?? new Date().getFullYear(),
  });

  useEffect(() => {
    if (currentUser) {
      if (filters.origenId) {
        init(filters).then();
      } else {
        setFilters({
          ...filters,
          origenId: +currentUser.idubc
        });
      }
    }

    const localFilters = localStorageFilter();
    if (JSON.stringify(localFilters) !== JSON.stringify(filters)) {
      localStorage.setItem('inventario:filter', JSON.stringify(filters));
    }
  }, [filters, currentUser]);

  const init = async (filters: Filters) => {
    setLoading(true);
    const data = await inventario(filters, filters.currentPage, filters.pageCount);
    setLoading(false);

    setVehiculos(data.list);
    setTotalElements(data.totalElements);
  }

  const inventario = async (filters, currentPage, pageCount) => {
    return await getVehicleInStock(currentPage, pageCount, filters.search, filters.origenId, filters.aduanaId,
      filters.countryId, filters.month, filters.year);
  }

  const columns = [
    {
      key: 'fecha',
      dataIndex: 'fecha',
      title: 'Recibido',
      render: (value) => value ? moment(value).format('MMM DD, yyyy').toUpperCase() : '--//--'
    },
    {
      key: 'lote',
      dataIndex: 'lote',
      title: 'Lote'
    },
    {
      key: 'vin',
      dataIndex: 'vin',
      title: 'VIN'
    },
    {
      key: 'vehiculoName',
      dataIndex: 'vehiculoName',
      title: 'Descripción'
    },
    {
      key: 'color',
      dataIndex: 'color',
      title: 'Color'
    },
    {
      key: 'country',
      dataIndex: 'country',
      title: 'País'
    },
    {
      key: 'aduana',
      dataIndex: 'aduana',
      title: 'Destino'
    },
    {
      key: 'titulo',
      dataIndex: 'titulo',
      title: 'Título',
      render: (value) => value
        ? <Tag color={'success'}>SI</Tag>
        : <Tag color={'error'}>NO</Tag>
    },
    {
      key: 'vehiculoSeccion',
      dataIndex: 'vehiculoSeccion',
      title: 'Ubicación',
      render: (_, element) => element?.vehiculoSeccion?.name
    },
  ];

  const handlePageChange = (page, pageSize) => {
    setFilters({
      ...filters,
      currentPage: page,
      pageCount: pageSize,
    });
  }

  const handleChangeSearch = (value) => {
    setFilters({
      ...filters,
      search: value,
      currentPage: 1,
    })
  }

  const handleChangeTab = (tab) => {
    setFilters({
      ...filters,
      origenId: tab,
      currentPage: 1,
    });
  }

  const handleChangeFilter = (value, key) => {
    setFilters({
      ...filters,
      [key]: value
    })
  }

  const handleDownloadExcel = async () => {
    setLoading(true);
    const data = await inventario(filters, 0, 0);
    setLoading(false);

    const list = data.list.map(e => {
      const origen = origenes.find(origen => +origen.id === +e.origen)?.nameOrigen ?? "";

      return {
        'Fecha': e.fecha ? moment(e.fecha).format('l') : '---',
        'Lote': e.lote,
        'Vehiculo': e.vehiculoName,
        'Vin': e.vin,
        'Color': e.color,
        'Origen': origen,
        'Destino': e.aduana,
        'País': e.country,
        'titulo': e.titulo ? "SI" : "NO"
      }
    });

    const worksheet = XLSX.utils.json_to_sheet(list);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Inventario");

    // Genera el archivo Excel
    XLSX.writeFile(workbook, "inventario.xlsx", {
      bookType: "xlsx",
      type: "blob",
    });
  }

  return(
    <Spinner loading={loading || loadingMain}>
      <h5 className="mb-0">Inventario de Vehículos</h5>
      <hr/>
      <div className="mb-3">
        <Row gutter={16}>
          <Col xs={24} md={12} lg={6} xl={6}>
            <div className="d-flex align-items-center justify-content-between">
              <p className="mb-1">Filtrar por Año</p>
              <ShowWhen show={filters.year !== 0}>
                <p className="mb-1 text-danger cursor-pointer"
                   onClick={() => handleChangeFilter(0, 'year')}>
                  <FontAwesomeIcon icon={faTimes}/> Remover
                </p>
              </ShowWhen>
            </div>
            <SelectYear year={filters.year === 0 ? '' : filters.year}
                        selectYear={year => setFilters({...filters, year: year})}/>
          </Col>
          <Col xs={24} md={12} lg={6} xl={6}>
            <div className="d-flex align-items-center justify-content-between">
              <p className="mb-1">Filtrar por Mes</p>
              <ShowWhen show={filters.month !== 0}>
                <p className="mb-1 text-danger cursor-pointer"
                   onClick={() => handleChangeFilter(0, 'month')}>
                  <FontAwesomeIcon icon={faTimes}/> Remover
                </p>
              </ShowWhen>
            </div>
            <SelectMonth month={filters.month === 0 ? '' : filters.month}
                         selectMonth={month => setFilters({...filters, month: month})}/>
          </Col>
          <Col xs={24} md={12} lg={6} xl={6}>
            <div className="d-flex align-items-center justify-content-between">
              <p className="mb-1">Filtrar por País</p>
              <ShowWhen show={filters.countryId !== 0}>
                <p className="mb-1 text-danger cursor-pointer"
                   onClick={() => handleChangeFilter(0, 'countryId')}>
                  <FontAwesomeIcon icon={faTimes}/> Remover
                </p>
              </ShowWhen>
            </div>
            <Select className="w-100"
                    value={filters?.countryId === 0 ? '' : filters?.countryId}
                    placeholder={"Filtrar por País"}
                    onChange={value => handleChangeFilter(value, 'countryId')}
                    options={countries.map(e => {
                      return {value: e.id, label: e.nameCountry}
                    })}/>
          </Col>
          <Col xs={24} md={12} lg={6} xl={6}>
            <div className="d-flex align-items-center justify-content-between">
              <p className="mb-1">Filtrar por Destino</p>
              <ShowWhen show={filters.aduanaId !== 0}>
                <p className="mb-1 text-danger cursor-pointer"
                   onClick={() => handleChangeFilter(0, 'aduanaId')}>
                  <FontAwesomeIcon icon={faTimes}/> Remover
                </p>
              </ShowWhen>
            </div>
            <Select className="w-100"
                    value={filters?.aduanaId === 0 ? '' : filters?.aduanaId}
                    placeholder={"Filtrar por Destino"}
                    onChange={value => handleChangeFilter(value, 'aduanaId')}
                    options={aduanas
                      .filter(e => !e.isProvisional)
                      .filter(e => filters?.countryId === 0 || e.countryId === filters?.countryId)
                      .map(e => {
                        return {value: e.id, label: e.nameAduana}
                      })}/>
          </Col>
        </Row>
      </div>
      <div className="mb-3 text-end">
        <Button type={'primary'} onClick={handleDownloadExcel}><FontAwesomeIcon icon={faDownload} className="me-2" /> Descargar excel</Button>
      </div>
      <div className="mb-3">
        <ShowWhen show={showTab}>
          <OrigenTab selectedOrigen={filters.origenId} onChange={handleChangeTab}/>
        </ShowWhen>
        <InputSearch placeholder={'buscar por vin o por lote'} doChange={handleChangeSearch}/>
      </div>
      <Table size={'small'}
             columns={columns}
             dataSource={vehiculos}
             pagination={{
               position: ["bottomRight"],
               total: totalElements,
               showSizeChanger: true,
               showTotal: total => `${total} elementos`,
               current: filters.currentPage,
               pageSize: filters.pageCount,
               pageSizeOptions: [20, 50, 100, 150, 200, 500],
               onChange: handlePageChange
             }}/>
    </Spinner>
  );
}

export default Inventario;
