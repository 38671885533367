import React, {useEffect, useState} from "react";
import {addImport, editImport} from "../../Servicios/ComponedoresService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import {errorMessage} from "../../utils/toast-message";
import {showProvinces, showCountries} from "../../Servicios/CombosService";
import Spinner from "../../shared/spinner";
import {Button, Col, Form, Input, Row, Select} from "antd";
import TextArea from "antd/lib/input/TextArea";
import selectFilterOptions from "../../shared/utils/select-filter-options";

const ImportadoraForm = ({ importadora, onCancel, afterSubmit, titulo, esConsignee, country }) => {
  const [form] = Form.useForm();
  const [provinces, setProvinces] = useState([]);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredProvinces, setFilteredProvinces] = useState([]);

  useEffect(() => {
    setLoading(true);
    init()
      .then(() => {
        if (importadora) {
          form.setFieldsValue({
            ...importadora,
            idCountry: importadora.idCountry ?? country ?? '',
          });
        } else {
          form.resetFields();
          form.setFieldsValue({
            idCountry: country ?? '',
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [importadora, country]);

  const init = async () => {
    if (countries.length === 0) {
      const countries = await showCountries();
      setCountries(countries);
    }

    if (provinces.length === 0) {
      const provinces = await showProvinces();
      setProvinces(provinces.filter(e => !country || +e.idCountry === +country));
      setFilteredProvinces(provinces.filter(e => !country || +e.idCountry === +country));
    }
  }

  const handleChange = (idCountry) => {
    form.setFieldValue('idProvince', '');
    setFilteredProvinces(provinces.filter(e => e.idCountry === idCountry));
  }

  const insertarImport = async (input) => {
    setLoading(true);
    const data = await addImport(input);
    setLoading(false);
    if (data && data.status) {
      afterSubmit(data.data);
    } else {
      errorMessage(data.message);
    }
  }

  const editarImport = async (input) => {
    setLoading(true);
    const data = await editImport(importadora.id, input);
    setLoading(false);
    if (data && data.status) {
      afterSubmit(data.data);
    } else {
      errorMessage(data.message);
    }
  }

  const handleSubmit = (values) => {
    const input = {
      ...values,
      consignee: !!esConsignee,
      idProvince: values.idProvince !== ""
        ? +values.idProvince
        : null
    }

    if (importadora) {
      editarImport(input).then();
    } else {
      insertarImport(input).then();
    }
  }

  return(
    <Spinner loading={loading}>
      <Form form={form}
            layout={'vertical'}
            onFinish={handleSubmit}>
        <Form.Item name="impName"
                   rules={[
                       { required: true, message: 'El nombre es un campo requerido'}
                   ]}
                   label={'Nombre de ' + (titulo ?? 'Importadora')}>
          <Input placeholder={`Nombre ${titulo ?? 'Importadora'}`} />
        </Form.Item>

        <Row gutter={16}>
          <Col md={8}>
            <Form.Item name="identfType" label={'Tipo de Identificación'}>
              <Select className="w-100"
                      options={[
                        {value: '# EIN', label: '# EIN'},
                        {value: '# Pasaporte', label: '# de Pasaporte'},
                        {value: '# Registro', label: '# de Registro'}
                      ]}>
              </Select>
            </Form.Item>
          </Col>
          <Col md={16}>
            <Form.Item name="identf"
                       label={'Identificación'}>
              <Input placeholder="Identifitacion"/>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name={'addressLine1'}
                   rules={[
                     { required: true, message: 'La Dirección es un campo requerido'},
                     { max: esConsignee ? 80 : 30, message: 'Ha alcanzado el maximo permitido en este campo' },
                   ]}
                   label={'Dirección linea 1'}>
          <TextArea rows={2} placeholder="Dirección Linea 1" />
        </Form.Item>

        <Form.Item name={'addressLine2'}
                   rules={[
                     { max: esConsignee ? 80 : 30, message: 'Ha alcanzado el maximo permitido en este campo' },
                   ]}
                   label={'Dirección linea 2'}>
          <TextArea rows={2} placeholder="Dirección Linea 2" />
        </Form.Item>

        <Row gutter={16}>
          <Col md={12}>
            <Form.Item name={'idCountry'}
                       rules={[
                         { required: true, message: 'El País es un campo requerido'}
                       ]}
                       label={'País'}>
              <Select showSearch={true}
                      className="w-100"
                      disabled={!!country}
                      filterOption={selectFilterOptions}
                      onChange={handleChange}
                      options={countries.map((e) => ({ label: e.nameCountry, value: e.id}))}/>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item name={'idProvince'}
                       rules={[
                         { required: true, message: 'El Departamento es un campo requerido'}
                       ]}
                       label={'Departamento'}>
              <Select showSearch={true}
                      className="w-100"
                      filterOption={selectFilterOptions}
                      options={filteredProvinces.map((e) => ({ label: e.name, value: e.id}))}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={16}>
            <Form.Item name="city"
                       rules={[
                         { required: true, message: 'La Ciudad es un campo requerido'}
                       ]}
                       label={'Ciudad'}>
              <Input placeholder={'Ciudad'} />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item name="postalCode"
                       label={'Código Postal'}>
              <Input placeholder={'Código Postal'} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={12}>
            <Form.Item name="contactFirstName"
                       label={'Nombre del Contacto'}>
              <Input placeholder={'Nombre del Contacto'} />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item name="contactLastName"
                       label={'Apellido del Contacto'}>
              <Input placeholder={'Apellido del Contacto'} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={12}>
            <Form.Item name="phoneNumber"
                       label={'Teléfono'}>
              <Input placeholder={'Teléfono'} />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item name="email"
                       label={'Correo electrónico'}>
              <Input placeholder={'Correo electrónico'} />
            </Form.Item>
          </Col>
        </Row>
        <div className="text-end">
          <Button type="default" className="me-3" htmlType={'button'} onClick={onCancel}>Cancelar</Button>
          <Button type="primary" htmlType={'submit'}><FontAwesomeIcon icon={faFloppyDisk} className="me-2"/>
            { importadora ? 'Actualizar' : 'Guardar'}
          </Button>
        </div>
      </Form>
    </Spinner>
  );
}

export default ImportadoraForm;
