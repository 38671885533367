import {server} from "../environments/environment";
import {ApiService} from "./ApiService";

const URL = `${server}coordinaciones`

export const showCoord = async (origenId = 0, state = "", year = 0, month = 0, currentPage = 0, pageCount = 0,
                                search = "", entryType = "", aduanaId = 0, day = 0, estPickUp = 0, estDelivery = 0,
                                fromDate = 0, toDate = 0, countriesId = "", subastaId = 0, actDelivery = 0,
                                transporteId = 0, recibidoSinTitulo = 0, filtrarPorFechaReciboVehiculo = 0,
                                vehiculoTituloEstado = "", vehiculoRecibidoConTitulo = -1, vendedorId = 0,
                                estadoPagoTransp = "", defaultSubasta = 0) => {
  let params = {
    origenId: origenId,
    year: year,
    month: month,
    currentPage: currentPage,
    pageCount: pageCount,
    aduanaId: aduanaId,
    day: day,
    estPickUp: estPickUp,
    estDelivery: estDelivery,
    fromDate: fromDate,
    toDate: toDate,
    subastaId: subastaId,
    actDelivery: actDelivery,
    transporteId: transporteId,
    recibidoSinTitulo: recibidoSinTitulo,
    filtrarPorFechaReciboVehiculo: filtrarPorFechaReciboVehiculo,
    vehiculoRecibidoConTitulo: vehiculoRecibidoConTitulo,
    vendedorId: vendedorId,
    defaultSubasta: defaultSubasta
  };

  if (state !== "") params['state'] = state;
  if (search !== "") params['search'] = search;
  if (entryType !== "") params['entryType'] = entryType;
  if (countriesId !== "") params['countriesId'] = countriesId;
  if (estadoPagoTransp !== "") params['estadoPagoTransp'] = estadoPagoTransp;
  if (vehiculoTituloEstado !== "") params['vehiculoTituloEstado'] = vehiculoTituloEstado;

  const queryParams = new URLSearchParams(params);

  const response = await ApiService.get(`${URL}?${queryParams.toString()}`);
  return response.json();
}

export const showPendingTitle = async (origenId = 0, estado = "", aduanaId = 0) => {
  let response;
  if (aduanaId == null || aduanaId === 0) {
    response = await ApiService.get(`${URL}?origenId=${origenId}&state=${estado}`);
  } else {
    response = await ApiService.get(`${URL}?origenId=${origenId}&aduanaId=${aduanaId}&state=${estado}`);
  }

  const data = await response.json()
  return data.list;
}

export const pendingTCountry = async (origenId = 0, estado = "", countryId = 0) => {
  let response;
  response = await ApiService.get(`${URL}?origenId=${origenId}&state=${estado}&countryId=${countryId}`);
  const data = await response.json()
  return data.list;
}

export const showCoordPorCliente = async (clienteId = 0) => {
  let response;

  if (clienteId === 0) {
    response = await ApiService.get(URL);
  } else {
    response = await ApiService.get(`${URL}?clienteId=${clienteId}`);
  }

  const data = await response.json()
  return data.list;
}

export const addCoor = async (coordi) => {
  return await ApiService.post(
    `${server}coordinaciones`,
    coordi).then(async (response) => {
    const data = await response.json()
    if (data.status) {
      return data.data;
    } else {
      throw Error(data.message)
    }
  }).catch(error => {
    throw Error(error)
  })
}

export const notifyCustomerCoordinacion = async (id) => {
    return await ApiService.get(`${server}coordinaciones/${id}/customerNotified`).then(async (response) => {
        const data = await response.json()
        if (data.status) {
            return data.data;
        } else {
            throw Error(data.message)
        }
    }).catch(error => {
        throw Error(error)
    })
}

export const viewCoordi = async (id) => {
  try {
    const response = await ApiService.get(URL + "/" + id);
    const data = await response.json()
    const coordi = data.data;

    if (coordi.fechacor && coordi.fechacor.indexOf("T00:00:00") !== -1) {
      coordi.fechacor = coordi.fechacor.substring(0, coordi.fechacor.indexOf("T00:00:00"));
    }

    if (coordi.expIdent && coordi.expIdent.indexOf("T00:00:00") !== -1) {
      coordi.expIdent = coordi.expIdent.substring(0, coordi.expIdent.indexOf("T00:00:00"));
    }

    if (coordi.firstBusDay && coordi.firstBusDay.indexOf("T00:00:00") !== -1) {
      coordi.firstBusDay = coordi.firstBusDay.substring(0, coordi.firstBusDay.indexOf("T00:00:00"));
    }

    if (coordi.lastFreeDay && coordi.lastFreeDay.indexOf("T00:00:00") !== -1) {
      coordi.lastFreeDay = coordi.lastFreeDay.substring(0, coordi.lastFreeDay.indexOf("T00:00:00"));
    }

    if (coordi.estPickUp && coordi.estPickUp.indexOf("T00:00:00") !== -1) {
      coordi.estPickUp = coordi.estPickUp.substring(0, coordi.estPickUp.indexOf("T00:00:00"));
    }

    if (coordi.actPickUp && coordi.actPickUp.indexOf("T00:00:00") !== -1) {
      coordi.actPickUp = coordi.actPickUp.substring(0, coordi.actPickUp.indexOf("T00:00:00"));
    }

    if (coordi.estDelivery && coordi.estDelivery.indexOf("T00:00:00") !== -1) {
      coordi.estDelivery = coordi.estDelivery.substring(0, coordi.estDelivery.indexOf("T00:00:00"));
    }

    if (coordi.actDelivery && coordi.actDelivery.indexOf("T00:00:00") !== -1) {
      coordi.actDelivery = coordi.actDelivery.substring(0, coordi.actDelivery.indexOf("T00:00:00"));
    }

    if (coordi.estArrival && coordi.estArrival.indexOf("T00:00:00") !== -1) {
      coordi.estArrival = coordi.estArrival.substring(0, coordi.estArrival.indexOf("T00:00:00"));
    }

    if (coordi.actArrival && coordi.actArrival.indexOf("T00:00:00") !== -1) {
      coordi.actArrival = coordi.actArrival.substring(0, coordi.actArrival.indexOf("T00:00:00"));
    }

    if (coordi.dispatchDate && coordi.dispatchDate.indexOf("T00:00:00") !== -1) {
      coordi.dispatchDate = coordi.dispatchDate.substring(0, coordi.dispatchDate.indexOf("T00:00:00"));
    }

    if (coordi.paid && coordi.paid.indexOf("T00:00:00") !== -1) {
      coordi.paid = coordi.paid.substring(0, coordi.paid.indexOf("T00:00:00"));
    }
    return coordi;

  } catch (ex) {
    throw new Error(ex);
  }
}

export const editCoor = async (id, coordi) => {
  const response = await ApiService.put(URL + "/" + id, coordi);
  const data = await response.json()

  if (data && data.status)
    return data.data;
  else
    throw new Error(data.message);
}

export const editCoordinacionPago = async (id, coordi) => {
  const response = await ApiService.put(`${URL}/${id}/pago`, coordi);
  const data = await response.json();
  if (data && data.status) {
    return data.data;
  }

  throw new Error(data.message);
}

export const deleteCoor = async (id, description) => {
  try {
    const response = await ApiService.put(URL + "/cancelar/" + id, description);
    const data = await response.json()
    console.log("ELIMINADA", data);
    return data;
  } catch (ex) {
    throw new Error(ex);
  }
}

export const reOpenCoor = async (id, description) => {
  try {
    const response = await ApiService.put(`${URL}/reactivar/${id}`, {});
    const data = await response.json();
    return data;
  } catch (ex) {
    throw new Error(ex);
  }
}
