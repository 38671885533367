import {Button, Col, DatePicker, Form, Input, Row} from "antd";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {updateExportationAviso} from "../../Servicios/ExportationServices";
import Spinner from "../../shared/spinner";
import dayjs from "dayjs";

const ExportationAvisoForm = ({exportation, onUpdate}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      departureDate: exportation?.departureDate
        ? dayjs(exportation?.departureDate)
        : null,
      arrivalDate: exportation?.arrivalDate
        ? dayjs(exportation?.arrivalDate)
        : null,
      dateNotArrive: exportation?.dateNotArrive
        ? dayjs(exportation?.dateNotArrive)
        : null,
      dateNotShipment: exportation?.dateNotShipment
        ? dayjs(exportation?.dateNotShipment)
        : null,
      dateNotEmtied: exportation?.dateNotEmtied
        ? dayjs(exportation?.dateNotEmtied)
        : null,
      dateReceivingRTU: exportation?.dateReceivingRTU
        ? dayjs(exportation?.dateReceivingRTU)
        : null,
      dateUpdateRTU: exportation?.dateUpdateRTU
        ? dayjs(exportation?.dateUpdateRTU)
        : null,
      dateNotZarpe: exportation?.dateNotZarpe
        ? dayjs(exportation?.dateNotZarpe)
        : null,
      dateDocumentDelivery: exportation?.dateDocumentDelivery
        ? dayjs(exportation?.dateDocumentDelivery)
        : null,
    })
  }, [exportation]);

  const handleFinish = async (values) => {
    values.dateNotShipment = values.dateNotShipment
      ? moment(values.dateNotShipment.$d).format("YYYY-MM-DDTHH:mm:ss")
      : null;

    values.dateNotArrive = values.dateNotArrive
      ? moment(values.dateNotArrive.$d).format("YYYY-MM-DDTHH:mm:ss")
      : null;

    values.dateNotEmtied = values.dateNotEmtied
      ? moment(values.dateNotEmtied.$d).format("YYYY-MM-DDTHH:mm:ss")
      : null;

    values.dateReceivingRTU = values.dateReceivingRTU
      ? moment(values.dateReceivingRTU.$d).format("YYYY-MM-DDTHH:mm:ss")
      : null;

    values.dateUpdateRTU = values.dateUpdateRTU
      ? moment(values.dateUpdateRTU.$d).format("YYYY-MM-DDTHH:mm:ss")
      : null;

    values.dateNotZarpe = values.dateNotZarpe
      ? moment(values.dateNotZarpe.$d).format("YYYY-MM-DDTHH:mm:ss")
      : null;

    values.dateDocumentDelivery = values.dateDocumentDelivery
      ? moment(values.dateDocumentDelivery.$d).format("YYYY-MM-DDTHH:mm:ss")
      : null;

    values.departureDate = values.departureDate
      ? moment(values.departureDate.$d).format("YYYY-MM-DDTHH:mm:ss")
      : null;

    values.arrivalDate = values.arrivalDate
      ? moment(values.arrivalDate.$d).format("YYYY-MM-DDTHH:mm:ss")
      : null;

    const input = {
      ...values,
      notificationWeek: values?.notificationWeek ?? "",
    }

    setLoading(true);
    await updateExportationAviso(exportation.exportationId, input);
    setLoading(false);

    if (onUpdate) {
      onUpdate();
    }
  }

  return(
    <Spinner loading={loading}>
      <Form form={form} layout={'vertical'} onFinish={handleFinish}>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Fecha de Salida"
              name="departureDate"
            >
              <DatePicker
                format="YYYY-MM-DD"
                style={{width: "100%"}}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Fecha de Llegada"
              name="arrivalDate"
            >
              <DatePicker
                format="YYYY-MM-DD"
                style={{width: "100%"}}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <Form.Item label="Semana de Notificación" name="notificationWeek">
              <Input placeholder="Semana de Notificación" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Fecha de Notificacion arribo:"
              name="dateNotArrive"
            >
              <DatePicker
                format="YYYY-MM-DD"
                style={{width: "100%"}}
              />
            </Form.Item>

            <Form.Item
              label="Fecha Aviso de Embarque:"
              name="dateNotShipment"
            >
              <DatePicker
                format="YYYY-MM-DD"
                style={{width: "100%"}}
              />
            </Form.Item>

            <Form.Item label="Fecha de vaciado:" name="dateNotEmtied">
              <DatePicker
                format="YYYY-MM-DD"
                style={{width: "100%"}}
              />
            </Form.Item>

            <Form.Item label="Fecha de entrega de Documento:" name="dateDocumentDelivery">
              <DatePicker
                format="YYYY-MM-DD"
                style={{width: "100%"}}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label="Fecha recibir RTU:" name="dateReceivingRTU">
              <DatePicker
                format="YYYY-MM-DD"
                style={{width: "100%"}}
              />
            </Form.Item>

            <Form.Item label="Fecha Modificación RTU:" name="dateUpdateRTU">
              <DatePicker
                format="YYYY-MM-DD"
                style={{width: "100%"}}
              />
            </Form.Item>

            <Form.Item
              label="Fecha Notificacion atraso Zarpe:"
              name="dateNotZarpe"
            >
              <DatePicker
                format="YYYY-MM-DD"
                style={{width: "100%"}}
              />
            </Form.Item>
          </Col>
        </Row>
        <Button type={'primary'} htmlType={'submit'}>Guardar</Button>
      </Form>
    </Spinner>
  );
}

export default ExportationAvisoForm;
