import TextArea from "antd/lib/input/TextArea";
import {Button} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {updateDetalleExportGoodsDetails} from "../../Servicios/ExportationServices";
import {errorMessage, successMessage} from "../../utils/toast-message";

const ExportationShippingInstructionDetail = ({ exportation, detail, index }) => {
  const [loading, setLoading] = useState(false);
  const [goodsDetails, setGoodsDetails] = React.useState('');

  useEffect(() => {
    let goodDetails = '';

    if (detail?.werehouse?.hasVehicle) {
      goodDetails += `${detail?.werehouse?.vehiculoName}\n`;

      if (detail?.werehouse?.extraItems?.length > 0) {
        detail?.werehouse?.extraItems?.forEach(item => {
          goodDetails += `${item?.quantity} ${item?.extraItemName}\n`;
        });
      }

      goodDetails += `${detail?.werehouse?.vin}\n`;
    } else {
      if (detail?.werehouse?.extraItems && detail?.werehouse?.extraItems.length > 0 && detail?.werehouse?.extraItems[0])
        goodDetails += `${detail?.werehouse?.extraItems[0]?.quantity} ${detail?.werehouse?.extraItems[0]?.extraItemName}\n`;
    }

    if (index === 0) {
      goodDetails += `${exportation?.descriptionGoods}`;
    }

    setGoodsDetails(goodDetails);
  },  [detail]);

  const missingInformation = () => <p className="mb-0 fw-bold text-danger">Missing Information</p>;

  const handleUpdateGoodsDetails = async (detalleExport) => {
    try {
      const input = { goodsDetails };

      setLoading(true);
      await updateDetalleExportGoodsDetails(detalleExport?.detalleExportId, input);
      setLoading(false);
      successMessage('Descripcion actualizada correctamente');
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  }

  return(
    <>
      <tr>
        <td colSpan={2} className="fw-bold">Line {index + 1}</td>
      </tr>
      <tr>
        <td className="w-50">Item Code</td>
        <td className="w-50">{detail?.werehouse?.scheduleB?.codigo ?? missingInformation()}</td>
      </tr>
      <tr>
        <td className="w-50">Number of Packages</td>
        <td className="w-50">1</td>
      </tr>
      <tr>
        <td className="w-50">Package Type Code</td>
        <td className="w-50">VN</td>
      </tr>
      <tr>
        <td className="w-50">Package Type Description</td>
        <td className="w-50">Vehicle (Small)</td>
      </tr>
      <tr>
        <td className="w-50">Package Detail</td>
        <td className="w-50">
          <p className="mb-0">{detail?.werehouse?.vehiculoName}</p>
          <p className="mb-0">{detail?.werehouse?.vin}</p>
        </td>
      </tr>
      <tr>
        <td className="w-50">Gross Weight</td>
        <td className="w-50">{detail?.werehouse?.weight} LBS</td>
      </tr>
      <tr>
        <td className="w-50">Gross Volume</td>
        <td className="w-50">{detail?.werehouse?.volume} FTQ</td>
      </tr>
      <tr>
        <td className="w-50">Transaction Reference Number (ITN)</td>
        <td className="w-50">
          {detail.itn ?? missingInformation()}
        </td>
      </tr>
      <tr>
        <td colSpan={2}>
          <p className="mb-1">Goods Details</p>
          <TextArea rows={5} value={goodsDetails} onChange={(event) => setGoodsDetails(event.target.value)}/>
          <div className="text-end mt-2">
            <Button loading={loading} type={'primary'} onClick={() => handleUpdateGoodsDetails(detail)}>
              <FontAwesomeIcon icon={faRefresh} className="me-2"/> Actualizar
            </Button>
          </div>
        </td>
      </tr>
    </>
  );
}

export default ExportationShippingInstructionDetail;
