import React, { useState } from "react";
import { Input, Modal } from "antd";
import DetailTracking from "./DetailTracking";

const { Search } = Input;

const InputSearchGlobal = ({ placeholder, value, onChange }) => {
  const [search, setSearch] = useState(""); // Estado para el valor del input
  const [visible, setVisible] = useState(false);

  const handleSearch = (value: string) => {
    if (value) {
      setSearch(value); // Actualiza el valor de búsqueda
      setVisible(true); // Muestra el modal
    }
  };

  const handleOnCancel = () => {
    setVisible(false); // Oculta el modal
    // setSearch(""); // Limpia el input
  };
  

  return (
    <>
      <Search
        placeholder={placeholder || "Buscar por Lote o VIN"}
        onSearch={handleSearch}
        allowClear
        style={{ width: "80%" }}
        size="large"
        onChange={(e) => setSearch(e.target.value)} // Sincroniza el input
        value={search}
      />
      <Modal
        title="Detalle de Tracking"
        open={visible}
        onOk={handleOnCancel}
        onCancel={handleOnCancel}   
        width={"80%"}
      >
        {/* Evita que pase undefined */}
        <DetailTracking loteOrVim={search || "Valor no encontrado"} show={visible} />
      </Modal>
    </>
  );
};

export default InputSearchGlobal;
