import React from "react";

import Nav from "react-bootstrap/Nav";
import "../../hojas-de-estilo/style.css";
import { Link } from "react-router-dom";
import { validarPermisos } from "../../Servicios/AuthService";
import { permisos } from "../../utils/permisos";
import { Col, Row, Card } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import useCurrentUser from "../../Hooks/UserHook";

import WelcomeSection from "./widget/welcomeSection";
import Wallets from "./widget/wallets";
import RequestAnalysisAnnual from "./graphic/RequestAnalysis/annual";
import RequestAnalysisWeekly from "./graphic/RequestAnalysis/weekly";
import TopSellersByMonth from "./graphic/Top/sellers";
import TopClientsByMonth from "./graphic/Top/clients";
import TotalOriginCurrentMonth from "./graphic/Coordination/totalCurrentMonth";
import TotalOriginCurrentMonthRequest from "./graphic/solicitudes/totalOriginCurrentMonthRequest";
import DetailsCordinations from "./graphic/Coordination/detailsCordinations";
import TotalVehicleReceived from "./widget/totalVehicleReceived";
import DetailsCordinationsByWeek from "./graphic/Coordination/detailsCordinationsByWeek";
import SolicitudPerWeek from "./graphic/solicitudes/SolicitudPerWeek";
import TotalContenedoresPorOrigen from "./widget/totalContenedoresPorOrigen";

function HomeInfo() {
  const user = useCurrentUser();

  const puedeAgregarCliente = validarPermisos(permisos.AGREGAR_CLIENTE);
  const puedeAgregarImportadora = validarPermisos(permisos.AGREGAR_IMPORTADORA);
  const puedeAgregarAduana = validarPermisos(permisos.AGREGAR_ADUANA);
  const puedeAgregarSegmento = validarPermisos(permisos.AGREGAR_SEGMENTO);
  const puedeAgregarTransporte = validarPermisos(permisos.AGREGAR_TRANSPORTE);
  const verCoordinacionesCA = validarPermisos(permisos.VER_COORDINACION_CA);
  const verCoordinacionesFL = validarPermisos(permisos.VER_COORDINACION_FL);
  const puedeAgregarSolicitudes = validarPermisos(permisos.AGREGAR_SOLICITUDES);

  const orFl = 1;
  const orCa = 2;

  return (
    <>
      <Row gutter={16}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <WelcomeSection user={user} />
        </Col>
      </Row>
      <br />

      <Wallets user={user} />
      <Row gutter={16}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <SolicitudPerWeek />
        </Col>
      </Row>
      <br />
      <Row gutter={16}>
        <Col lg={12} md={12} sm={24} xs={24}>
          <TotalOriginCurrentMonthRequest user={user} />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <RequestAnalysisWeekly user={user} />
        </Col>
      </Row>
      <br />
      <Row gutter={16}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <RequestAnalysisAnnual user={user} />
        </Col>
      </Row>
      <br />
      <Row gutter={16}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <TotalContenedoresPorOrigen user={user} />
        </Col>
      </Row>
      <br />
      <Row gutter={16}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <TotalVehicleReceived user={user} />
        </Col>
      </Row>
      <br />
      <Row gutter={16}>
      <Col lg={12} md={12} sm={24} xs={24}>
          <TopClientsByMonth user={user} />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <TopSellersByMonth user={user} />
        </Col>
      </Row>
      <br />
      <Row gutter={16}>
      <Col lg={12} md={12} sm={24} xs={24}>
          {/* <DetailsCordinations user={user} /> */}
          <DetailsCordinationsByWeek user={user} />
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <TotalOriginCurrentMonth user={user} />
        </Col>
      </Row>
      <br />
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
      >
        <Col className="gutter-row" span={8}>
          {puedeAgregarCliente && (
            <Card
              title="Controles de Cartera de Clientes"
              style={gridStylePrincipal}
            >
              {puedeAgregarCliente && (
                <Card.Grid style={gridStyle}>
                  <Nav.Link as={Link} to="/home/agregarCli">
                    <LinkOutlined
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                    <strong>Agregar Nuevo Cliente</strong>
                  </Nav.Link>
                </Card.Grid>
              )}
              {puedeAgregarImportadora && (
                <Card.Grid style={gridStyle}>
                  {" "}
                  <Nav.Link as={Link} to="/home/importadoras">
                    <LinkOutlined
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                    <strong>Ver/Agregar Importadoras</strong>
                  </Nav.Link>
                </Card.Grid>
              )}
              {puedeAgregarAduana && (
                <Card.Grid style={gridStyle}>
                  {" "}
                  <Nav.Link as={Link} to="/home/aduanas">
                    <LinkOutlined
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                    <strong>Ver/Agregar Aduanas</strong>
                  </Nav.Link>
                </Card.Grid>
              )}
              {puedeAgregarSegmento && (
                <Card.Grid style={gridStyle}>
                  {" "}
                  <Nav.Link as={Link} to="/home/segmentos">
                    <LinkOutlined
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                    <strong>Ver/Agregar Segmentos</strong>
                  </Nav.Link>
                </Card.Grid>
              )}
            </Card>
          )}
        </Col>
        <Col className="gutter-row" span={8}>
          {verCoordinacionesFL && (
            <Card
              title="Controles de Coordinaciones"
              style={gridStylePrincipal}
            >
              {verCoordinacionesFL && (
                <Card.Grid style={gridStyle}>
                  <Nav.Link
                    as={Link}
                    to={`/home/coordinaciones/origen/${orFl}`}
                  >
                    <LinkOutlined
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                    <strong>Coordinaciones Florida</strong>
                  </Nav.Link>
                </Card.Grid>
              )}
              {verCoordinacionesCA && (
                <Card.Grid style={gridStyle}>
                  <Nav.Link
                    as={Link}
                    to={`/home/coordinaciones/origen/${orCa}`}
                  >
                    <LinkOutlined
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                    <strong>Coordinaciones California</strong>
                  </Nav.Link>
                </Card.Grid>
              )}
              {puedeAgregarTransporte && (
                <Card.Grid style={gridStyle}>
                  <Nav.Link as={Link} to="/home/transporte">
                    <LinkOutlined
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                    <strong>Ver/Agregar Compañia de Transporte</strong>
                  </Nav.Link>
                </Card.Grid>
              )}
              {puedeAgregarTransporte && (
                <Card.Grid style={gridStyle}>
                  <Nav.Link as={Link} to={`/home/subasta`}>
                    <LinkOutlined
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                    <strong>Agregar/Editar Subasta</strong>
                  </Nav.Link>
                </Card.Grid>
              )}
            </Card>
          )}
        </Col>
        <Col className="gutter-row" span={8}>
          {puedeAgregarSolicitudes && (
            <Card title="Controles de Solicitudes" style={gridStylePrincipal}>
              {puedeAgregarSolicitudes && (
                <Card.Grid style={gridStyle}>
                  <Nav.Link as={Link} to={`/home/solicitudes`}>
                    <LinkOutlined
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                    <strong> Nueva Solicitud</strong>
                  </Nav.Link>
                </Card.Grid>
              )}
              {puedeAgregarSolicitudes && (
                <Card.Grid style={gridStyle}>
                  <Nav.Link as={Link} to={`/home/impcoti`}>
                    <LinkOutlined
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                    <strong>Ver Solicitudes</strong>
                  </Nav.Link>
                </Card.Grid>
              )}
              {puedeAgregarSolicitudes && (
                <Card.Grid style={gridStyle}>
                  <Nav.Link as={Link} to={`/home/subasta`}>
                    <LinkOutlined
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                    <strong>Agregar/Editar Subasta</strong>
                  </Nav.Link>
                </Card.Grid>
              )}
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
}

const gridStyle = {
  width: "100%",
  textAlign: "center",
};

const gridStylePrincipal = {
  width: "100%",
  textAlign: "center",
  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
};

export default HomeInfo;
