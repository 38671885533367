import {ApiService} from "./ApiService";
import {server} from "../environments/environment";

export const getExportationContainerList = async (currentPage = 0, pageCount = 0, loadingDate = 0, origen = 0, search = "") => {
  const params = {
    loadingDate: loadingDate,
    currentPage: currentPage,
    pageCount: pageCount,
    origen: origen,
  };

  if (search !== "") params['search'] = search;

  const queryParams = new URLSearchParams(params).toString();

  const response = await ApiService.get(`${server}exportationContainer/list?${queryParams}`);
  const result = await response.json();

  if (result.status)
    return result;
  throw new Error(result.message);
}

export const getExportationContainer = async (createdDate = 0, loadingDate = 0, currentPage = 0,
                                              pageCount = 0, state = "", aduanaId = 0, carrierId = 0, origenId = 0,
                                              fromDate = 0, toDate = 0, countryId = 0, lote = "", departureFromDate = 0,
                                              departureToDate = 0) => {
  const params = {
    createdDate: createdDate,
    loadingDate: loadingDate,
    currentPage: currentPage,
    pageCount: pageCount,
    aduanaId: aduanaId,
    carrierId: carrierId,
    origenId: origenId,
    fromDate: fromDate,
    toDate: toDate,
    countryId: countryId,
    departureFromDate: departureFromDate,
    departureToDate: departureToDate
  };

  if (state !== "") params['state'] = state;
  if (lote !== "") params['lote'] = lote;

  const queryParams = new URLSearchParams(params).toString();

  const response = await ApiService.get(`${server}exportationContainer?${queryParams}`);
  const result = await response.json();

  if (result.status)
    return result;
  throw new Error(result.message);
}

export const getExportationContainerById = async (id) => {
  const response = await ApiService.get(`${server}exportationContainer/${id}`);
  const result = await response.json();

  if (result.status)
    return result.data;
  throw new Error(result.message);
}

export const addExportationContainer = async (exportationContainer) => {
  const response = await ApiService.post(`${server}exportationContainer`, exportationContainer);
  const result = await response.json();

  if (result.status)
    return result.data;
  throw new Error(result.message);
}

export const updateExportationContainer = async (id, exportationContainer) => {
  const response = await ApiService.put(`${server}exportationContainer/${id}`, exportationContainer);
  const result = await response.json();

  if (result.status)
    return result.data;
  throw new Error(result.message);
}

export const updateExportationContainerLoaded = async (id, exportationContainer) => {
  const response = await ApiService.put(`${server}exportationContainer/${id}/loaded`, exportationContainer);
  const result = await response.json();

  if (result.status)
    return result.data;
  throw new Error(result.message);
}

export const updateExportationContainerValue = async (id, exportationContainer) => {
  const response = await ApiService.put(`${server}exportationContainer/${id}/containerValue`, exportationContainer);
  const result = await response.json();

  if (result.status)
    return result.data;
  throw new Error(result.message);
}

export const getPlanificacionContenedorPdf = async (loadingDate, idOrigen) => {
  const response = await ApiService.get(`${server}exportationContainer/${loadingDate}/planificacionContenedorPdf?idOrigen=${idOrigen}`);
  const result = await response.json();

  if (result.status)
    return result.data;
  throw new Error(result.message);
}

export const updateExportationContainerSequence = async (id, exportationContainer) => {
  const response = await ApiService.put(`${server}exportationContainer/${id}/sequence`, exportationContainer);
  const result = await response.json();

  if (result.status)
    return result.data;
  throw new Error(result.message);
}
