import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, Avatar, Steps, Typography, Row, Col, Alert } from "antd";
import { UserOutlined, LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { ImagePreview } from "../../Componentes/ImagePreview";
import { MdConfirmationNumber, MdNumbers } from "react-icons/md";
import { FaCar, FaUser, FaWarehouse } from "react-icons/fa";
import { LiaBarcodeSolid } from "react-icons/lia";
import { HiPhoto, HiBuildingOffice2 } from "react-icons/hi2";
import { RiUserLocationFill } from "react-icons/ri";
import { FaMapLocationDot, FaLocationDot } from "react-icons/fa6";
import { GiCarrier, GiCrane } from "react-icons/gi";
import { TbBrandBooking, TbWorldPin } from "react-icons/tb";
import { PiShippingContainerFill } from "react-icons/pi";
import { getListFolderId } from "../../Servicios/GoogleService";
import { QuoteEntryType } from "../../utils/quote-entry-type";
import { getTrackingByLoteOrVim } from "../../Servicios/TrackingService";

const UserCardClient = ({ data, folderId, loading }) => (
  <>
    <Card className="bg-gray-100 mb-3" loading={loading}>
      <Card.Meta
        avatar={
          <Avatar
            size={64}
            icon={
              data?.vehicle?.imageFront ? (
                <ImagePreview
                  src={data?.vehicle?.imageFront}
                  alt={"vehiculo"}
                />
              ) : (
                <UserOutlined />
              )
            }
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        }
        title={`${data?.vehicle?.year} ${data?.vehicle?.make} ${data?.vehicle?.model}`}
        description={
          data?.vehicle?.vin && (
            <Typography.Paragraph strong copyable>
              {data?.vehicle?.vin}
            </Typography.Paragraph>
          )
        }
      />
      <div style={{ marginTop: 20 }}>
        <Row style={{ gap: 10 }}>
          <MdConfirmationNumber size={20} />
          <Typography.Paragraph strong>{"Lote"}:</Typography.Paragraph>{" "}
          <Typography.Paragraph copyable>{data?.lote}</Typography.Paragraph>
        </Row>
        <Row style={{ gap: 10 }}>
          <FaCar size={20} />
          <Typography.Paragraph strong>{"Vehiculo"}:</Typography.Paragraph>
          <Typography.Paragraph>
            {data?.vehicle?.year} {data?.vehicle?.make} {data?.vehicle?.model}
          </Typography.Paragraph>
        </Row>
        <Row style={{ gap: 10 }}>
          <LiaBarcodeSolid size={20} />
          <Typography.Paragraph strong>{"VIN"}:</Typography.Paragraph>
          <Typography.Paragraph>{data?.vehicle?.vin}</Typography.Paragraph>
        </Row>
        <Row style={{ gap: 10 }}>
          <HiPhoto size={20} />
          <Typography.Paragraph strong>{"Fotos"}:</Typography.Paragraph>
          {folderId?.length ? (
            <Typography.Paragraph underline>
              <a
                href={`
              https://drive.google.com/drive/folders/${folderId}
              `}
                target="_blank"
                rel="noreferrer"
              >
                {"Hacer click para ver fotos"}
              </a>
            </Typography.Paragraph>
          ) : (
            <Typography.Paragraph>
              {"No hay fotos disponibles"}
            </Typography.Paragraph>
          )}
        </Row>
        {/* <Row style={{ gap: 10 }}>
        <FaFileContract size={20} />
        <Typography.Paragraph strong>Foto del titulo:</Typography.Paragraph>
        {data?.vehicle?.imageTitle ? (
          <ImagePreview
            src={data?.vehicle?.imageTitle}
            alt={"titulo"}
            type="text"
          />
        ) : (
          <Typography.Paragraph>No hay foto disponible</Typography.Paragraph>
        )}
      </Row> */}
      </div>
    </Card>
    <Card className="bg-gray-100 mb-3" loading={loading}>
      <Typography.Title strong underline level={4} style={{ marginTop: 20 }}>
        {"Datos del Cliente"}
      </Typography.Title>
      <Row style={{ gap: 10 }}>
        <FaUser size={20} />
        <Typography.Paragraph strong>{"Cliente"}:</Typography.Paragraph>
        <Typography.Paragraph>
          {data?.quote?.importadora?.name}
        </Typography.Paragraph>
      </Row>
      <Row style={{ gap: 10 }}>
        <RiUserLocationFill size={20} />
        <Typography.Paragraph strong>{"Dirección"}:</Typography.Paragraph>
        <Typography.Paragraph>
          {data?.quote?.importadora?.address}
        </Typography.Paragraph>
      </Row>
      <Row style={{ gap: 10 }}>
        <FaMapLocationDot size={20} />
        <Typography.Paragraph>
          {data?.quote?.importadora?.city}{" "}
          {data?.quote?.importadora?.state ? "," : ""}{" "}
          {data?.quote?.importadora?.state}{" "}
          {data?.quote?.importadora?.country ? "," : ""}{" "}
          {data?.quote?.importadora?.country}{" "}
          {data?.quote?.importadora?.zipCode ? "," : ""}{" "}
          {data?.quote?.importadora?.zipCode}
        </Typography.Paragraph>
      </Row>
    </Card>
  </>
);

UserCardClient.propTypes = {
  data: PropTypes.shape({
    vehicle: PropTypes.shape({
      imageFront: PropTypes.string,
      imageTitle: PropTypes.string,
      year: PropTypes.number,
      make: PropTypes.string,
      model: PropTypes.string,
      vin: PropTypes.string,
    }),
    lote: PropTypes.string,
    quote: PropTypes.shape({
      importadora: PropTypes.shape({
        name: PropTypes.string,
        address: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        country: PropTypes.string,
        zipCode: PropTypes.string,
      }),
    }),
  }).isRequired,
  folderId: PropTypes.string,
  loading: PropTypes.bool,
};

const DetalleExportacion = ({ data, loading }) => (
  <Card className="bg-gray-100 mb-3" loading={loading}>
    <Typography.Title strong underline level={4} style={{ marginTop: 10 }}>
      {"Detalle de la exportación"}
    </Typography.Title>
    <Row style={{ gap: 10 }}>
      <MdNumbers size={20} />
      <Typography.Paragraph strong>
        {"Número de referencia"}:
      </Typography.Paragraph>
      <Typography.Paragraph>
        {data?.exportation?.exportationCode}
      </Typography.Paragraph>
    </Row>
    <Row style={{ gap: 10 }}>
      <FaWarehouse size={20} />
      <Typography.Paragraph strong>{"Codigo de WR"}:</Typography.Paragraph>
      <Typography.Paragraph>
        {data?.warehouse?.warehouseCode}
      </Typography.Paragraph>
    </Row>
    <Row style={{ gap: 10 }}>
      <GiCarrier size={20} />
      <Typography.Paragraph strong>{"Transportista"}:</Typography.Paragraph>
      <Typography.Paragraph>
        {data?.exportation?.carrierName}
      </Typography.Paragraph>
    </Row>
    <Row style={{ gap: 10 }}>
      <TbBrandBooking size={20} />
      <Typography.Paragraph strong>{"Número de reserva"}:</Typography.Paragraph>
      <Typography.Paragraph>
        {data?.exportation?.bookingNumber}
      </Typography.Paragraph>
    </Row>
    <Row style={{ gap: 10 }}>
      <PiShippingContainerFill size={20} />
      <Typography.Paragraph strong>
        {"Número de contenedor"}:
      </Typography.Paragraph>
      <Typography.Paragraph>
        {data?.exportation?.containerNumber}
      </Typography.Paragraph>
    </Row>
  </Card>
);

DetalleExportacion.propTypes = {
  data: PropTypes.shape({
    exportation: PropTypes.shape({
      exportationCode: PropTypes.string,
      carrierName: PropTypes.string,
      bookingNumber: PropTypes.string,
      containerNumber: PropTypes.string,
      loadingDate: PropTypes.string,
      estimatedTimeDeparture: PropTypes.string,
      estimatedTimeArrival: PropTypes.string,
      portCodeLoading: PropTypes.shape({
        name: PropTypes.string,
      }),
      portCodeUnloading: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    warehouse: PropTypes.shape({
      warehouseCode: PropTypes.string,
    }),
  }).isRequired,
  loading: PropTypes.bool,
};

const Origin = ({ data, loading }) => (
  <Card className="bg-gray-100 mb-3" loading={loading}>
    <Row style={{ gap: 10 }}>
      <Typography.Title strong underline level={4} style={{ marginTop: 10 }}>
        {"Detalle de origen"}
      </Typography.Title>
    </Row>
    <Row style={{ gap: 10 }}>
      <HiBuildingOffice2 size={20} />
      <Typography.Paragraph strong>{"Oficina"}:</Typography.Paragraph>
      <Typography.Paragraph>{data?.quote?.origen?.name}</Typography.Paragraph>
    </Row>

    <Row style={{ gap: 10 }}>
      <FaLocationDot size={20} />
      <Typography.Paragraph strong>{"Dirección"}:</Typography.Paragraph>
      <Typography.Paragraph>
        {data?.quote?.origen?.address}
      </Typography.Paragraph>
    </Row>

    <Row style={{ gap: 10 }}>
      <FaMapLocationDot size={20} />
      <Typography.Paragraph>
        {data?.quote?.origen?.city}, {data?.quote?.origen?.state},{" "}
        {data?.quote?.origen?.zipCode}
      </Typography.Paragraph>
    </Row>
  </Card>
);

Origin.propTypes = {
  data: PropTypes.shape({
    quote: PropTypes.shape({
      origen: PropTypes.shape({
        name: PropTypes.string,
        address: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      }),
    }),
  }).isRequired,
  loading: PropTypes.bool,
};

const Destination = ({ data, loading }) => (
  <Card className="bg-gray-100 mb-3" loading={loading}>
    <Row style={{ gap: 10 }}>
      <Typography.Title strong underline level={4} style={{ marginTop: 10 }}>
        {"Detalle de destino"}
      </Typography.Title>
    </Row>
    <Row style={{ gap: 10 }}>
      <TbWorldPin size={20} />
      <Typography.Paragraph strong>{"País"}:</Typography.Paragraph>
      <Typography.Paragraph>
        {data?.quote?.destino?.country}
      </Typography.Paragraph>
    </Row>
    <Row style={{ gap: 10 }}>
      <GiCrane size={20} />
      <Typography.Paragraph strong>
        {"Detalle de destino"}:
      </Typography.Paragraph>
      <Typography.Paragraph>{data?.quote?.destino?.name}</Typography.Paragraph>
    </Row>
  </Card>
);

Destination.propTypes = {
  data: PropTypes.shape({
    quote: PropTypes.shape({
      destino: PropTypes.shape({
        country: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  }).isRequired,
  loading: PropTypes.bool,
};

const DetailTracking = ({ show, loteOrVim }) => {
  const [data, setData] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [numberCurrent, setNumberCurrent] = useState(0);
  const [folderId, setFolderId] = useState(null);
  const [error, setError] = useState(null);
  const createItem = (title, date) => ({
    title,
    description: date ? dayjs(date).format("DD-MM-YYYY") : "- -",
  });

  const createPickupDateItem = (data) => ({
    title: "Fecha estimada de retiro subasta / ubicacion",
    description: data?.domestic?.pickupDate ? (
      <div>
        {dayjs(data?.domestic?.pickupDate).format("DD-MM-YYYY")}
        <br />
        <Typography.Text type="secondary">
          {"Destino"}: {data?.quote?.subasta?.name} <br />
          {"Dirección"}: {data?.quote?.subasta?.address} <br />
          {data?.quote?.subasta?.city}
          {data?.quote?.subasta?.city ? "," : ""}{" "}
          {data?.quote?.subasta?.zipCode}
        </Typography.Text>
      </div>
    ) : (
      <div>
        {"- -"}
        <br />
        <Typography.Text type="secondary">
          {"Destino"}: {data?.quote?.subasta?.name} <br />
          {"Dirección"}: {data?.quote?.subasta?.address} <br />
          {data?.quote?.subasta?.city}
          {data?.quote?.subasta?.city ? "," : ""}{" "}
          {data?.quote?.subasta?.zipCode}
        </Typography.Text>
      </div>
    ),
  });

  const createReceivedDateItem = (data) => ({
    title: "Fecha de recibo en bodega",
    description: data?.vehicle?.receivedDate ? (
      <div>
        {dayjs(data?.vehicle?.receivedDate).format("DD-MM-YYYY")}
        <br />
        <Typography.Text type="secondary">
          {"Destino"}: Oficina{" "}
          {(data?.quote?.origen?.name ?? "").charAt(0).toUpperCase() +
            (data?.quote?.origen?.name
              ? data.quote.origen.name.slice(1).toLowerCase()
              : "")}{" "}
          <br />
          {"Dirección"}: {data?.quote?.origen?.address} <br />
          {data?.quote?.origen?.city}, {data?.quote?.origen?.zipCode}
        </Typography.Text>
      </div>
    ) : (
      "- -"
    ),
  });

  const createTitleItem = (data) => ({
    title: "Titulo",
    description: data?.vehicle?.titleStatus ? (
      <>
        <Typography.Text type="secondary">{"Titulo Recibido"} </Typography.Text>
        <br />
        <Typography.Text type="secondary">
          {"Fecha de recibo"}:{" "}
          {dayjs(data?.vehicle?.receivedTitleDate).format("DD-MM-YYYY")}
        </Typography.Text>
      </>
    ) : (
      `No se ha entrego el titulo`
    ),
    status: data?.vehicle?.titleStatus ? "finish" : "error",
  });

  const createEstimatedDateItem = (title, date, portName) => ({
    title,
    description: date ? (
      <div>
        {dayjs(date).format("DD-MM-YYYY")}
        <br />
        <Typography.Text type="secondary">
          {"Puerto"}: {portName}
        </Typography.Text>
      </div>
    ) : (
      "- -"
    ),
  });

  const processItems = (baseItems, data) => {
    const lastIndex = baseItems.findIndex((item) => item.description === "- -");
    const finalItems = [...baseItems];

    if (lastIndex !== -1) {
      finalItems.splice(lastIndex, 0, {
        title: "En proceso",
        description: "Actualmente en proceso",
        status: "process",
        icon: <LoadingOutlined />,
      });
      return { finalItems, currentStep: lastIndex };
    } else {
      finalItems.push({
        title: "Finalizado",
        description: "Proceso completado",
        status: "finish",
      });
      return { finalItems, currentStep: baseItems.length };
    }
  };

  const fetchGetListFolderId = async (folderId) => {
    try {
      const data = await getListFolderId(folderId);
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  useEffect(() => {
    if (data) {
      const createBaseItems = (data) => {
        const items = [
          createItem("Fecha de solicitud", data?.quote?.date),
          createItem(
            "Fecha estimada de entrega en bodega",
            data?.domestic?.deliveryDate
          ),
          createReceivedDateItem(data),
          createTitleItem(data),
          createItem("Fecha de carga", data?.exportation?.loadingDate),
          createEstimatedDateItem(
            "Fecha estimada de salida (ETD)",
            data?.exportation?.estimatedTimeDeparture,
            data?.exportation?.portCodeLoading?.name
          ),
          createEstimatedDateItem(
            "Fecha estimada de llegada (ETA)",
            data?.exportation?.estimatedTimeArrival,
            data?.exportation?.portCodeUnloading?.name
          ),
          createItem("Fecha de entrega de Documento", data?.exportation?.documentDeliveryDate),
        ];

        if (
          data?.quote?.subasta?.name !==
          QuoteEntryType.entrega_en_bodega_por_cliente
        ) {
          items.splice(
            1,
            0,
            createItem("Fecha de pago en subasta", data?.domestic?.paymentDate)
          );
          items.splice(2, 0, createPickupDateItem(data));
        }

        return items;
      };

      const baseItems = createBaseItems(data);
      const { finalItems, currentStep } = processItems(baseItems, data);
      setItems(finalItems);
      setNumberCurrent(currentStep);

      fetchGetListFolderId(data?.lote).then((data) => {
        setFolderId(data.data);
      });
    }
  }, [data]);

  useEffect(() => {
    console.log("show", show);
    if (show) {
      setError(null);
      setIsLoading(true);
      getTrackingByLoteOrVim(loteOrVim)
        .then((data) => {
          if (data?.status === false) {
            throw new Error("Vehiculo no encontrado");
          } else {
            setData(data.data);
          }
        })
        .catch((error) => {
          console.error(error, "Error al obtener el tracking");
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [show, loteOrVim]);
  
  if (error) {
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto">
          <Alert
            message="Error"
            description={error.message}
            type="error"
            showIcon
          />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white">
      <div className="bg-gray-100 w-full">
        <div className="max-w-7xl mx-auto ">
          <Row style={{ marginTop: 20 }} gutter={[20, 20]}>
            <Col xs={24} md={12}>
              <UserCardClient data={data} folderId={folderId} loading={isloading}/>
              {data?.exportation.carrierName && (
                <DetalleExportacion data={data} loading={isloading} />
              )}
            </Col>
            <Col xs={24} md={12}>
              <Origin data={data} loading={isloading} />
              <Destination data={data} loading={isloading} />
            </Col>
          </Row>
        </div>
      </div>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto">
          <Row justify="center" style={{ marginTop: 20 }} className="px-5">
            <Col xs={24} md={24}>
              <div>
                <Row justify="space-between">
                  <Typography.Title
                    strong
                    underline
                    level={4}
                    style={{ marginTop: 10 }}
                  >
                    {" "}
                    {"Historial de movimientos"}{" "}
                  </Typography.Title>
                  {/* {data?.notes?.length > 0 && (
                    <CommentsPreview data={data.notes} />
                  )} */}
                </Row>
                <Steps
                  direction="vertical"
                  current={numberCurrent}
                  items={items}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default DetailTracking;
