import { server } from "../environments/environment";
import moment from "moment";
import { ApiService } from "./ApiService";

const URL = `${server}`;
// traer el numero del mes
const mes = moment().format("MM");
// traer el numero del año
const anio = moment().format("YYYY");

export const topSellers = async () => {
  const response = await ApiService.get(
    `${URL}Quote/RangeCreator?mes=${mes}&anio=${anio}`
  );
  const data = await response.json();

  let option = [];
  let series = [];

  data.map((item) => {
    item.creador = item.creador
      .split(" ")
      .map((name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase())
      .join(" ");
    option.push(item.creador);
    series.push(item.count);
  });

  return { option, series };
};

export const totalRequestMount = async () => {
  const response = await ApiService.get(
    `${URL}dashboard/summaryTotalSolicitudesPorMes`
  );
  const data = await response.json();
  return data.data;
};

export const totalWeeklytotals = async () => {
  const response = await ApiService.get(`${URL}Quote/weeklytotals`);
  const data = await response.json();
  console.log("weeklytotals", data);
  return data;
};

export const topClientes = async () => {
  const response = await ApiService.get(
    `${URL}Quote/topClients?mes=${mes}&anio=${anio}`
  );
  const data = await response.json();
  return data;
};

export const totalCoordinacionPerOrigenByMonth = async () => {
  const year = moment().format("YYYY");
  const month = moment().format("MM");
  const response = await ApiService.get(
    `${URL}Dashboard/totalCoordinacionPerOrigen?year=${year}&month=${month}`
  );
  const { data } = await response.json();
  return data;
};

export const totalCoordinacionPerOrigenByMonthRequest = async () => {
  const year = moment().format("YYYY");
  const month = moment().format("MM");
  const response = await ApiService.get(
    `${URL}Dashboard/totalSolicitudPerOrigen?year=${year}&month=${month}`
  );
  const { data } = await response.json();
  return data;
};

export const totalCoordinacionPerCountry = async () => {
  const response = await ApiService.get(
    `${URL}dashboard/totalCoordinacionPerCountry`
  );
  const { data } = await response.json();
  return data;
};

export const totalVehiculosEnBodegaPerOrigen = async () => {
  const response = await ApiService.get(
    `${URL}dashboard/totalVehiculosEnBodegaPerOrigen`
  );
  const { data } = await response.json();
  return data;
};



export const totalCoordinacionByWeek = async (NumberOfWeek) => {
  const ActualityYear = moment().format("YYYY");
  const response = await ApiService.get(
    `${URL}dashboard/totalCoordinacionPerWeek?year=${ActualityYear}&week=${NumberOfWeek}`
  );
  const { data } = await response.json();
  return data;
};

export const totalSolicitudesByWeek = async (NumberOfWeek) => {
  const ActualityYear = moment().format("YYYY");
  const response = await ApiService.get(
    `${URL}dashboard/totalSolicitudesPerWeek?year=${ActualityYear}&week=${NumberOfWeek}`
  );
  const { data } = await response.json();
  return data;
};

export const totalSolicitudesByWeekBySellers = async (origen, countryId, week) => {
  const year = moment().format("YYYY");
  const response = await ApiService.get(
    `${URL}dashboard/totalSolicitudesPerVendedoresPerWeek?origen=${origen}&countryId=${countryId}&year=${year}&week=${week}`
  );
  const { data } = await response.json();
  return data;
};


export const totalContenedoresPorOrigen = async (date) => {
  const response = await ApiService.get(
    `${URL}dashboard/totalContenedoresPorOrigen?fecha=${date}`
  );
  const { data } = await response.json();
  return data;
};


export const totalContenedoresPorDestino = async (id,date,idCountry) => {
  const response = await ApiService.get(
    `${URL}dashboard/totalContenedoresPorDestino/${id}?fecha=${date}&idCountry=${idCountry}`
  );
  const { data } = await response.json();
  return data;
};