import PropTypes from "prop-types";

// material-ui
import { styled } from "@mui/material/styles";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
// styles
const IconWrapper = styled("div")({
  position: "absolute",
  left: "-17px",
  bottom: "-27px",
  color: "#fff",
  transform: "rotate(25deg)",
  "& svg": {
    width: "100px",
    height: "100px",
    opacity: "0.35",
  },
});

const TextWrapper = styled("div")({
  position: "absolute",
  right: "-5%",
  bottom: "-10%",
  transform: "translate(-50%, -50%)",
  color: "#fff",
  textAlign: "center",
});

// =============================|| USER NUM CARD ||============================= //

const WalletCountCard = ({
  primary,
  secondary,
  tertiary = 0,
  showtertiary = false,
  textTertiary = "",
  iconPrimary,
  color,
  loading,
  pressable = false,
  onClick,
}) => {
  const IconPrimary = iconPrimary;
  const primaryIcon = iconPrimary ? <IconPrimary fontSize="large" /> : null;

  return (
    <Card
      elevation={0}
      sx={{
        background: color,
        position: "relative",
        color: "#fff",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
      }}
      style={{
        cursor: pressable ? "pointer" : "default",
      }}
      onClick={pressable ? onClick : null}
    >
      <CardContent>
        <IconWrapper>
          <img
            src={iconPrimary}
            alt="icon"
            style={{ width: "100px", height: "100px", opacity: "0.35" }}
          />
        </IconWrapper>
      

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item sm={12}>
            <Typography variant="h5" align="center" color="inherit">
              {primary}
            </Typography>
          </Grid>
          <Grid item sm={12}>


            <Typography variant="h5" align="center" color="inherit">
              {loading ? (
                <Spin
                  size="large"
                  tip="Cargando..."
                  style={{ color: "#fff" }}
                  indicator={<LoadingOutlined style={{ fontSize: 34 }} />}
                />
              ) : (
                `${textTertiary}${secondary}`
              )}
            </Typography>
            {showtertiary ? (
            <Typography variant="h5" color="inherit">
              Total vehículos: {tertiary}
            </Typography>
        ) : null}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

WalletCountCard.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string,
  iconPrimary: PropTypes.object,
  color: PropTypes.string,
};

export default WalletCountCard;
