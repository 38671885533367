import React, { useEffect, useState, useCallback } from "react";
import { Button, Form, message, Spin, Upload } from "antd";
import { GoogleOutlined, UploadOutlined } from "@ant-design/icons";
import {
  getUpdateInspeccion,
  redirectGoogleUrl,
  refreshToken,
} from "../../Servicios/UploadService";
import { errorMessage, successMessage } from "../../utils/toast-message";
import { getListFolderId } from "../../Servicios/GoogleService";
import { enviarEmail } from "../../Servicios/SolicitudesService";
import environment from "../../environments/environment";
import { StringFormats } from "../../utils/string-formats";
import { NotificacionEntregaVehiculoClienteEmail } from "../../Resources/HtmlEmailBody/NotificacionEntregaVehiculoCliente.email";
import { showQuotePoridvehi } from "../../Servicios/SolicitudesService";
import dayjs from "dayjs";
// tipoEvidencia puede ser: "raiz", "recibo", "carga" y "descarga", en dependencia de ese props se decide que servio llamar
const UploadGoogleDrive = ({
  vehiculo,
  fechaRecibido,
  open,
  tipoEvidencia,
  triggerUpload,
  handleCancelEvidence,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [existToken, setExistToken] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [form] = Form.useForm();

  const validationSignInGoogle = useCallback(() => {
    const tokenString = localStorage.getItem("googleTokens");
    if (tokenString) {
      const tokens = JSON.parse(tokenString);
      const currentTime = new Date().getTime();

      const expiresIn = tokens.expiry_date || 0;
 
      if (expiresIn < currentTime) {
        setExistToken(false);
      } else {
        setExistToken(true);
      }
    }
  }, []);

  useEffect(() => {
    if (open) {
      validationSignInGoogle();
    }
  }, [open, handleCancelEvidence, validationSignInGoogle]);
    

  const validationToken = async () => {
    const tokenString = localStorage.getItem("googleTokens");
    console.log("tokenString", tokenString);
    if (tokenString) {
      const tokens = JSON.parse(tokenString);

      console.log("refreshToken", tokens.refresh_token);
      const refreshedToken = await refreshToken(tokens.refresh_token);
      if (refreshedToken) {
        localStorage.setItem("googleTokens", JSON.stringify(refreshedToken));
        setExistToken(true);
        return true;
      } else {
        setExistToken(false);
        errorMessage("La sesión ha expirado. Por favor, inicie sesión de nuevo.");
        return false;
      }
    } else {
      setExistToken(false);
      errorMessage("La sesión ha expirado. Por favor, inicie sesión de nuevo.");
      return false;
    }
  };

  const onFinish = async (values) => {
    console.log("values", values);
    if (selectedFiles.length === 0) {
      message.error("Please select files to upload.");
      return;
    }

    setUploading(true);
    const  validate = await validationToken();
    if (!validate) {
      setUploading(false);
      return;
    }
    const formData = new FormData();
    values.Files.fileList.forEach((file) => {
      formData.append("Files", file.originFileObj);
    });

    console.log("vehiculo Fecha", fechaRecibido);
    if (!fechaRecibido) {
      errorMessage(
        "Debe especificar una fecha de recibido antes de subir la evidencia"
      );
      setUploading(false);
      return;
    }

    const requestBody = {
      lote: vehiculo.lote,
      origen:
        vehiculo.origen === "1"
          ? "FLORIDA"
          : vehiculo.origen === "2"
          ? "CALIFORNIA"
          : "PENNSYLVANIA",
      fechaRecibido: fechaRecibido,
      tokens: JSON.parse(localStorage.getItem("googleTokens")),
    };

    formData.append("body", JSON.stringify(requestBody));

    let UrlForTipoEvidencia = "";
    console.log("tipoEvidencia", tipoEvidencia);
    switch (tipoEvidencia) {
      case "raiz":
        UrlForTipoEvidencia = `${process.env.REACT_APP_URL_API_NODE_GOOGLE}/upload`;
        break;
      case "recibo":
        UrlForTipoEvidencia = `${process.env.REACT_APP_URL_API_NODE_GOOGLE}/upload/receipt`;
        break;
      case "carga":
        UrlForTipoEvidencia = `${process.env.REACT_APP_URL_API_NODE_GOOGLE}/upload/load`;
        break;
      case "descarga":
        UrlForTipoEvidencia = `${process.env.REACT_APP_URL_API_NODE_GOOGLE}/upload/unload`;
        break;

      default:
        break;
    }
    console.log("UrlForTipoEvidencia", UrlForTipoEvidencia);
    try {
      let response = await fetch(UrlForTipoEvidencia, {
        method: "POST",
        body: formData,
      });

      if (response && response.statusText === "OK") {
        successMessage(`Se ha subido la evidencia con exito!`);
        // if (tipoEvidencia !== "recibo") {
        if (tipoEvidencia !== "raiz") {
          setSelectedFiles([]);
          setUploading(false);
          form.resetFields();
        }
        await getUpdateInspeccion(vehiculo.vehiculoId);
        const elements = await getListFolderId(vehiculo.lote);

        const copyLink = `https://drive.google.com/drive/folders/${elements.data}`;

        setSelectedFiles([]);
        setUploading(false);
      }
    } catch (error) {
      errorMessage(`Subida fallida. Por favor, inténtelo de nuevo más tarde.`);
      throw new Error(`Subida fallida: ${error.toString()}`);
    }
  };

  const handleUpload = async () => {
    try {
      const values = await form.validateFields();
      console.log("values handle", values);

      if (values.Files.fileList && values.Files.fileList.length > 0) {
        await onFinish(values);
      }
    } catch (error) {
      console.error("Error uploading files", error);
    }
  };

  useEffect(() => {
    if (triggerUpload) {
      console.log("triggerUpload", triggerUpload);
      triggerUpload(handleUpload);
    }
  }, [selectedFiles]);

  const handleFileChange = (fileList) => {
    setSelectedFiles(fileList);
  };

  const templateTokenExists = () => {
    return (
      <Spin spinning={isLoading} tip="Cargando...">
        <div style={{ maxWidth: 500, margin: "0 auto", textAlign: "center" }}>
          <p className="fw-bold m-0">
            Seleccionar archivos para subir a Google Drive
          </p>
          <Form
            layout="vertical"
            form={form}
            initialValues={{
              layout: "vertical",
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={(errorInfo) => {
              console.log("Failed:", errorInfo);
            }}
          >
            <Form.Item
              name="Files"
              rules={[
                {
                  required: true,
                  message: "Porfavor seleccione una imagen.",
                },
              ]}
            >
              <Upload.Dragger
                listType="picture"
                fileList={selectedFiles}
                onChange={(info) => {
                  handleFileChange(info.fileList);
                }}
                accept="image/*,video/*"
                multiple
                beforeUpload={() => false}
              >
                Arrastre archivos de imagen o video a esta área
                <br />
                o
                <br />
                <Button icon={<UploadOutlined />}>Haga clic en Cargar</Button>
              </Upload.Dragger>
            </Form.Item>
            <Form.Item>
              {uploading ? (
                <Spin tip="Subiendo evidencias.." />
              ) : // ) : tipoEvidencia === "recibo" ? (
              tipoEvidencia === "raiz" ? (
                <Button
                  type="primary"
                  onClick={handleUpload}
                  disabled={uploading}
                >
                  Subir evidencia
                </Button>
              ) : null}
            </Form.Item>
          </Form>
        </div>
      </Spin>
    );
  };

  const templateTokenNotExists = () => {
    return (
      <div style={{ maxWidth: 500, margin: "0 auto", textAlign: "center" }}>
        <strong>
          Para subir evidencia debe iniciar sesión con su cuenta de Google
        </strong>
        <br />
        <br />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            type="primary"
            onClick={async () => await redirectGoogleUrl()}
            icon={<GoogleOutlined />}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "red",
              borderColor: "red",
            }}
          >
            Iniciar sesión
          </Button>
        </div>

        <br />
        <br />
      </div>
    );
  };

  useEffect(() => {
    const handleAuthMessage = (event) => {
      if (event.origin !== process.env.REACT_APP_URL_API_NODE_GOOGLE) {
        return;
      }

      localStorage.setItem("googleTokens", JSON.stringify(event.data.tokens));
      setExistToken(true);
    };

    window.addEventListener("message", handleAuthMessage);

    return () => {
      window.removeEventListener("message", handleAuthMessage);
    };
  }, []);

  return <>{existToken ? templateTokenExists() : templateTokenNotExists()}</>;
};

export default UploadGoogleDrive;
