import {ApiService} from "./ApiService";
import {server} from "../environments/environment";

export const getUserSellers = async () => {
  const response = await ApiService.get(`${server}usuarios/sellers`);
  const data = await response.json();

  return data.list;
}

export const getUserReceiving = async () => {
  const response = await ApiService.get(`${server}usuarios/receiving`);
  const data = await response.json();

  return data.list;
}

export const getUserWarehouses = async () => {
  const response = await ApiService.get(`${server}usuarios/warehouseOffices`);
  const data = await response.json();

  return data.list;
}
