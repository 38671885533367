import React, {useEffect, useState} from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Result,
  Form,
  Select,
  Input,
  Tabs,
  Checkbox,
  Space,
  Modal as ModalAntD,
  Spin,
} from "antd";
import Modal from "react-bootstrap/Modal";
import environment from "../environments/environment";
import {SmileTwoTone, DeleteFilled} from "@ant-design/icons";
import {useSelector, useDispatch} from "react-redux";
import moment from "moment";
import {listClients} from "../Servicios/ClienteService";
import {viewAduana} from "../Servicios/ComponedoresService";
import {getVehicleInput, listMake} from "../Servicios/VehicleService";
import SendEmail from "../utils/email";
import {
  showSubasta,
  showOrigen,
  showAduana,
} from "../Servicios/CombosService";
import {faFloppyDisk} from "@fortawesome/free-solid-svg-icons";

import {Form as FormB} from "react-bootstrap";
import {addSubasta} from "../Servicios/ComponedoresService";
import {showVehiclesoli, showVehicleLote} from "../Servicios/CombosService";
import useCurrentUser from "../Hooks/UserHook";
import {
  sendAllVehicles,
  deleteOneVehicle,
  deleteAllVehicle,
  saveAllVehicles,
  sendAllCoordinations,
  deleteOneCoordination,
  deleteAllCoordination,
  saveAllCoordination,
  deleteAllCoordinationSave,
} from "../store/solicitud";
import {CoordinacionNotasImportantes} from "../Resources/PdfBase64/CoordinacionNotasImportantes";
import {CoordinacionNotifacarClienteMultiple} from "../Resources/HtmlEmailBody/CoordinacionNotificarCliente";
import {CoordinacionEntryTypeEnum, TypePay} from "../shared/enums/coordinacion-entry-type.enum";
import InputSearchCustomer from "../Componentes/InputSearchCustomer/InputSearchCustomer";
import ClienteForm from "../Carteracliente/ClienteForm";
import {EditOutlined} from "@ant-design/icons";
import {showStates} from "../Servicios/WerehouseService";
import {showCountries} from "../Servicios/CombosService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";
import {errorMessage} from "../utils/toast-message";
import {getTitles} from "../Servicios/TitleService";
import {ClienteTypeEnum} from "../shared/enums/cliente-type.enum";

const {TextArea} = Input;
const {TabPane} = Tabs;

const MultipleCoordinaciones = () => {
  const [showFormCoordinacion, setShowFormCoordinacion] = useState(false);
  const [vehicle, setVehicle] = useState([]);
  const [make, setMake] = useState([]);
  const [model, setModel] = useState([]);
  const dateNow = moment().format("DD/MM/YYYY");
  const [vehicleSelected, setVehicleSelected] = useState(null);
  const [listVehicle, setListVehicle] = useState([]);
  const [listClient, setListClient] = useState([]);
  const [listOrigin, setListOrigin] = useState([]);
  const [listAduana, setListAduana] = useState([]);
  const [listSubasta, setListSubasta] = useState([]);
  const [countryId, setCountryId] = useState(null);
  const [importadoraId, setImportadoraId] = useState(null);
  const [selectedClient, setSelectedClient] = useState(undefined);
  const [showModalClient, setShowModalClient] = useState(false);
  const [selectedImportadora, setSelectedImportadora] = useState(null);
  const [showModalFinalClient, setShowModalFinalClient] = useState(false);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();
  const [isLoadingSub, setIsLoadingSub] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const {allCoordinations, allVehicles, allCoordinationSave} = useSelector(
    (state) => state.solicitud
  );
  const [canSaveVehicle, setCanSaveVehicle] = useState(true);
  const [titles, setTitles] = useState([]);

  const [formSolicitud] = Form.useForm();
  const currentUser = useCurrentUser();
  const [showForm, setShowForm] = useState(false);
  const [form] = Form.useForm();
  const [checked, setChecked] = useState(false);
  const [checkedEntryType, setCheckedEntryType] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [showA, setShowA] = useState(false);

  const handleShowA = () => setShowA(true);
  const handleCloseA = () => setShowA(false);
  const [addSubastas, setAddSubastas] = useState({
    subId: "",
    subName: "",
    addressLine1Sub: "",
    addressLine2Sub: "",
    city: "",
    state: null,
    zipCode: null,
    countryId: 6,
    identf: null,
    phoneNumber: null,
  });

  const onSaveVehicle = async (values) => {
    values.estado = "NO_SOLICITUD";
    values.make = values.make[0];
    values.model = values.model[0];
    values.year = Number(values.year);

    const loteVehiculo = await showVehicleLote(values.lote);
    console.log("loteVehiculo", loteVehiculo);

    // validar que si lotevehiculo.data es mayor a 0 entonces no se puede agregar el vehiculo
    console.log("loteVehiculo.data.length", loteVehiculo);
    if (loteVehiculo?.list?.length > 0) {

      toast.error("El lote ya existe, por favor verifique el lote ingresado", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      const input = {
        ...getVehicleInput(),
        ...values,
      };
      dispatch(sendAllVehicles(input));
      form.resetFields();
      setShowForm(false);
    }
  };

  const onChangeCheckbox = (e) => {
    console.log("checked = ", e.target.checked);
    setChecked(e.target.checked);
  };

  const onChangeCheckboxEntryType = (e) => {
    setCheckedEntryType(e.target.checked);

    formSolicitud.setFieldsValue({
      entryType: e.target.checked
        ? CoordinacionEntryTypeEnum.entryTypeByClient
        : CoordinacionEntryTypeEnum.entryTypeByOwn,
    });
  };

  const handleSelectChangeCliente = (client) => {
    console.log("client", client);
    setSelectedClient(client);
    setSelectedImportadora(client.importadora);
    setImportadoraId(client.importadora);
    formSolicitud.setFieldsValue({
      type_pay: client.classPay,
    });
  };

  const handleSaveCustomer = (customer) => {
    if (showModalClient) {
      setSelectedClient(customer);
      console.log("customer", customer);
      setSelectedImportadora(customer.importadora);
      setShowModalClient(false);
    }

    if (showModalFinalClient) {
      // setSelectedFinalClient(customer);
      setShowModalFinalClient(false);
    }
  };

  const handleCancelCustomer = () => {
    if (showModalClient) setShowModalClient(false);
    if (showModalFinalClient) setShowModalFinalClient(false);
  };

  const onSaveCoordinacion = (values) => {
    values.fechacor = moment().format("YYYY-MM-DD");
    values.countryId = countryId?.countryId;
    values.importadoraId = selectedImportadora?.id;
    values.buyer = Number(values.buyer);
    values.rate_Quoted = Number(values.rate_Quoted);
    values.createBy = currentUser?.fullName;
    values.updateBy = "";
    values.updateDate = null;
    values.cdealer = checked;
    values.clienteId = selectedClient?.id;
    values.subastaId = checkedEntryType ? null : values.subastaId;
    console.log("values coordinacion", values);

    // values.entryType = values.entryType;
    dispatch(sendAllCoordinations(values));
    formSolicitud.setFieldsValue({
      vehiculoId: "",
      quoted: "",
      // rate_Quoted: "",
      // bl: "",
      // costoQ: "",
      comentarios: "",
    });
    setShowFormCoordinacion(false);
  };

  const notifySub = () =>
    toast.success("Se guardo nueva subasta, puede seleccionarla en la lista", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const fetchMake = async () => {
    const list = await listMake();
    setMake(list);
  };

  const fetchTitles = async () => {
    const data = await getTitles();
    setTitles(data);
  };

  const fetchVehicle = async () => {
    setIsLoading(true);
    const list = await showVehiclesoli("NO_SOLICITUD");
    console.log("list Vehiculos", list);
    setListVehicle(list);
    setVehicle(
      // filter the list of vehicles to allcoordinations and show only the vehicles that are not in the list of allcoordinations
      allCoordinations?.length > 0 && list?.length > 0
        ? list
          .filter(
            (item) =>
              !allCoordinations.some(
                (coordination) => coordination.vehiculoId === item.vehiculoId
              )
          )
          .map((item) => {
            return {
              value: item.vehiculoId,
              label: `NAME: ${item?.vehiculoName} - VIN: ${item?.vin} - MAKE: ${item?.make} - MODEL: ${item?.model} - LOTE: ${item?.lote} - YEAR: ${item?.year}`,
            };
          })
        : list.map((item) => {
          return {
            value: item.vehiculoId,
            label: `NAME: ${item?.vehiculoName} - VIN: ${item?.vin} - MAKE: ${item?.make} - MODEL: ${item?.model} - LOTE: ${item?.lote} - YEAR: ${item?.year}`,
          };
        })
    );
  };

  const fetchClient = async (idpais) => {
    console.log("idpais", idpais);
    const list = await listClients(idpais);
    console.log("list Client", list.list);
    setListClient(
      list?.list.map((item) => {
        return {
          value: item.id,
          label: `${item.nombreCompleto} - ${item.importadoraName}`,
        };
      })
    );
  };

  const loadStates = async () => {
    const data = await showStates();
    setStates(data);
  };
  const loadCountries = async () => {
    const data = await showCountries();
    setCountries(data);
  };

  const fetchOrigin = async () => {
    const list = await showOrigen();
    console.log("list Origin", list);
    setListOrigin(
      list.map((item) => {
        return {
          value: item.id,
          label: `${item.nameOrigen}`,
        };
      })
    );
  };

  const fetchSubasta = async () => {
    const data = await showSubasta();
    setListSubasta(
      data.list.map((item) => {
        return {
          value: item.subId,
          label: `${item.subName} - ${item.direccion}`,
        };
      })
    );
  };

  const fetchAduana = async () => {
    const list = await showAduana();
    console.log("list Aduana", list);
    setListAduana(
      list.map((item) => {
        return {
          value: item.id,
          label: `${item.nameAduana} - ${item.countryName}`,
        };
      })
    );
  };

  const handleChange = (value) => {
    make.map((item) => {
      if (item.make === value[0]) {
        setModel(
          item.models.map((item) => {
            return {
              value: item,
              label: item,
            };
          })
        );
      }
    });
  };

  const onChangeVehicle = (value) => {
    setVehicleSelected(listVehicle.find((item) => item.vehiculoId === value));
    console.log("vehicle selected", listVehicle.find((item) => item.vehiculoId === value));
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  // const onChangeCliente = (value) => {
  //   viewClient(value).then((client) => {
  //     setImportadoraId(client);
  //   });
  // };

  const onChange = (value) => {
    console.log("item seleccionado", value);
  };

  const onChangeAduana = (value) => {
    viewAduana(value).then((adu) => {
      setCountryId(adu);
      formSolicitud.setFieldsValue({
        bl: adu.countryId === 3 ? 0 : 20
      });
    });
  };

  const options = [];
  for (let i = 0; i < make.length; i++) {
    options.push({
      value: make[i].make,
      label: make[i].make,
    });
  }

  useEffect(() => {
    if (currentUser) {
      Promise.all([
        fetchVehicle(),
        fetchMake(),
        fetchClient(currentUser.paisId),
        fetchOrigin(),
        fetchAduana(),
        fetchSubasta(),
        loadStates(),
        loadCountries(),
        fetchTitles(),
      ]).finally(() => {
        setIsLoading(false);

        formSolicitud.setFieldsValue({
          bl: 20
        });
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (allCoordinationSave?.length > 0) {
      setIsLoadingSave(false);
      setShowSendEmail(true);
      console.log("allCoordinationSave DATAAAA", allCoordinationSave);
    }
  }, [allCoordinationSave]);

  const handleSendEmail = () => {
    dispatch(deleteAllCoordinationSave([]));
    setShowSendEmail(false);
  };

  const insertarSubasta = async () => {
    try {
      setIsLoadingSub(true);
      const data = await addSubasta({
        ...addSubastas,
      });
      if (data) {
        notifySub();
        fetchSubasta();
        handleCloseA();
        formSolicitud.setFieldsValue({
          subastaId: data.subId,
        });
      }
      setIsLoadingSub(false);
    } catch (ex) {
      console.log(ex);
    }
  };

  const handleOnSelectTitle = (event) => {
    const title = titles.find((e) => e.idTitle === event);
    setCanSaveVehicle(title.embarkable);

    if (!title.embarkable) {
      errorMessage(
        "LA SOLICITUD NO PUEDE CREARSE POR QUE EL VEHÍCULO NO ES EMBARCABLE"
      );
    }
  };


  return (
    <>

      {showSendEmail ? (
        <SendEmail
          visible={true}
          subject={`CONFIRMACION DE SOLICITUD ${
            allCoordinationSave[0].origen.state
          } #LOTE: ${allCoordinationSave
            ?.map((item) => item.lote)
            .join(", ")} DEL CLIENTE: ${
            allCoordinationSave[0]?.clienteName
          } DESTINO: ${allCoordinationSave[0]?.aduanaName}`}
          titulo={`Enviar correo electronico`}
          to={
            allCoordinationSave[0].coDos && allCoordinationSave[0].coDos !== ""
              ? allCoordinationSave[0].emailCli &&
              allCoordinationSave[0].emailCli !== ""
                ? [
                  ...allCoordinationSave[0].emailCli
                    .replace(/\s/, "")
                    .split(","),
                  ...allCoordinationSave[0].coDos,
                ]
                : [allCoordinationSave[0].coDos]
              : allCoordinationSave[0].emailCli &&
              allCoordinationSave[0].emailCli !== ""
                ? [
                  ...allCoordinationSave[0].emailCli
                    .replace(/\s/, "")
                    .split(","),
                ]
                : []
          }
          cc={currentUser && currentUser.email
            ? [environment.emails.grua, currentUser.email]
            : [environment.emails.grua]}
          body={CoordinacionNotifacarClienteMultiple(allCoordinationSave)}
          attachments={[
            {
              nombreArchivo: "notas_importantes.pdf",
              archivo: CoordinacionNotasImportantes,
            },
          ]}
          setVisible={() => setShowSendEmail(false)}
          showMessageInput={false}
          isLoading={false}
          doSendEmail={() => handleSendEmail()}
        />
      ) : (
        <></>
      )}
      <div>
        <h3>Ingreso de Coordinaciones a Domestic</h3>
        <hr/>
        <Tabs
          defaultActiveKey="0"
          onChange={async () => {
            await fetchVehicle().finally(() => {
              setIsLoading(false);
            });
          }}
        >
          <TabPane tab="Registro de vehiculos" key="1">
            {!showForm && (
              <Row gutter={24}>
                {
                  /* Agregar un box vacio para agregar mas solicitudes */
                  allVehicles?.length === 0 ? (
                    <Col span={24}>
                      <Result
                        icon={<SmileTwoTone twoToneColor="#e8e8e8"/>}
                        title="No hay vehiculos pendiente."
                        style={{
                          border: "1px solid #e8e8e8",
                          borderRadius: "6px",
                        }}
                      />
                    </Col>
                  ) : (
                    // obtener los vehiculos del estado de solicitud
                    allVehicles?.map((item, index) => (
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}
                        xl={6}
                        xxl={4}
                        style={{
                          marginBottom: "20px",
                        }}
                      >
                        <Card
                          style={{
                            border: "1px solid #e8e8e8",
                            borderRadius: "6px",
                          }}
                        >
                          <p>VIN: {item.vin}</p>
                          <p>Lote: {item.lote}</p>
                          <p>Marca: {item.make}</p>
                          <p>Modelo: {item.model}</p>
                          <p>Año: {item.year}</p>
                          <p>Color: {item.color}</p>

                          <Button
                            type="primary"
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            danger
                            block
                            onClick={() => {
                              console.log("eliminar vehiculo", item.vin);
                              dispatch(deleteOneVehicle(item.vin));
                            }}
                          >
                            <DeleteFilled/>
                          </Button>
                        </Card>
                      </Col>
                    ))
                  )
                }
              </Row>
            )}

            <Row gutter={24}>
              {!showForm && (
                <Col
                  span={24}
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    href="#"
                    type="primary"
                    onClick={() => {
                      setShowForm(true);
                    }}
                  >
                    Agregar nuevo vehiculo
                  </Button>
                  {allVehicles?.length > 0 && (
                    <Button
                      type="success"
                      style={{
                        marginLeft: "20px",
                        backgroundColor: "green",
                        color: "#fff",
                      }}
                      onClick={() => {
                        dispatch(saveAllVehicles(allVehicles));
                      }}
                    >
                      Guardar todos los vehiculos agregados
                    </Button>
                  )}

                  {allVehicles?.length > 1 && (
                    <Button
                      type="primary"
                      style={{
                        marginLeft: "20px",
                      }}
                      onClick={() => {
                        dispatch(deleteAllVehicle());
                      }}
                      danger
                    >
                      Eliminar todos los vehiculos agregados
                    </Button>
                  )}
                </Col>
              )}
            </Row>

            <Row gutter={24}>
              {/* agregar un Form responsive con ant design que lleve los campos: vin, lote: required, marca: required, modelo:required, select año:required, color  */}
              {showForm && (
                <Col span={24}>
                  <Form
                    layout="vertical"
                    size="large"
                    form={form}
                    style={{
                      padding: "20px",
                    }}
                    onFinish={onSaveVehicle}
                    autoComplete="off"
                  >
                    <Form.Item label="VIN" name="vin">
                      <Input placeholder="VIN"/>
                    </Form.Item>

                    <Form.Item
                      label="Lote"
                      name="lote"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese el lote",
                        },
                      ]}
                    >
                      <Input placeholder="Lote"/>
                    </Form.Item>

                    <Form.Item
                      label="Marca"
                      name="make"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese la marca",
                        },
                      ]}
                    >
                      {/* <Input placeholder="Marca" /> */}

                      <Select
                        mode="tags"
                        style={{
                          width: "100%",
                        }}
                        onChange={handleChange}
                        tokenSeparators={[","]}
                        options={options}
                        placeholder="Seleccionar marca o agregar una nueva"
                      />
                    </Form.Item>

                    <Form.Item
                      label="Modelo"
                      name="model"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingrese el modelo",
                        },
                      ]}
                    >
                      {/* <Input placeholder="Modelo" /> */}

                      <Select
                        mode="tags"
                        style={{
                          width: "100%",
                        }}
                        onChange={() => {
                        }}
                        tokenSeparators={[","]}
                        options={model}
                        placeholder="Seleccionar modelo o agregar una nueva"
                      />
                    </Form.Item>

                    <Form.Item
                      label="Año"
                      name="year"
                      rules={[
                        {required: true, message: "Por favor ingrese el año"},
                      ]}
                    >
                      <Input placeholder="Año" type="number"/>
                    </Form.Item>

                    <Form.Item label="Color" name="color">
                      <Input placeholder="Color"/>
                    </Form.Item>

                    <Form.Item label="Título" name="idTitle"
                               rules={[{required: true, message: "El tipo de titulo es requerido"}]}
                    >
                      <Select
                        showSearch={true}
                        placeholder={"Buscar título"}
                        filterOption={filterOption}
                        onChange={handleOnSelectTitle}
                        options={titles.map((e) => ({
                          value: e.idTitle,
                          label: `${e.state} - ${e.titleName} (${
                            e.embarkable ? "Embarcable" : "No Embarcable"
                          })`,
                        }))}
                      />
                    </Form.Item>

                    <Form.Item
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Button type="primary" htmlType="submit"
                              disabled={!canSaveVehicle}

                      >
                        Agregar vehiculo
                      </Button>
                      <Button
                        style={{
                          backgroundColor: "green",
                          color: "#fff",
                          marginLeft: "20px",
                        }}
                        onClick={async () => {
                          setShowForm(false);
                        }}
                      >
                        Lista pendiente de vehiculos
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              )}
            </Row>
          </TabPane>
          <TabPane tab="Registro de coordinaciones" key="2">
            <Spin spinning={isLoadingSave} tip="Se estan guardando las coordinaciones..." size='large'>
              {!showFormCoordinacion && (
                <Row gutter={24}>
                  {allCoordinations?.length === 0 ? (
                    <Col span={24}>
                      <Result
                        icon={<SmileTwoTone twoToneColor="#e8e8e8"/>}
                        title="No hay coordinaciones pendiente."
                        style={{
                          border: "1px solid #e8e8e8",
                          borderRadius: "6px",
                        }}
                      />
                    </Col>
                  ) : (
                    allCoordinations?.map((item, index) => (
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}
                        xl={6}
                        xxl={4}
                        style={{
                          marginBottom: "20px",
                        }}
                      >
                        <Card
                          style={{
                            border: "1px solid #e8e8e8",
                            borderRadius: "6px",
                          }}
                        >
                          <p>
                            Vehicle:{" "}
                            <strong>
                              {
                                listVehicle.find(
                                  (vehicle) =>
                                    vehicle.vehiculoId === item.vehiculoId
                                )?.vehiculoName
                              }
                            </strong>{" "}
                          </p>
                          <p>
                            Lote:{" "}
                            <strong>
                              {
                                listVehicle.find(
                                  (vehicle) =>
                                    vehicle.vehiculoId === item.vehiculoId
                                )?.lote
                              }
                            </strong>
                          </p>
                          <p>
                            Origen:{" "}
                            <strong>
                              {
                                listOrigin.find(
                                  (origin) => origin.value === item.origenId
                                )?.label
                              }
                            </strong>
                          </p>
                          <p>
                            Destino Aduana:{" "}
                            <strong>
                              {
                                listAduana.find(
                                  (aduana) => aduana.value === item.aduanaId
                                )?.label
                              }
                            </strong>
                          </p>
                          <p>Creador: {item.creador}</p>
                          <p>Buyer: {item.buyer}</p>
                          <p>Tipo Pago: {item.type_pay}</p>
                          <p>Fecha: {item.fechacor}</p>

                          <Button
                            type="primary"
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            danger
                            block
                            onClick={() => {
                              console.log("eliminar vehiculo", item.vin);
                              dispatch(deleteOneCoordination(index));
                            }}
                          >
                            <DeleteFilled/>
                          </Button>
                        </Card>
                      </Col>
                    ))
                  )}
                </Row>
              )}

              <Row gutter={24}>
                {!showFormCoordinacion && (
                  <Col
                    span={24}
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      href="#"
                      type="primary"
                      onClick={async () => {
                        setShowFormCoordinacion(true);
                        await fetchVehicle().finally(() => {
                          setIsLoading(false);
                        });
                      }}
                    >
                      Agregar nueva coordinacion
                    </Button>
                    {allCoordinations?.length > 0 && (
                      <Button
                        type="success"
                        style={{
                          marginLeft: "20px",
                          backgroundColor: "green",
                          color: "#fff",
                        }}
                        onClick={() => {
                          setIsLoadingSave(true);
                          dispatch(saveAllCoordination(allCoordinations));
                        }}
                      >
                        Guardar todas las coordinaciones agregadas
                      </Button>
                    )}

                    {allCoordinations?.length > 1 && (
                      <Button
                        type="primary"
                        style={{
                          marginLeft: "20px",
                        }}
                        onClick={() => {
                          dispatch(deleteAllCoordination());
                        }}
                        danger
                      >
                        Eliminar todos las coordinaciones agregadas
                      </Button>
                    )}
                  </Col>
                )}
              </Row>

              <Row gutter={24}>
                <Col span={24}>
                  {showFormCoordinacion && (
                    <Spin spinning={isLoading}>
                      <Form
                        layout="vertical"
                        size="large"
                        form={formSolicitud}
                        style={{
                          padding: "20px",
                        }}
                        onFinish={onSaveCoordinacion}
                        autoComplete="off"
                        initialValues={{
                          entryType: CoordinacionEntryTypeEnum.entryTypeByOwn,
                        }}
                      >
                        {/* DATOS DEL VEHICULO */}
                        <Row gutter={24}>
                          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                              name="vehiculoId"
                              label="Vehiculos"
                              rules={[
                                {
                                  required: true,
                                  message: "Por favor seleccione un vehiculo",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Seleccionar vehiculo"
                                optionFilterProp="children"
                                onChange={onChangeVehicle}
                                onSearch={onSearch}
                                filterOption={filterOption}
                                options={vehicle}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <hr/>
                        <Row gutter={24} style={{marginBottom: "20px"}}>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <strong>Fecha Ingreso: </strong> {dateNow}
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <strong>Vehiculo: </strong>{" "}
                            {vehicleSelected?.vehiculoName}
                          </Col>

                          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <strong>#Lote:</strong> {vehicleSelected?.lote}
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <strong>#Vin:</strong> {vehicleSelected?.vin}
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <strong>Tipo de
                              Título:</strong> {vehicleSelected?.title ? vehicleSelected?.title?.titleName : "N/D"}
                          </Col>
                        </Row>
                        <hr/>
                        <Row gutter={24}>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            {/* ADD checkbox ant design yes delear and not delear */}
                            <Form.Item label="Dealer">
                              <Checkbox
                                checked={checked}
                                onChange={onChangeCheckbox}
                              >
                                Matus Dealer?
                              </Checkbox>
                            </Form.Item>
                            <Form.Item label="">
                              <Checkbox
                                checked={checkedEntryType}
                                onChange={onChangeCheckboxEntryType}
                              >
                                Entrega en Bodega?
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                              label="Gate Pass"
                              name="gatePassPin"
                              rules={[
                                // max 10 characters
                                {
                                  max: 10,
                                  message: "Maximo 10 caracteres",
                                },
                              ]}
                            >
                              <Input placeholder="Gate pass"/>
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item label="Buyer" name="buyer">
                              <Input placeholder="Buyer" type="number"/>
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                              label="Tipo de Pago"
                              name="type_pay"
                              extra="Seleccione un Cliente"
                              rules={[
                                {
                                  required: true,
                                  message: "Por favor seleccione un tipo de pago",
                                },
                              ]}
                            >
                              {/* <Input placeholder="Tipo de pago" /> */}
                              <Select
                                disabled
                                showSearch
                                placeholder="Seleccionar Tipo de Pago"
                                optionFilterProp="children"
                                //   onChange={onChangeVehicle}
                                //   onSearch={onSearch}
                                //   filterOption={filterOption}
                                options={TypePay}
                              />
                            </Form.Item>
                          </Col>

                          <Col
                            xs={24}
                            sm={24}
                            md={checkedEntryType ? 24 : 12}
                            lg={checkedEntryType ? 24 : 12}
                            xl={checkedEntryType ? 24 : 12}
                            xxl={checkedEntryType ? 24 : 12}
                          >
                            <Form.Item
                              label="Cliente"
                              name="clienteId"
                              rules={[
                                {
                                  required:
                                    selectedClient === undefined ? true : false,
                                  message: "Por favor seleccione un cliente",
                                },
                              ]}
                            >
                              <Row
                                gutter={24}
                                style={{
                                  display: "flex",
                                }}
                              >
                                {/* <p></p> */}
                                <Col
                                  xs={24}
                                  sm={24}
                                  md={14}
                                  lg={18}
                                  xl={18}
                                  xxl={18}
                                >
                                  <InputSearchCustomer
                                    selectedCustomer={selectedClient}
                                    filterByFinalCustomer={false}
                                    onSelectedCustomer={handleSelectChangeCliente}
                                  />
                                </Col>

                                <Col
                                  xs={24}
                                  sm={24}
                                  md={10}
                                  lg={6}
                                  xl={6}
                                  xxl={6}
                                  style={{}}
                                >
                                  <Button
                                    type="primary"
                                    icon={<EditOutlined/>}
                                    size={"sm"}
                                    onClick={() => setShowModalClient(true)}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    block
                                  >
                                    Agregar Cliente
                                  </Button>
                                </Col>
                              </Row>
                              {/* <Select
                      showSearch
                      placeholder="Select a person"
                      optionFilterProp="children"
                      onChange={onChangeCliente}
                      onSearch={onSearch}
                      filterOption={filterOption}
                      options={listClient}
                    /> */}
                            </Form.Item>
                          </Col>

                          {!checkedEntryType && (
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                              <Row
                                gutter={24}
                                style={{
                                  display: "flex",
                                  // justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Col
                                  xs={24}
                                  sm={24}
                                  md={14}
                                  lg={18}
                                  xl={18}
                                  xxl={18}
                                >
                                  <Form.Item label="Subasta" name="subastaId">
                                    <Select
                                      showSearch
                                      placeholder="Seleccionar subasta"
                                      optionFilterProp="children"
                                      onChange={onChange}
                                      onSearch={onSearch}
                                      filterOption={filterOption}
                                      style={{width: "100%"}} // Set width to 100% here
                                      options={listSubasta}
                                    />
                                  </Form.Item>
                                </Col>

                                <Col
                                  xs={24}
                                  sm={24}
                                  md={10}
                                  lg={6}
                                  xl={6}
                                  xxl={6}
                                >
                                  <Button
                                    type="primary"
                                    onClick={handleShowA}
                                    size={"sm"}
                                    style={{
                                      marginTop: "20px",
                                    }}
                                    block
                                  >
                                    Agregar Nueva Subasta
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          )}
                          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                              label="Forma de Entrega en Bodega"
                              name="entryType"
                            >
                              <Select>
                                <Select.Option
                                  value={CoordinacionEntryTypeEnum.entryTypeByOwn}
                                  disabled={checkedEntryType}
                                >
                                  {CoordinacionEntryTypeEnum.entryTypeByOwn}
                                </Select.Option>
                                <Select.Option
                                  value={
                                    CoordinacionEntryTypeEnum.entryTypeByClient
                                  }
                                  disabled={!checkedEntryType}
                                >
                                  {CoordinacionEntryTypeEnum.entryTypeByClient}
                                </Select.Option>
                                <Select.Option
                                  value={
                                    CoordinacionEntryTypeEnum.entryTypePendingByClient
                                  }
                                  disabled={!checkedEntryType}
                                >
                                  {
                                    CoordinacionEntryTypeEnum.entryTypePendingByClient
                                  }
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                            <Form.Item
                              label="Origen"
                              name="origenId"
                              rules={[
                                {
                                  required: true,
                                  message: "Por favor seleccione un origen",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Seleciona un origen"
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                                filterOption={filterOption}
                                options={listOrigin}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                            <Form.Item
                              label="Aduana destino"
                              name="aduanaId"
                              rules={[
                                {
                                  required: true,
                                  message: "Por favor seleccione una aduana",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Seleccione una aduana"
                                optionFilterProp="children"
                                onChange={onChangeAduana}
                                onSearch={onSearch}
                                filterOption={filterOption}
                                options={listAduana}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                            <Form.Item
                              label="Estado"
                              name="state"
                              rules={[
                                {
                                  required: true,
                                  message: "Por favor seleccione un estado",
                                },
                              ]}
                            >
                              <Select placeholder="Seleccione un estado">
                                <Select.Option value="Vehiculo no pagado en subasta">
                                  Vehiculo no pagado en subasta
                                </Select.Option>
                                <Select.Option value="Vehiculo pagado en subasta">
                                  Vehiculo pagado en subasta
                                </Select.Option>
                                <Select.Option value="Pendiente de prepago">
                                  Pendiente de prepago
                                </Select.Option>
                                <Select.Option value="Chofer asignado">
                                  Chofer asignado
                                </Select.Option>
                                <Select.Option value="Vehículo de dirección particular">
                                  Vehículo de dirección particular
                                </Select.Option>
                                <Select.Option value="Vehículo para corte">
                                  Vehículo para corte
                                </Select.Option>
                                <Select.Option value="No pick Up">
                                  No pick Up
                                </Select.Option>
                                <Select.Option value="No delivery">
                                  No delivery
                                </Select.Option>
                                <Select.Option value="Vehículo Posteado">
                                  Vehículo Posteado
                                </Select.Option>
                                <Select.Option value="Vehículo en bodega sin WareHouse">
                                  Vehículo en bodega sin WareHouse
                                </Select.Option>
                                <Select.Option value="Vehículo en bodega con WareHouse">
                                  Vehículo en bodega con WareHouse
                                </Select.Option>
                                <Select.Option value="Vehículo en bodega con título pendiente">
                                  Vehículo en bodega con título pendiente
                                </Select.Option>
                                <Select.Option value="Vehículo de la cuenta dealer">
                                  Vehículo de la cuenta dealer
                                </Select.Option>
                                <Select.Option value="Vehículo para entrega en bodega">
                                  Vehículo para entrega en bodega
                                </Select.Option>

                                <Select.Option value="COMPLETADO">
                                  COMPLETADO
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                            <Form.Item label="Fecha de Pago" name="paid">
                              <Input placeholder="Fecha de Pago" type="date"/>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                            <Form.Item
                              label="Primer dia Disponible"
                              name="firstBusDay"
                            >
                              <Input
                                placeholder="Primer dia Disponible"
                                type="date"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                            <Form.Item
                              label="Ultimo dia Disponible"
                              name="lastFreeDay"
                            >
                              <Input
                                placeholder="Ultimo dia Disponible"
                                type="date"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                            <Form.Item label="Flete" name="flete">
                              <Input placeholder="Flete" type="number"/>
                            </Form.Item>
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                            <Form.Item label="Costo de Grua" name="rate_Quoted">
                              <Input placeholder="Costo de grua"/>
                            </Form.Item>
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                            <Form.Item label="Costo de BL" name="bl">
                              <Input placeholder="Costo de bl"/>
                            </Form.Item>
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                            <Form.Item
                              label="Costo Adicional"
                              name="costAdditional"
                            >
                              <Input placeholder="Costo de adicional"/>
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item label="Notas" name="nota">
                              <TextArea rows={4}/>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Col span={24}>
                          <Form.Item
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <Button type="primary" htmlType="submit">
                              Agregar Coordinacion
                            </Button>
                            <Button
                              style={{
                                backgroundColor: "green",
                                color: "#fff",
                                marginLeft: "20px",
                              }}
                              onClick={() => {
                                setShowFormCoordinacion(false);
                              }}
                            >
                              Lista pendiente de coordinaciones
                            </Button>
                          </Form.Item>
                        </Col>
                      </Form>
                    </Spin>
                  )}
                </Col>
              </Row>

            </Spin>
          </TabPane>
        </Tabs>
        <>
          <Modal
            show={showA}
            onHide={handleCloseA}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add new auction</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormB.Label>Nombre de la Subasta</FormB.Label>
              <FormB.Control
                onChange={handleChange}
                name="subName"
                type="text"
                placeholder="Nombre Subasta"
              />
              <br/>
              <FormB.Label>Address line 1</FormB.Label>
              <FormB.Control
                onChange={handleChange}
                name="addressLine1Sub"
                type="text"
                placeholder="Address line 1"
              />
              <br/>
              <FormB.Label>Address line 2</FormB.Label>
              <FormB.Control
                onChange={handleChange}
                name="addressLine2Sub"
                type="text"
                placeholder="Address line 2"
              />
              <br/>
              <FormB.Label>City</FormB.Label>
              <FormB.Control
                onChange={handleChange}
                name="city"
                type="text"
                placeholder="City"
              />
              <br/>
              <FormB.Label>Zip Code</FormB.Label>
              <FormB.Control
                onChange={handleChange}
                name="zipCode"
                type="number"
                placeholder="Zip Code"
              />
              <br/>
              <FormB.Label>Phone Number</FormB.Label>
              <FormB.Control
                onChange={handleChange}
                name="phoneNumber"
                type="number"
                placeholder="Phone Number"
              />
              <br/>
              <FormB.Label>State</FormB.Label>
              <FormB.Select
                onChange={handleChange}
                name="state"
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
              >
                <option disabled selected>
                  Seleccione
                </option>
                {states &&
                  states.length &&
                  states.map((ste, index) => {
                    return (
                      <option key={index} value={ste.stateId}>
                        {ste.state}
                      </option>
                    );
                  })}
              </FormB.Select>
              <br/>
              {/*<FormB.Label>Country</FormB.Label>
              <FormB.Select
                onChange={handleChange}
                name="countryId"
                value={addSubastas?.countryId}
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
              >
                <option disabled selected>
                  Seleccione
                </option>
                {countries &&
                  countries?.length &&
                  countries.map((countries, index) => {
                    return (
                      <option key={index} value={countries?.id}>
                        {countries?.nameCountry}
                      </option>
                    );
                  })}
              </FormB.Select>
              <br /> */}
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleCloseA}>Cancelar</Button>
              <Button onClick={() => insertarSubasta()} type="primary">
                {" "}
                <Space>
                  <FontAwesomeIcon icon={faFloppyDisk}/> Guardar
                </Space>
              </Button>
            </Modal.Footer>
          </Modal>
        </>
        <ModalAntD
          open={showModalClient || showModalFinalClient}
          closable={false}
          cancelButtonProps={{style: {display: "none"}}}
          okButtonProps={{style: {display: "none"}}}
        >
          <ClienteForm
            shortSaved={true}
            countryId={0}
            importadora={selectedImportadora}
            finalClient={showModalFinalClient}
            submitCliente={handleSaveCustomer}
            onCancel={handleCancelCustomer}
          />
        </ModalAntD>
      </div>
    </>
  );
};

export default MultipleCoordinaciones;
