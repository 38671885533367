import React, {useEffect, useState} from "react";
import {Button, Input, Space} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import ShowWhen from "../ShowWhen/ShowWhen";

const InputSearch = ({ initialValue, placeholder, doChange, showButton }) => {
  const [filterText, setFilterText] = useState(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (filterText != null && !showButton)
        doChange(filterText);
    }, 500);

    return () => clearTimeout(timeoutId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, 500]);

  useEffect(() => {
    if (initialValue === '') setFilterText(null);
  }, [initialValue]);

  const handleChange = (event) => {
    const value = event.target.value;

    setFilterText(value);
  }

  return (
    <div className="input-buscar-container">
      <Space.Compact style={{ width: '100%' }}>
        <Input
          className="input-buscar"
          id="search"
          type="text"
          autoComplete="off"
          placeholder={placeholder}
          value={filterText ?? initialValue}
          onChange={handleChange}
        />
        {
          showButton &&
          <Button type="primary" onClick={() => doChange(filterText)}>
            <FontAwesomeIcon icon={faSearch} className="me-2" /> Buscar
          </Button>
        }
      </Space.Compact>
      <ShowWhen show={filterText !== null && filterText !== ''}>
        <div className={`input-buscar-icon${showButton ? ' search-button' : ''}`} onClick={() => handleChange({target: {value: ""}})}>
          <FontAwesomeIcon icon={faTimes} className="cursor-pointer"/>
        </div>
      </ShowWhen>
    </div>
  );
}

export default InputSearch;
