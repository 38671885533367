import {Alert, Button, Card, Col, Row, Switch, Carousel, Image, Select} from "antd";
import React from "react";
import moment from "moment/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckDouble,
  faCopy,
  faEye,
  faPenToSquare,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import { VehiculoEstadoEnum } from "../shared/enums/vehiculo-estado.enum";
import { VehiculoEstadoInspeccionEnum } from "../shared/enums/vehiculo-estado-inspeccion.enum";

const contentStyle = {
  margin: 0,
  height: "200px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

const VehicleCardInfo = ({
  vehicle,
  onInspect,
  onReceive,
  onUpdate,
  onUploadEvidence,
  onViewEvidence,
  onCopyLink,
  estadoEmbarcado,
  vehiculoSecciones,
  onSelectVehiculoSeccion
}) => {
  const lote = () => <div className="fw-bold">{vehicle?.lote}</div>;

  const actions = () => {
    const actions = [];

    if (onUpdate) {
      actions.push(
        <Button
          onClick={onUpdate}
          type="success"
          title={"Edit vehicle"}
          style={{ backgroundColor: "#52c41a" }}
        >
          <FontAwesomeIcon icon={faPenToSquare} />
        </Button>
      );
    }

    if (onUploadEvidence) {
      actions.push(
        <Button
          type="primary"
          title={"Upload evidence"}
          onClick={() => onUploadEvidence(vehicle)}
          style={{ backgroundColor: "#52c41a" }}
        >
          <FontAwesomeIcon icon={faUpload} />
        </Button>
      );
    }

    if (onViewEvidence) {
      actions.push(
        <Button type="primary" title={"View evidence"} onClick={onViewEvidence}>
          <FontAwesomeIcon icon={faEye} />
        </Button>
      );
    }

    if (onCopyLink) {
      actions.push(
        <Button
          type="primary"
          title={"Copy link of evidence"}
          style={{ backgroundColor: "#F9B325" }}
          onClick={onCopyLink}
        >
          <FontAwesomeIcon icon={faCopy} />
        </Button>
      );
    }

    return actions;
  };

  return (
    <>
      <Card
        title={`${vehicle?.vehiculoName}`}
        extra={lote()}
        actions={actions()}
        cover={
          vehicle?.imagenFrontal ||
          vehicle?.imagenTitulo ||
          vehicle?.imagenVin ? (
            <Carousel autoplay>
              {vehicle?.imagenTitulo && (
                <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px", // Sin !important
                  maxWidth: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px", // Sin !important
                  }}
                >
                  <Image
                    src={vehicle?.imagenTitulo}
                    alt="Vehicle"
                    style={{
                      maxHeight: "200px",
                      maxWidth: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </Row>
              )}
              {vehicle?.imagenFrontal && (
                <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px", // Sin !important
                  maxWidth: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px", // Sin !important
                  }}
                >
                  <Image
                    src={vehicle?.imagenFrontal}
                    alt="Vehicle"
                    style={{
                      maxHeight: "200px",
                      maxWidth: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </Row>
              )}
              {vehicle?.imagenVin && (
                <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px", // Sin !important
                  maxWidth: "100%",
                }}
              >
                <div 
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px", // Sin !important
                  }}
                >
                  <Image
                    src={vehicle?.imagenVin}
                    alt="Vehicle"
                    style={{
                      maxHeight: "200px",
                      maxWidth: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </Row>
              )}
            </Carousel>
          ) : (
            <h3 style={contentStyle}>No image</h3>
          )
        }
      >
        <Row>
          <Col lg={24} md={24} sm={24} xs={24} className="mb-3">
            <p className="mb-2">Ubicación</p>
            <Select className="w-100"
                    value={vehicle?.idVehiculoSeccion}
                    onChange={value => onSelectVehiculoSeccion(value)}
                    options={vehiculoSecciones.map(e => ({ value: e.idVehiculoSeccion, label: e.name }))} />
          </Col>
          <ShowWhen show={vehicle?.cutVehicle}>
            <Col lg={24} md={24} sm={24} xs={24} className="mb-3">
              <Alert message={'VEHÍCULO PARA CORTE'} showIcon
                     type="warning"
                     style={{
                       textAlign: "center",
                       fontWeight: "bold",
                       fontSize: "1em",
                     }}
              />
            </Col>
          </ShowWhen>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Alert
              message={`${
                vehicle.destinoCode ? vehicle.destinoCode : "SIN SERIE"
              }`}
              type="info"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1.5em",
              }}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <p className="mb-0">
              <span className="fw-bold">VIN:</span> {vehicle?.vin}
            </p>
            <p className="mb-0">
              <span className="fw-bold">Fecha de Recibido:</span>{" "}
              {vehicle?.fecha
                ? moment(vehicle?.fecha).format("lll")
                : "Sin Recibir"}
            </p>
            <ShowWhen
              show={vehicle.estado === VehiculoEstadoEnum.recibidoEnBodega}
            >
              <p className="mb-0">
                <span className="fw-bold">Recibido Por:</span>{" "}
                {vehicle?.updateBy}
              </p>
            </ShowWhen>
            <ShowWhen
              show={
                vehicle.estadoInspeccion ===
                VehiculoEstadoInspeccionEnum.inspeccionado
              }
            >
              <p className="mb-0">
                <span className="fw-bold">Inspeccionado Por:</span>{" "}
                {vehicle?.inspeccionadoPor}
              </p>
            </ShowWhen>

            <ShowWhen
              show={
                vehicle.estadoInspeccion ===
                VehiculoEstadoInspeccionEnum.inspeccionado
              }
            >
              <p className="mb-0">
                <span className="fw-bold">Numero de Llave:</span>{" "}
                {vehicle?.ubicacion}
              </p>
            </ShowWhen>
            <ShowWhen show={vehicle?.estado === VehiculoEstadoEnum.recibidoEnBodega}>
              <hr/>
              <div className="d-flex align-items-center justify-content-start gap-2 mb-2">
                <p className="mb-0">Vehículo en Bodega:</p>
                <Switch checked={vehicle?.enInventario}
                        onChange={() => estadoEmbarcado(vehicle)}/>
              </div>
              <p className="mb-0 font-12">Quitar marca si el vehículo no esta en Bodega</p>
            </ShowWhen>
          </Col>
        </Row>
        {/* <hr /> */}
        {/* <Row gutter={16}>
          <Col>
            <label>
              <strong>Storage: </strong>
              {vehicle?.storage ? vehicle?.storage : "-"}
            </label>
          </Col>
          <Col span={12}>
            <label>
              <strong>No llave: </strong>
              {vehicle?.ubicacion}
            </label>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col
            span={12}
            className="d-flex align-items-center justify-content-between gap-2"
          >
            <label className="fw-bold">Título:</label>
            <Switch checked={vehicle?.titulo} disabled />
          </Col>
          <Col
            span={12}
            className="d-flex align-items-center justify-content-between gap-2"
          >
            <label className="fw-bold">Llave:</label>
            <Switch checked={vehicle?.llave} disabled />
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col
            span={12}
            className="d-flex align-items-center justify-content-between gap-2"
          >
            <label className="fw-bold">Factura:</label>
            <Switch checked={vehicle?.factura} disabled />
          </Col>
          <Col
            span={12}
            className="d-flex align-items-center justify-content-between gap-2"
          >
            <label className="fw-bold">Bio:</label>
            <Switch checked={vehicle?.bio} disabled />
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col
            span={12}
            className="d-flex align-items-center justify-content-between gap-2"
          >
            <label className="fw-bold">Catalizador:</label>
            <Switch checked={vehicle?.catalitico} disabled />
          </Col>
        </Row> */}
        <br />
        {vehicle?.estadoInspeccion === "Inspeccionado" ? (
          <Alert
            message="Inspeccionado"
            type="success"
            showIcon
            style={{
              textAlign: "center",
            }}
          />
        ) : (
          <Alert
            message="No Inspeccionado"
            type="error"
            showIcon
            style={{
              textAlign: "center",
            }}
          />
        )}
        <ShowWhen show={onReceive}>
          <Row gutter={16}>
            <Col span={24}>
              <hr />
              <Button block={true} type={"primary"} onClick={onReceive}>
                <FontAwesomeIcon icon={faCheckDouble} className="me-1" /> Marcar
                como Recibido
              </Button>
            </Col>
          </Row>
        </ShowWhen>

        <ShowWhen show={onInspect}>
          <Row gutter={16}>
            <Col span={24}>
              <hr />
              <Button block={true} type={"primary"} onClick={onInspect}>
                <FontAwesomeIcon icon={faCheckDouble} className="me-1" />{" "}
                Inspeccionar Vehículo
              </Button>
            </Col>
          </Row>
        </ShowWhen>
      </Card>
    </>
  );
};

export default VehicleCardInfo;
