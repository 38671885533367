import {Button, Col, Form, Input, InputNumber, Row, Table} from "antd";
import React, {useEffect, useState} from "react";
import {
  exportationAddBillLadingItem,
  exportationRemoveBillLadingItem,
  getExportationById
} from "../../Servicios/ExportationServices";
import Spinner from "../../shared/spinner";
import {errorMessage, successMessage} from "../../utils/toast-message";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

const ExportationInformationBlForm = ({ exportation, exportationContainer, onUpdate }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [billLadingItems, setBillLadingItems] = useState([]);

  useEffect(() => {
    form.resetFields();
    setBillLadingItems(exportation?.billLadingItems ?? []);
  }, [exportation]);

  const handleFinish = async (values) => {
    const input = {
      ...values,
      quantity: +values?.quantity,
      weight: +values?.weight,
      idExportationContainer: exportationContainer?.idExportationContainer
    }

    try {
      setLoading(true);
      await exportationAddBillLadingItem(exportation.exportationId, input);
      setLoading(false);

      successMessage('Item agregado correctamente');
      form.resetFields();

      setLoading(true);
      const newExportation = await getExportationById(exportation.exportationId);
      setBillLadingItems(newExportation?.billLadingItems ?? []);
      setLoading(false);
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  }

  const handleDelete = async (idBillLadingItem) => {
    try {
      setLoading(true);
      await exportationRemoveBillLadingItem(idBillLadingItem);
      setLoading(false);

      successMessage('Item eliminado correctamente');
      form.resetFields();

      setLoading(true);
      const newExportation = await getExportationById(exportation.exportationId);
      setBillLadingItems(newExportation?.billLadingItems ?? []);
      setLoading(false);
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  }

  const columns = [
    {
      key: 'option',
      dataIndex: 'option',
      render: (_, element) => <Button shape="circle"
                                      danger={true}
                                      onClick={() => handleDelete(element.idBillLadingItem)}>
        <FontAwesomeIcon icon={faTrash}/>
      </Button>
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'Description',
    },
    {
      key: 'quantity',
      dataIndex: 'quantity',
      title: 'Cantidad',
    },
    {
      key: 'weight',
      dataIndex: 'weight',
      title: 'Peso (Kg)',
    }
  ];

  return(
    <Spinner loading={loading}>
      <Form form={form} layout={'vertical'} onFinish={handleFinish}>
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            <Form.Item label="Descripción" name="description">
              <Input placeholder="Descripción" />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item label="Cantidad" name="quantity">
              <InputNumber className="w-100" placeholder="Cantidad" />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item label="Peso (Kg)" name="weight">
              <InputNumber className="w-100" placeholder="Peso (Kg)" />
            </Form.Item>
          </Col>

          <Col className="gutter-row text-end" span={24}>
            <Button type={'dashed'} htmlType={'button'} className="me-2" onClick={onUpdate}>Cerrar</Button>
            <Button type={'primary'} htmlType={'submit'}>Guardar</Button>
          </Col>
        </Row>
      </Form>

      <Table className="mt-3"
             dataSource={billLadingItems}
             columns={columns}/>
    </Spinner>
  );
}

export default ExportationInformationBlForm;
