import ExportationTable from "../ExportationTable";
import {useEffect, useState} from "react";
import {getExportationList} from "../../../Servicios/ExportationServices";
import Spinner from "../../../shared/spinner";
import InputSearch from "../../../Componentes/InputSearch/InputSearch";
import {ExportationCbpStatusEnum} from "../../../shared/enums/exportation-cbp-status.enum";
import useCurrentUser from "../../../Hooks/UserHook";
import localStorageFilter from "../../../shared/utils/local-storage-filter";
import {errorMessage} from "../../../utils/toast-message";

interface Filters {
  pageCount: number;
  currentPage: number;
  search: string;
}

const ExportationAduanaPage = () => {
  const currentUser = useCurrentUser();

  const [loading, setLoading] = useState(false);
  const [exportations, setExportations] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const [filters, setFilters] = useState({
    pageCount: localStorageFilter('exportationEnProceso:filter', 'pageCount') ?? 15,
    currentPage: localStorageFilter('exportationEnProceso:filter', 'currentPage') ?? 1,
    search: localStorageFilter('exportationEnProceso:filter', 'search') ?? "",
  });

  useEffect(() => {
    if (currentUser) {
      initExportations(filters).then();
    }

    // save filters
    const localFilters = localStorageFilter();
    if (JSON.stringify(localFilters) !== JSON.stringify(filters)) {
      localStorage.setItem('exportationEnProceso:filter', JSON.stringify(filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, filters]);

  const initExportations = async (filters: Filters) => {
    try {
      const origen = +currentUser.idubc;

      setLoading(true);
      const data = await getExportationList(
        filters.currentPage,
        filters.pageCount,
        origen,
        '',
        ExportationCbpStatusEnum.EnProceso,
        filters.search
      );
      setLoading(false);
      setTotalElements(data?.totalElements);

      if (data && data.status) {
        setExportations(data.list.map((e, i) => ({...e, key: i})));
      }
    } catch (ex) {
      setLoading(false);
      errorMessage(`${ex.toString()}`);
    }
  }

  const handlePageChange = (page, pageSize) => {
    setFilters({
      ...filters,
      pageCount: pageSize,
      currentPage: page,
    });
  }

  const handleSearch = (value) => {
    setFilters({
      ...filters,
      search: value,
      currentPage: 1,
    });
  }

  return(
    <>
      <Spinner loading={loading}>
        <div className="mb-3">
          <InputSearch initialValue={filters.search}
                       showButton={true}
                       placeholder={'Search by shipping name, booking number, vin or lot'}
                       doChange={handleSearch} />
        </div>
        <ExportationTable pageCount={filters.pageCount}
                          currentPage={filters.currentPage}
                          totalElements={totalElements}
                          exportations={exportations}
                          onPageChange={handlePageChange}
                          onRefreshTable={() => initExportations(filters).then()}
                          onEditExportation={() => initExportations(filters).then()}/>
      </Spinner>
    </>
  );
}

export default ExportationAduanaPage;
