import React, {useState, useEffect} from "react";
import {Col, Container, Row, Form} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import '../hojas-de-estilo/style.css';
import '../hojas-de-estilo/styleWR.css';
import {useParams, Link} from "react-router-dom";
import {viewWR} from "../Servicios/WerehouseService";
import {faEnvelopeCircleCheck, faPrint, faLeftLong, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import {getPDFWRId} from "../Servicios/WerehouseService";
import getPdf from "../Servicios/Base64ToPdf";
import {enviarEmail} from "../Servicios/SolicitudesService";
import {toast} from 'react-toastify';
import Spinner from "../shared/spinner";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import {WarehouseReceiptEmail} from "../Resources/HtmlEmailBody/WarehouseReceipt.email";
import PdfViewer from "../Componentes/PdfViewer/PdfViewer";

const VisualizarWR = () => {
  let {id} = useParams();

  const [infowere, setInfowere] = useState([]);
  const [showT, setShowT] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pdf, setPdf] = useState(null);

  const handleCloseT = () => setShowT(false);

  const handleShowT = () => {
    setShowT(true);
    if (id !== undefined && id !== 0)
      getPDFWRId(id).then((pdf) => {
        const temp = [...attach]
        temp.push({nombreArchivo: 'Warehouse Receipt.pdf', archivo: "data:application/pdf;base64," + pdf})
        setAttach([...temp])
      });
    setSendCo({
      ...sendCo,
      subject: `Vehiculo recibido en bodega Matus International - ${infowere?.origenName} - Lot#${infowere?.lote}`,
      body: WarehouseReceiptEmail(infowere)
    })
    setToC(currentToc => [...currentToc, infowere?.emailCli]);
  };

  const notify = () => toast.success("Correo EnProceso", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const [to, setTo] = useState("");
  const [toC, setToC] = useState([]);

  const [cc, setCc] = useState("");
  const [ccC, setCcC] = useState([]);

  const [cco, setCco] = useState("");
  const [ccoC, setCcoC] = useState([]);

  const [attach, setAttach] = useState([]);

  const [sendCo, setSendCo] = useState({
    to: toC,
    cc: ccC,
    cco: ccoC,
    subject: "",
    body: "",
    attachedFile: attach,
  });

  const agregarTo = (event) => {
    event.preventDefault();
    setToC(currentToc => [...currentToc, to]);
    setTo('');
  }

  const handleDeleteTO = (deletingItem) => {
    const lisTo = toC.filter((item) => item !== deletingItem);
    setToC(lisTo);
  }

  const agregarCA = () => {
    setCcC(currentCA => [...currentCA, "officecalifornia@matusintl.com"]);
  }
  const agregarFL = () => {
    setCcC(currentFL => [...currentFL, "warehousefl@matusintl.com"]);
  }
  const agregarCR = () => {
    setCcC(currentCR => [...currentCR, 'karla@matusintl.com', 'operacioncr@matusintl.com']);
  }
  const agregarHN = () => {
    setCcC(currentHN => [...currentHN, 'karla@matusintl.com', 'honduras@matusintl.com', 'ktorres@matusintl.com']);
  }
  const agregarGT = () => {
    setCcC(currentGT => [...currentGT, 'karla@matusintl.com', 'guatemala@matusintl.com', 'logisticagt@matusintl.com']);
  }
  const agregarSV = () => {
    setCcC(currentSV => [...currentSV, 'karla@matusintl.com', 'operacion@matusintl.com']);
  }

  const agregarCC = (event) => {
    event.preventDefault();
    setCcC(currentCC => [...currentCC, cc]);
    setCc('');
  }

  const handleDeleteCC = (deletingItem) => {
    const lisCC = ccC.filter((item) => item !== deletingItem);
    setCcC(lisCC);
  }

  const agregarCCO = (event) => {
    event.preventDefault();
    setCcoC(currentCCO => [...currentCCO, cco]);
    setCco('');
  }

  const handleDeleteCCO = (deletingItem) => {
    const lisCCO = ccoC.filter((item) => item !== deletingItem);
    setCcoC(lisCCO);
  }

  useEffect(() => {
    setSendCo({...sendCo, to: toC})
  }, [toC])

  useEffect(() => {
    setSendCo({...sendCo, cc: ccC})
  }, [ccC])

  useEffect(() => {
    setSendCo({...sendCo, cco: ccoC})
  }, [ccoC])

  useEffect(() => {
    setSendCo({...sendCo, attachedFile: attach})
  }, [attach])

  useEffect(() => {
    if (id) {
      setLoading(true);
      viewWR(id).then((were) => {
        setInfowere(were);
      });
      getPDFWRId(id).then((pdf) => {
        setLoading(false);
        setPdf(pdf);
      });
    }
  }, [id]);

  const handleChange = e => {
    const {name, value} = e.target;
    setSendCo({
      ...sendCo,
      [name]: value
    });
  }

  const enviarCorreo = async () => {
    enviarEmail(sendCo).then(() => {
      notify();
      handleCloseT();
      setAttach([]);
    })
  }

  const mostrarPDF = () => {
    getPdf('Warehouse Receipt', pdf);
  }

  return (
    <Spinner loading={loading}>
      <Container>
        <h4>Vehicle Receipt Format</h4>
        <hr/>
        <Row>
          <Button style={{width: '120px'}} as={Link} to={`/warehouseReceipt/${infowere?.idOrigen}`} variant="warning">
            <FontAwesomeIcon icon={faLeftLong}/>
            &nbsp;Back
          </Button>
        </Row>
        <Row style={{justifyContent: 'space-evenly'}}>
          <Col xs={12} lg="2">
            <Button variant="success" onClick={handleShowT}>
              <FontAwesomeIcon icon={faEnvelopeCircleCheck}/>
              &nbsp;Send Email
            </Button>
          </Col>
          <Col xs={12} lg="2">
            <Button onClick={mostrarPDF} variant="primary">
              <FontAwesomeIcon icon={faPrint}/>
              &nbsp;Print
            </Button>
          </Col>
        </Row>
        <hr/>
        <ShowWhen show={!!pdf}>
          <PdfViewer base64={pdf}/>
        </ShowWhen>
        <>
          <Modal
            show={showT}
            onHide={handleCloseT}
            backdrop="static"
            keyboard={false}
            className="emailsoli"
          >
            <Modal.Header closeButton>
              <Modal.Title>Enviando WR</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Row>
                <Col xs={12} lg="2">
                  <Button onClick={agregarCA} variant="secondary">+CC, CA</Button>
                </Col>
                <Col xs={12} lg="2">
                  <Button onClick={agregarFL} variant="secondary">+CC, FL</Button>
                </Col>
                <Col xs={12} lg="2">
                  <Button onClick={agregarSV} variant="secondary">+CC, SV</Button>
                </Col>
                <Col xs={12} lg="2">
                  <Button onClick={agregarGT} variant="secondary">+CC, GT</Button>
                </Col>
                <Col xs={12} lg="2">
                  <Button onClick={agregarCR} variant="secondary">+CC, CR</Button>
                </Col>
                <Col xs={12} lg="2">
                  <Button onClick={agregarHN} variant="secondary">+CC, HN</Button>
                </Col>
              </Row>

              <Form.Label><strong>Para:</strong></Form.Label>
              {toC?.map((item, index) => {
                return <li key={index}>{item}
                  <button onClick={() => handleDeleteTO(item)}>x</button>
                </li>
              })}
              <Row>
                <Col xs={12} lg="8">
                  <Form.Control value={to} onChange={(e) => setTo(e.target.value)} aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"/>
                </Col>
                <Col xs={12} lg="2">
                  <Button onClick={agregarTo} variant="primary">Agregar</Button>
                </Col>
              </Row>
              <Row>
                <Form.Label><strong>CC:</strong></Form.Label>
                {ccC?.map((item, index) => {
                  return <li key={index}>{item}
                    <button onClick={() => handleDeleteCC(item)}>x</button>
                  </li>
                })}
                <Col xs={12} lg="8">
                  <Form.Control value={cc} onChange={(e) => setCc(e.target.value)} aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"/>
                </Col>
                <Col xs={12} lg="2">
                  <Button onClick={agregarCC} variant="primary">Agregar</Button>
                </Col>
              </Row>
              <Row>
                <Form.Label><strong>CCO:</strong></Form.Label>
                {ccoC?.map((item, index) => {
                  return <li key={index}>{item}
                    <button onClick={() => handleDeleteCCO(item)}>x</button>
                  </li>
                })}
                <Col xs={12} lg="8">
                  <Form.Control value={cco} onChange={(e) => setCco(e.target.value)} aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"/>
                </Col>
                <Col xs={12} lg="2">
                  <Button onClick={agregarCCO} variant="primary">Agregar</Button>
                </Col>
              </Row>
              <Form.Label><strong>Asunto:</strong></Form.Label>
              <Form.Control name="subject" onChange={handleChange} value={sendCo?.subject} as="textarea" rows={2}/>

              <Form.Label><strong>Archivos Adjuntos</strong></Form.Label> <br/>
              {attach?.map((attach, key) => {
                return <li key={key}>{attach.id}{attach.nombreArchivo}</li>
              })}

              <Form.Label><strong>Mensaje</strong></Form.Label>
              <Form.Control disabled readOnly name="body"
                            value="**El mensaje esta predeterminado por el sistema con los datos ingresados en el WR**
        - Si agrega nuevos correos asegurarse esten bien escritos y aparezcan en la lista de agregados" as="textarea"
                            rows={3}/>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseT}>
                Cancelar
              </Button>
              <Button onClick={enviarCorreo} variant="primary"><FontAwesomeIcon icon={faPaperPlane}/> Enviar</Button>
            </Modal.Footer>
          </Modal>
        </>
      </Container>
    </Spinner>
  )
}

export default VisualizarWR;
