import React, { useState, useEffect } from "react";
import { Card, Row, Col, Grid } from "antd";
import Typography from "@mui/material/Typography";
import WalletCountCard from "../../../Componentes/widgets/wallletCountCard";
import { useTheme } from "@mui/material/styles";
import todoterreno from "../../../image/todoterreno.png";
import { totalVehiculosEnBodegaPerOrigen } from "../../../Servicios/Home";

const { useBreakpoint } = Grid;

const TotalVehicleReceived = () => {
  const theme = useTheme();

  const [TotalVehicle, setTotalVehicle] = useState(null);
  const [loading, setLoading] = useState(false);
  const screens = useBreakpoint();

  const transformToArray = (obj) => {
    return Object.entries(obj).map(([key, value]) => ({
      name: key,
      value,
    }));
  };
  useEffect(() => {
    totalVehiculosEnBodegaPerOrigen().then((data) => {
      setTotalVehicle({
        ayer: transformToArray(data.ayer),
        hoy: transformToArray(data.hoy),
      });
    });
  }, []);

  return (
    <Card
      title="Total de Vehiculos Recibidos"
      style={{
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        height: "100%",
      }}
      loading={loading}
    >
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row gutter={24}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <Typography variant="h5">Ayer</Typography>
            </Col>
            {TotalVehicle?.ayer?.map((item, index) => (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                offset={
                  TotalVehicle?.ayer?.length % 2 === 1 && // Si el número total de tarjetas es impar
                  index === TotalVehicle?.ayer?.length - 1 && // Si es la última tarjeta
                  (screens.md || screens.lg || screens.xl) // Y el tamaño actual es md o mayor
                    ? 6
                    : 0
                }
                style={{
                  marginBottom: "20px",
                }}
                key={index}
              >
                <WalletCountCard
                  primary={item.name}
                  secondary={item.value}
                  color={theme.palette.info.light}
                  iconPrimary={todoterreno}
                />
              </Col>
            ))}
          </Row>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row gutter={24}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <Typography variant="h5">Hoy</Typography>
            </Col>
            {TotalVehicle?.hoy?.map((item, index) => (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                offset={
                  TotalVehicle?.ayer?.length % 2 === 1 && // Si el número total de tarjetas es impar
                  index === TotalVehicle?.ayer?.length - 1 && // Si es la última tarjeta
                  (screens.md || screens.lg || screens.xl) // Y el tamaño actual es md o mayor
                    ? 6
                    : 0
                }
                style={{
                  marginBottom: "20px",
                }}
                key={index}
              >
                <WalletCountCard
                  primary={item.name}
                  secondary={item.value}
                  color="#60E396"
                  iconPrimary={todoterreno}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default TotalVehicleReceived;
