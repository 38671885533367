import React, {useState, useEffect} from "react";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faPen, faTimes} from "@fortawesome/free-solid-svg-icons";
import "styled-components";
import {Link, useNavigate, useParams} from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {showWR} from "../Servicios/WerehouseService";
import InputSearch from "../Componentes/InputSearch/InputSearch";
import SelectMonth from "../Componentes/SelectMonth/SelectMonth";
import SelectYear from "../Componentes/SelectYear/SelectYear";
import Spinner from "../shared/spinner";
import dayjs from "dayjs";
import {DatePicker, Select, Table} from "antd";
import localStorageFilter from "../shared/utils/local-storage-filter";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import {getUserWarehouses} from "../Servicios/UsuarioService";

interface Filter {
  pageCount: number;
  currentPage: number;
  year: number;
  month: number;
  day: Date | null;
  search: string;
  userAssigned: string;
}

const TableWerehouse = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const [werehouses, setWerehouses] = useState([]);
  const [userWarehouses, setUserWarehouses] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [totalElements, setTotalElements] = useState(0);

  const [filters, setFilters] = useState({
    pageCount: localStorageFilter('warehouse:filter', 'pageCount') ?? 10,
    currentPage: localStorageFilter('warehouse:filter', 'currentPage') ?? 1,
    year: localStorageFilter('warehouse:filter', 'year') ?? new Date().getFullYear(),
    month: localStorageFilter('warehouse:filter', 'month') ?? new Date().getMonth() + 1,
    day: localStorageFilter('warehouse:filter', 'day', true),
    search: localStorageFilter('warehouse:filter', 'search') ?? '',
    userAssigned: localStorageFilter('warehouse:filter', 'userAssigned') ?? '',
  });

  useEffect(() => {
    if (id) {
      showWerehouse(filters).then();
    }

    const localFilters = localStorageFilter();
    if (JSON.stringify(filters) !== JSON.stringify(localFilters)) {
      localStorage.setItem('warehouse:filter', JSON.stringify(filters));
    }
  }, [id, filters]);

  useEffect(() => {
    setIsLoading(true);
    getUserWarehouses()
      .then(data => {
        setUserWarehouses(data);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const showWerehouse = async (filter: Filter) => {
    setIsLoading(true);
    const data = await showWR(id,
      filter.search !== "" ? 0 : filter.month,
      filter.search !== "" ? 0 : filter.year,
      filter.currentPage,
      filter.pageCount,
      filter.search,
      filter.day ? filter.day.getDate() : 0,
      filter.userAssigned);
    setIsLoading(false);

    if (data.status) {
      setWerehouses(data.list);
      setTotalElements(data.totalElements);
    }
  };

  const columns = [
    {
      key: "Editar",
      width: "60px",
      fixed: 'left',
      render: (_, row) => (
        <Button
          as={Link}
          to={`/editWereHouse/${row?.werehouseId}`}
          variant="primary" size={'sm'}
        >
          <FontAwesomeIcon icon={faPen}/>
        </Button>
      ),
    },
    {
      key: "Visualizar",
      width: "60px",
      fixed: 'left',
      render: (_, row) => (
        <Button
          as={Link}
          to={`/viewWereHouse/${row?.werehouseId}`}
          variant="success" size={'sm'}
        >
          <FontAwesomeIcon icon={faEye}/>
        </Button>
      ),
    },
    {
      key: "fecha",
      dataIndex: 'fecha',
      title: 'Fecha',
      width: "170px",
    },
    {
      title: "Codigo",
      key: "codigo",
      dataIndex: 'codigo',
      width: "100px",
    },
    {
      title: "Lote",
      key: "lote",
      dataIndex: 'lote',
      width: "110px",
    },
    {
      title: "VIN",
      key: "vin",
      dataIndex: 'vin',
      width: "185px",
    },
    {
      title: "Vehiculo",
      key: "vehiculoName",
      dataIndex: 'vehiculoName',
      width: "230px",
    },
    {
      title: "Color",
      key: "color",
      dataIndex: 'color',
      width: "80px",
    },
    {
      title: "# Llave",
      key: "numLlave",
      dataIndex: 'numLlave',
      width: "80px",
    },
    {
      title: "Shipper Info",
      key: "subName",
      dataIndex: 'subName',
      width: "180px",
    },
    {
      title: "Consignee Info",
      key: "impName",
      dataIndex: 'impName',
      width: "180px",
    },
    {
      title: "Origen",
      key: "origen",
      dataIndex: 'origen',
      render: (_, row) => row?.origen?.state,
      width: "80px",
    },
    {
      title: "Destino",
      key: "aduName",
      dataIndex: 'aduName',
      width: "110px",
    },
  ];

  const handlePageChange = (page, pageSize) => {
    setFilters({
      ...filters,
      currentPage: page,
      pageCount: pageSize,
    });
  };

  const handleChangeDay = (value) => {
    if (value) {
      setFilters({
        ...filters,
        day: value,
        month: value.getMonth() + 1,
        year: value.getFullYear(),
      });
    } else {
      setFilters({
        ...filters,
        day: null,
      });
    }
  }

  return (
    <Spinner loading={isLoading}>
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="mb-0">WAREHOUSE RECEIPT</h5>
        <Button onClick={() => navigate('/warehouse/autoparts')}>Crear Warehouse</Button>
      </div>
      <hr/>
      <Row>
        <Col xs={12} md={6} lg={3} className="mb-3">
          <p className="mb-2">Filtrar por Año:</p>
          <SelectYear year={filters.year} selectYear={year => setFilters({...filters, year: year})}/>
        </Col>
        <Col xs={12} md={6} lg={3} className="mb-3">
          <p className="mb-2">Filtrar por Mes:</p>
          <SelectMonth month={filters.month}
                       selectMonth={month => setFilters({...filters, month: month})}/>
        </Col>
        <Col xs={12} md={6} lg={3} className="mb-3">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <p className="mb-0">Filtrar por Fecha:</p>
            <ShowWhen show={!!filters.day}>
              <div className="text-danger me-1 cursor-pointer"
                   onClick={() => handleChangeDay(undefined)}>
                <FontAwesomeIcon icon={faTimes} className="text-danger"/> Borrar filtro
              </div>
            </ShowWhen>
          </div>
          <DatePicker className="w-100"
                      value={filters.day ? dayjs(filters.day) : ''}
                      onChange={(value) => handleChangeDay(value.$d)}/>
        </Col>
        <Col xs={12} md={6} lg={3} className="mb-3">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <p className="mb-0">Filtrar por Usuario:</p>
            <ShowWhen show={filters.userAssigned !== ''}>
              <div className="text-danger me-1 cursor-pointer"
                   onClick={() => setFilters({...filters, userAssigned: ''})}>
                <FontAwesomeIcon icon={faTimes} className="text-danger"/> Borrar filtro
              </div>
            </ShowWhen>
          </div>
          <Select className="w-100"
                  value={filters.userAssigned}
                  options={userWarehouses.map(e => ({value: `${e.fullName}`, label: `${e.fullName}`}))}
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      userAssigned: value,
                    })
                  }}/>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={12} md={12} lg={12}>
          <InputSearch initialValue={filters.search}
                       placeholder={'Seleccione un warehouse para Editar o Visualizar'}
                       doChange={(value) => setFilters({...filters, search: value, currentPage: 1})}/>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Table columns={columns}
                 dataSource={werehouses}
                 size="small"
                 loading={isLoading}
                 scroll={{x: 1300, y: 500}}
                 pagination={{
                   position: ["bottomRight"],
                   total: totalElements,
                   showSizeChanger: true,
                   showTotal: total => `${total} elementos`,
                   current: filters.currentPage,
                   pageSize: filters.pageCount,
                   pageSizeOptions: [20, 50, 100, 150, 200, 500],
                   onChange: handlePageChange
                 }} />
        </Col>
      </Row>
    </Spinner>
  );
};

export default TableWerehouse;
