import Spinner from "../shared/spinner";
import React, {useEffect, useState} from "react";
import {Button, Card, Checkbox, Col, Form, Input, InputNumber, Popconfirm, Row, Select} from "antd";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFloppyDisk, faPencil, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {getUserSellers} from "../Servicios/UsuarioService";
import {SolicitudEstadoEnum} from "../shared/enums/solicitud-estado.enum";
import {getCountriesId, hasRol, isSeller, validarPermisos} from "../Servicios/AuthService";
import {useMainContext} from "../contexts/MainContext";
import selectFilterOptions from "../shared/utils/select-filter-options";
import InputSearchCustomer from "../Componentes/InputSearchCustomer/InputSearchCustomer";
import ClienteForm from "../Carteracliente/ClienteForm";
import {Modal} from "antd/lib";
import InputSearchImportadora from "../Componentes/InputSearchImportadora/InputSearchImportadora";
import ImportadoraForm from "../Componentes/ImportadoraForm/ImportadoraForm";
import {showSubasta} from "../Servicios/CombosService";
import SubastaForm from "../Componentes/SubastaForm/SubastaForm";
import useCurrentUser from "../Hooks/UserHook";
import roles from "../utils/roles";
import {permisos} from "../utils/permisos";
import CancelarSolicitudBtn from "./CancelarSolicitudBtn";
import {addQuote, cancelQuote, editQuote, enviarEmail} from "../Servicios/SolicitudesService";
import {errorMessage, successMessage} from "../utils/toast-message";
import {useNavigate} from "react-router-dom";
import {SolicitudActivaEnum} from "../shared/enums/solicitud-activa.enum";
import environment from "../environments/environment";
import {SolicitudCambioAduana} from "../Resources/HtmlEmailBody/SolicitudCambioAduana";
import {SolicitudEnRevisionCorreccionEmail} from "../Resources/HtmlEmailBody/SolicitudEnRevisionCorreccion.email";
import {NotificarSolicitudDatosCompletados} from "../Resources/HtmlEmailBody/NotificarSolicitudDatosCompletados";
import moment from "moment";

const SolicitudForm = ({solicitud, vehiculo}) => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const [form] = Form.useForm();
  const { loadingMain, aduanas, countries, origenes } = useMainContext();

  const [userList, setUserList] = useState([]);
  const [subastas, setSubastas] = useState([]);

  const [switchDealer, setSwitchDealer] = useState(false);
  const [switchCutVehicle, setSwitchCutVehicle] = useState(false);
  const [switchEntregaBodega, setSwitchEntregaBodega] = useState(false);

  const [country, setCountry] = useState(undefined);
  const [cliente, setCliente] = useState(undefined);
  const [clienteFinal, setClienteFinal] = useState(undefined);
  const [aduanaAnterior, setAduanaAnterior] = useState(undefined);
  const [selectedSubasta, setSelectedSubasta] = useState(undefined);
  const [selectedImportadora, setSelectedImportadora] = useState(undefined);

  const [loading, setLoading] = useState(false);
  const [showModalClient, setShowModalClient] = useState(false);
  const [showImportadora, setShowImportadora] = useState(false);
  const [showSubastaField, setShowSubastaField] = useState(true);
  const [gatePassRequired, setGatePassRequired] = useState(false);
  const [showModalSubasta, setShowModalSubasta] = useState(false);
  const [isCommentInternal, setIsCommentInternal] = useState(false);
  const [showModalFinalClient, setShowModalFinalClient] = useState(false);
  const [showModalImportadora, setShowModalImportadora] = useState(false);
  const [showInputsAsDisabled, setShowInputsAsDisabled] = useState(false);
  const [showModalEditarImportadora, setShowModalEditarImportadora] = useState(false);

  const showCancelarButton = solicitud && validarPermisos(permisos.AGREGAR_SOLICITUDES) && hasRol(roles.ADMIN, roles.SUPERVISOR);

  useEffect(() => {
    const initForm = async () => {
      setLoading(true);
      const subastas = await showSubasta();
      const userSellers = await getUserSellers();
      setLoading(false);

      setUserList(userSellers);
      setSubastas(subastas.list);

      // colocar usuario que hizo la solicitud
      let userSellerId = getUserSellerId(userSellers);

      form.setFieldsValue({
        dealer: solicitud ? solicitud.dealer : false,
        cutVehicle: solicitud ? solicitud.cutVehicle : false,
        entregaEnBodega: solicitud ? solicitud.entregaEnBodega : false,
        gatePass: solicitud ? solicitud.gatePass : '',
        buyer: solicitud ? solicitud.buyer : '',
        tipoPago: solicitud ? solicitud.tipoPago : '',
        origenId: solicitud ? solicitud.origenId : '',
        aduanaId: solicitud ? solicitud.aduana && solicitud.aduana.isProvisional ? '' : solicitud.aduanaId : '',
        subastaId: solicitud ? solicitud.subastaId : '',
        clienteId: solicitud ? solicitud.clienteId : '',
        importadoraId: solicitud ? solicitud.importadoraId : '',
        quoted: solicitud ? solicitud.quoted : '', // flete
        estGrua: solicitud ? solicitud.estGrua : '', // grua
        bl: solicitud ? solicitud.bl : '20',
        costoQ: solicitud ? solicitud.costoQ : '',
        descripcionCostoAdicional: solicitud ? solicitud.descripcionCostoAdicional : '',
        notaInterna: solicitud ? solicitud.notaInterna : '',
        idClienteFinal: solicitud ? solicitud.idClienteFinal : '',
        proceso: solicitud ? solicitud.proceso : 'Sin ninguna Accion Realizada',
        registradoBy: solicitud ? solicitud.registradoBy : currentUser?.fullName,
        userSellerId: userSellerId,
      });

      if (solicitud) {
        setCountry(solicitud.country);
        setCliente(solicitud.clientes);
        setClienteFinal(solicitud.clienteFinal);
        setSelectedSubasta(solicitud.subasta);
        setAduanaAnterior(solicitud.aduanaId);
        setSelectedImportadora(solicitud.importadora);

        setSwitchDealer(solicitud.dealer);
        setSwitchCutVehicle(solicitud.cutVehicle);
        setSwitchEntregaBodega(solicitud.entregaEnBodega);

        if (isSeller()) {
          setShowInputsAsDisabled(
            solicitud.estado !== SolicitudEstadoEnum.PendienteCompletar &&
            solicitud.estado !== SolicitudEstadoEnum.EnRevision &&
            solicitud.estado !== SolicitudEstadoEnum.EntregasEnBodega
          );
        }
      }
    }

    if (currentUser) {
      initForm().then();
    }
  }, [solicitud, currentUser]);

  const getUserSellerId = (userSellers) => {
    let userSellerId = '';

    if (solicitud) {
      userSellerId = solicitud.userSellerId;

      // si el userSellerId no existe buscar por creador (nombre apellido)
      if (userSellerId === 0) {
        let user = userSellers
          .find(e => `${e.firstName} ${e.lastName}`.toLowerCase() === (solicitud.creador ?? "").toLowerCase());

        if (user) {
          userSellerId = user.idUser;
        } else {
          let user = userSellers
            .find(e => `${e.firstName} ${e.lastName}`.toLowerCase() === currentUser.fullName.toLowerCase());

          if (user) userSellerId = user.idUser;
          else userSellerId = '';
        }
      }
    } else {
      const user = userSellers.find(e => +e.idUser === +currentUser?.idusr);
      if (user) {
        userSellerId = user.idUser;
      }
    }

    return userSellerId;
  }

  const handleFinish = async (values) => {
    if (!values.aduanaId || values.aduanaId === '' || values.aduanaId === '0') {
      errorMessage('La aduana es requerida');
      return;
    }

    if (!country) {
      errorMessage('El país es requerida');
      return;
    }

    const seller = userList.find(e => +e.idUser === +values.userSellerId);

    if (!seller) {
      errorMessage('No se ha especificado al vendedor');
      return;
    }

    const input = {
      ...values,
      dealer: switchDealer,
      cutVehicle: switchCutVehicle,
      entregaBodega: switchEntregaBodega,
      vehiculoId: vehiculo.vehiculoId,
      fechaQ: solicitud ? solicitud.fechaQ : moment().format(),
      buyer: +values.buyer,
      clienteId: +values.clienteId,
      aduanaId: +values.aduanaId,
      origenId: +values.origenId,
      countryId: country.id,
      subastaId: +values.subastaId,
      userSellerId: +values.userSellerId,
      importadoraId: +values.importadoraId,
      gatePass: values?.gatePass?.toString(),
      bl: values?.bl?.toString(),
      costoQ: values?.costoQ?.toString(),
      quoted: values?.quoted?.toString(),
      estGrua: values?.estGrua?.toString(),
      notaInterna: values.notaInterna ? values.notaInterna : "",
      idClienteFinal: values.idClienteFinal ? +values.idClienteFinal : null,
      activa: SolicitudActivaEnum.Activa,
      creador: `${seller?.firstName} ${seller?.lastName}`,
      estado: solicitud
        ? solicitud.estado === SolicitudEstadoEnum.EnRevision
          ? SolicitudEstadoEnum.SinAsignar
          : solicitud.estado
        : SolicitudEstadoEnum.SinAsignar,
    };

    setLoading(true);
    const data = solicitud
      ? await editQuote(solicitud.quoteId, input)
      : await addQuote(input);
    setLoading(false);

    if (data && data.status) {
      successMessage("Solicitud guardada correctamente");

      if (solicitud) {
        await enviarCorreo(data.data);
        navigate('/requests/list');
      } else {
        navigate(`/sendprintRequest/${data.data.quoteId}`);
      }
    } else {
      errorMessage(data.message);
    }
  }

  const enviarCorreo = async (nuevaSolicitud) => {
    if (!environment.production) {
      solicitud.emailUserAssigned = "manuelpc2495@gmail.com";
      solicitud.emailUserCreated = "manuelpc2495@gmail.com";
      solicitud.emailUserSeller = "manuelpc2495@gmail.com";

      nuevaSolicitud.emailUserAssigned = "manuelpc2495@gmail.com";
      nuevaSolicitud.emailUserCreated = "manuelpc2495@gmail.com";
      nuevaSolicitud.emailUserSeller = "manuelpc2495@gmail.com";
    }

    const notificarAsignado = solicitud.estado === SolicitudEstadoEnum.EnRevision;
    const notificarCompletado = solicitud.estado === SolicitudEstadoEnum.PendienteCompletar;

    if (notificarAsignado) {
      const emails = [];
      const emailsCC = [];

      enviarEmail({
        to: emails,
        cc: emailsCC,
        cco: [],
        subject: `Corrección de Solicitud ${vehiculo.lote} Cliente: ${cliente?.nombreCompleto}`,
        body: SolicitudEnRevisionCorreccionEmail(nuevaSolicitud, cliente?.nombreCompleto)
      }).then();
    }

    if (nuevaSolicitud.aduanaId !== aduanaAnterior) {
      enviarEmail({
        to: [environment.emails.grua],
        cc: [currentUser.email],
        cco: [],
        subject: `***CAMBIO DE ADUANA*** VEHÍCULO LOTE # ${vehiculo?.lote} - ${vehiculo?.year} ${vehiculo?.make} ${vehiculo?.model}`,
        body: SolicitudCambioAduana(nuevaSolicitud)
      }).then();
    }

    if (notificarCompletado) {
      const emails = [];
      const emailsCC = [];

      if (nuevaSolicitud.emailUserAssigned && nuevaSolicitud.emailUserAssigned !== "")
        emails.push(nuevaSolicitud.emailUserAssigned);

      if (nuevaSolicitud.emailUserCreated && nuevaSolicitud.emailUserCreated !== "")
        emailsCC.push(nuevaSolicitud.emailUserCreated);

      if (nuevaSolicitud.emailUserSeller && nuevaSolicitud.emailUserSeller !== "")
        emailsCC.push(nuevaSolicitud.emailUserSeller);

      enviarEmail({
        to: emails,
        cc: emailsCC,
        cco: [],
        subject: `Datos completados de la Solicitud ${vehiculo.lote}. Cliente: ${cliente?.nombreCompleto}`,
        body: NotificarSolicitudDatosCompletados(nuevaSolicitud, cliente?.nombreCompleto)
      }).then();
    }
  }

  const handleCheckboxChange = (event, key) => {
    form.setFieldValue(key, event.target.checked);

    if (key === 'dealer') {
      setSwitchDealer(event.target.checked);
    }

    if (key === 'cutVehicle') {
      setSwitchCutVehicle(event.target.checked);
    }

    if (!solicitud && key === 'entregaEnBodega') {
      setShowSubastaField(!event.target.checked);
      setSwitchEntregaBodega(event.target.checked);

      if (event.target.checked) {
        form.setFieldValue('subastaId', null);
      }
    }
  }

  const handleSelectAduana = (value) => {
    const aduana = aduanas.find(e => +e.id === +value);
    if (!aduana) return;

    const country = countries.find(e => e.id === +aduana.countryId);

    form.setFieldsValue({
      countryId: aduana.countryId,
      bl: aduana.countryId === "3" ? "0" : "20",
    });

    setCountry(country);
    setShowImportadora(country?.quoteConsignee);
  }

  const handleSelectSubasta = (value) => {
    const subasta = subastas.find(e => +e.subId === +value);
    setSelectedSubasta(subasta);
    setGatePassRequired(subasta?.subName?.indexOf("COPART") !== -1);
  }

  const handleSelectChangeCliente = customer => {
    setCliente(customer);
    setSelectedImportadora(customer.importadora);

    form.setFieldsValue({
      clienteId: customer.id,
      tipoPago: customer.classPay,
      importadoraId: customer.importadoraId,
    });
  }

  const handleSelectChangeImportadora = (importadora) => {
    setSelectedImportadora(importadora);

    form.setFieldsValue({
      importadoraId: importadora.id
    });
  };

  const handleCancelCustomer = () => {
    if (showModalClient) setShowModalClient(false);
    if (showModalFinalClient) setShowModalFinalClient(false);
  };

  const handleSaveCustomer = async (customer) => {
    if (showModalClient) {
      setCliente(customer);
      setSelectedImportadora(customer.importadora);
      setShowModalClient(false);

      form.setFieldsValue({
        clienteId: customer.id,
        tipoPago: customer.classPay,
        importadoraId: customer.importadoraId,
      });
    }

    if (showModalFinalClient) {
      setClienteFinal(customer);
      setShowModalFinalClient(false);

      form.setFieldsValue({
        idClienteFinal: customer.id,
      });
    }
  };

  const handleSaveImportadora = async (importadora) => {
    setSelectedImportadora(importadora);
    setShowModalImportadora(false);
    setShowModalEditarImportadora(false);

    form.setFieldsValue({
      importadoraId: importadora.id,
    });
  };

  const handleInsertarSubasta = async (newSubasta) => {
    setSelectedSubasta(newSubasta);

    form.setFieldsValue({
      subastaId: newSubasta.subId,
      origenId: newSubasta.origen,
    });

    const subastas = await showSubasta();
    setSubastas(subastas.list);
    setShowModalSubasta(false);
  };

  const handleSelectChangeClienteFinal = customer => {
    setClienteFinal(customer);
    form.setFieldsValue({
      idClienteFinal: customer.id,
    });
  }

  const handleAsignadoAChange = (value) => {
    const user = userList.find(e => +e.idUser === +value);
    form.setFieldsValue({
      creador: `${user.firstName} ${user.lastName}`
    });
  }

  const handleCancelarSolicitud = async (description) => {
    setLoading(true);
    const data = await cancelQuote(solicitud.quoteId, {comentarios: description})
    setLoading(false);

    if (data) {
      successMessage("La solicitud fue cancelada correctamente");
      navigate('/requests/list');
    }
  }

  return(
    <>
      <Spinner loading={loading || loadingMain}>
        <Form form={form}
              layout={'vertical'}
              onFinish={handleFinish}>
          <Row gutter={16}>
            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
              <Checkbox className="my-3"
                        checked={switchDealer}
                        onChange={(ev) => handleCheckboxChange(ev, 'dealer')}>
                Matus Dealer?
              </Checkbox>
            </Col>
            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
              <Checkbox className="my-3"
                        checked={switchCutVehicle}
                        onChange={(ev) => handleCheckboxChange(ev, 'cutVehicle')}>
                Cortar Vehículo
              </Checkbox>
            </Col>
            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
              <Checkbox className="my-3"
                        checked={switchEntregaBodega}
                        disabled={!!solicitud}
                        onChange={(ev) => handleCheckboxChange(ev, 'entregaEnBodega')}>
                Entrega en Bodega
              </Checkbox>
            </Col>
            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
              <Form.Item label="Gate Pass:" name="gatePass"
                         rules={[{ required: gatePassRequired, message: 'Este campo es requerido' }]}>
                <InputNumber className="w-100" placeholder={"Gate Pass"} disabled={showInputsAsDisabled} />
              </Form.Item>
            </Col>
            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
              <Form.Item label="Buyer:" name="buyer"
                         rules={[{ required: true, message: 'Este campo es requerido' }]}>
                <InputNumber className="w-100" placeholder={"Buyer"} disabled={showInputsAsDisabled} />
              </Form.Item>
            </Col>
            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
              <Form.Item label="Tipo de Pago:" name="tipoPago"
                         extra={'Seleccionar un cliente'}
                         rules={[{ required: true, message: 'Este campo es requerido' }]}>
                <Input className="w-100" placeholder={"Tipo de Pago"} disabled={true} />
              </Form.Item>
            </Col>

            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item label="Origen" name="origenId"
                         rules={[{ required: true, message: 'Este campo es requerido' }]}>
                <Select placeholder={'Seleccionar Origen'}
                        showSearch={true}
                        filterOption={selectFilterOptions}
                        disabled={showInputsAsDisabled}
                        options={origenes.map(e => ({label: `${e?.nameOrigen} / ${e?.city}`, value: e?.id}))} />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item label="Aduana Destino" name="aduanaId"
                         rules={[{ required: true, message: 'Este campo es requerido' }]}>
                <Select placeholder={'Seleccionar Aduana'}
                        showSearch={true}
                        filterOption={selectFilterOptions}
                        disabled={showInputsAsDisabled}
                        onChange={handleSelectAduana}
                        options={aduanas
                          .filter((e) => !solicitud || !e.isProvisional)
                          .filter((e) => getCountriesId() === "0" || getCountriesId().indexOf(e.countryId) !== -1)
                          .map(e => ({label: `${e?.nameAduana} - ${e?.countryName}`, value: e?.id}))}/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <p className="m-0">Cliente</p>
                <ShowWhen show={!showInputsAsDisabled}>
                  <Button type={'link'}
                          size={"sm"}
                          onClick={() => setShowModalClient(true)}>
                    <FontAwesomeIcon icon={faPlus} className="me-2"/> Agregar Cliente
                  </Button>
                </ShowWhen>
              </div>
              <Form.Item name="clienteId"
                         rules={[{required: true, message: 'Este campo es requerido'}]}>
                <InputSearchCustomer disabled={showInputsAsDisabled}
                                     selectedCustomer={cliente}
                                     filterByFinalCustomer={false}
                                     onSelectedCustomer={handleSelectChangeCliente}/>
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <p className="m-0">Importadora</p>
                <ShowWhen show={showImportadora && !showInputsAsDisabled}>
                  <Button type={'link'}
                          size={"sm"}
                          onClick={() => setShowModalImportadora(true)}>
                    <FontAwesomeIcon icon={faPlus} className="me-2"/> Agregar Importadora
                  </Button>
                </ShowWhen>
              </div>
              <Form.Item name="importadoraId"
                         rules={[{required: true, message: 'Este campo es requerido'}]}>
                <InputSearchImportadora disabled={!showImportadora}
                                        selectedImportadora={selectedImportadora}
                                        onSelectImportadora={handleSelectChangeImportadora}/>
              </Form.Item>
              <ShowWhen show={selectedImportadora}>
                <Card className="my-3" size={'small'}>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <p className="fw-bold m-0 text-uppercase">{selectedImportadora?.impName}</p>
                    {/*//</div><ShowWhen show={!showInputsAsDisabled}>
                      //<Button type={'link'} size={"sm"}
                              //</ShowWhen>onClick={() => setShowModalEditarImportadora(true)}>
                        //<FontAwesomeIcon icon={faPencil} className="me-2"/> Modificar Importadora
                      //</Button>
                    //</ShowWhen>*/}
                  </div>
                  <p
                    className="m-0 text-uppercase">{selectedImportadora?.identfType ?? "Identificador"}: {selectedImportadora?.identf ?? "SIN ASIGNAR"}</p>
                  <p className="m-0 text-uppercase">Dirección: {selectedImportadora?.direccion?.toUpperCase()}</p>
                  <p className="m-0 text-uppercase">{selectedImportadora?.country}</p>
                </Card>
              </ShowWhen>
            </Col>
            <ShowWhen show={showSubastaField}>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <p className="m-0">Seleccionar Subasta</p>
                  <Button type={'link'}
                          size={"sm"}
                          onClick={() => setShowModalSubasta(true)}>
                    <FontAwesomeIcon icon={faPlus} className="me-2"/> Agregar Subasta
                  </Button>
                </div>
                <Form.Item label="" name="subastaId"
                           rules={[{required: true, message: 'Este campo es requerido'}]}>
                  <Select placeholder={'Seleccionar Subasta'}
                          showSearch={true}
                          disabled={showInputsAsDisabled}
                          filterOption={selectFilterOptions}
                          onChange={handleSelectSubasta}
                          options={subastas
                            .map(e => ({label: `${e?.subName} - ${e?.direccion}`, value: e?.subId}))}/>
                </Form.Item>
                <ShowWhen show={selectedSubasta}>
                  <Card className="my-3" size={'small'}>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <p className="fw-bold m-0 text-uppercase">{selectedSubasta?.subName}</p>
                    </div>
                    <p className="m-0 text-uppercase">Dirección: {selectedSubasta?.direccion?.toUpperCase()}</p>
                    <p className="m-0 text-uppercase">{selectedSubasta?.countryName}</p>
                  </Card>
                </ShowWhen>
              </Col>
            </ShowWhen>

            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
              <Form.Item label="Costo de Flete" name="quoted"
                         rules={[{required: true, message: 'Este campo es requerido'}]}>
                <InputNumber addonBefore="$" className="w-100" placeholder={"Costo de Flete"}
                             disabled={showInputsAsDisabled}/>
              </Form.Item>
            </Col>
            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
            <Form.Item label="Costo de Grua" name="estGrua"
                         rules={[{required: true, message: 'Este campo es requerido'}]}>
                <InputNumber addonBefore="$" className="w-100" placeholder={"Costo de Grua"}
                             disabled={showInputsAsDisabled} />
              </Form.Item>
            </Col>
            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
              <Form.Item label="Costo BL" name="bl"
                         rules={[{ required: true, message: 'Este campo es requerido' }]}>
                <InputNumber addonBefore="$" className="w-100" placeholder={"Costo de BL"} disabled={showInputsAsDisabled} />
              </Form.Item>
            </Col>

            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
              <Form.Item label="Cargos Adicionales" name="costoQ">
                <InputNumber addonBefore="$" className="w-100" placeholder={"Cargos Adicionales"} disabled={showInputsAsDisabled} />
              </Form.Item>
            </Col>
            <Col xl={16} lg={16} md={12} sm={24} xs={24}>
              <Form.Item label="Descripción de Cargos Adicionales" name="descripcionCostoAdicional">
                <Input className="w-100" placeholder="Descripción de Cargos Adicionales" disabled={showInputsAsDisabled} />
              </Form.Item>
            </Col>

            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Form.Item label="Comentarios por correo (Copia cliente)" name="comentarios"
                         rules={[{ max: 150, message: 'Maximo 150 caracteres' }]}>
                <Input.TextArea rows={3} placeholder="Comentarios por correo (Copia cliente)" />
              </Form.Item>
            </Col>
            <ShowWhen show={!isCommentInternal}>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Popconfirm title="Comentario Interno"
                            description="Este comentario solo será visible para los usuarios internos de Matus, el cliente no podrá verlo. ¿Desea agregar un comentario interno?"
                            onConfirm={() => setIsCommentInternal(!isCommentInternal)}
                            onCancel={() => setIsCommentInternal(false)}
                            okText="Yes"
                            cancelText="No">
                  <div className="text-center">
                    <Button type="primary">
                      Agregar Comentario Interno
                    </Button>
                  </div>
                </Popconfirm>
              </Col>
            </ShowWhen>
            <ShowWhen show={isCommentInternal}>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <p className="m-0">Comentario interno Matus (Opcional)</p>
                  <Button type="link" danger onClick={() => setIsCommentInternal(!isCommentInternal)}>
                    <FontAwesomeIcon icon={faTimes} className="me-2"/> Cerrar
                  </Button>
                </div>
                <Form.Item label="" name="notaInterna"
                           rules={[{max: 150, message: 'Maximo 150 caracteres'}]}>
                  <Input.TextArea rows={3} placeholder="Comentario interno Matus (Opcional)"/>
                </Form.Item>
              </Col>
            </ShowWhen>

            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <p className="m-0">Cliente Final (Opcional)</p>
                <Button type={'link'}
                        size={"sm"}
                        onClick={() => setShowModalFinalClient(true)}>
                  <FontAwesomeIcon icon={faPlus} className="me-2"/> Agregar Cliente
                </Button>
              </div>
              <Form.Item name="idClienteFinal">
                <InputSearchCustomer selectedCustomer={clienteFinal}
                                     filterByFinalCustomer={true}
                                     onSelectedCustomer={handleSelectChangeClienteFinal}/>
              </Form.Item>
            </Col>

            <ShowWhen show={solicitud && solicitud.estado === SolicitudEstadoEnum.EnRevision}>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Form.Item label="Notas de Revisión" name="proceso">
                  <Input.TextArea rows={3} placeholder="Notas de Revisión" disabled={true}/>
                </Form.Item>
              </Col>
            </ShowWhen>

            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item label="Registrado Por:" name="registradoBy"
                         rules={[{ required: true, message: 'Este campo es requerido' }]}>
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item label="Asignado A:" name="userSellerId"
                         rules={[{ required: true, message: 'Este campo es requerido' }]}>
                <Select placeholder={'Seleccionar'}
                        showSearch={true}
                        disabled={!!solicitud && solicitud.estado !== SolicitudEstadoEnum.PendienteCompletar && solicitud.estado !== SolicitudEstadoEnum.EntregasEnBodega && !hasRol(roles.ADMIN, roles.SUPERVISOR)}
                        filterOption={selectFilterOptions}
                        onChange={handleAsignadoAChange}
                        options={userList.map(e => ({label: `${e?.firstName} ${e?.lastName}`, value: e?.idUser}))} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xl={showCancelarButton ? 12 : 24}
                 lg={showCancelarButton ? 12 : 24}
                 md={showCancelarButton ? 12 : 24}
                 sm={24}
                 xs={24}>
              <Button type="primary" htmlType={'submit'} block
                      icon={<FontAwesomeIcon icon={faFloppyDisk} className="me-2"/>} loading={loading}>
                Guardar Recolección
              </Button>
            </Col>
            <ShowWhen show={showCancelarButton}>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <CancelarSolicitudBtn block={true} onCancelar={handleCancelarSolicitud}/>
              </Col>
            </ShowWhen>
          </Row>
        </Form>
      </Spinner>

      <Modal open={showModalClient || showModalFinalClient}
             closable={false}
             title={'Agregar Cliente'}
             cancelButtonProps={{style: { display: "none" } }}
             okButtonProps={{ style: { display: "none" } }}>
        <ClienteForm shortSaved={true}
                     countryId={0}
                     importadora={showModalClient ? undefined : selectedImportadora}
                     finalClient={showModalFinalClient}
                     submitCliente={handleSaveCustomer}
                     onCancel={handleCancelCustomer} />
      </Modal>
      <Modal open={showModalImportadora || showModalEditarImportadora}
             closable={false}
             title={showModalEditarImportadora ? 'Modificar Importadora' : 'Agregar Importadora'}
             cancelButtonProps={{ style: { display: "none" } }}
             okButtonProps={{ style: { display: "none" } }}>
        <ImportadoraForm importadora={showModalImportadora ? undefined : selectedImportadora}
                         afterSubmit={handleSaveImportadora}
                         country={showModalEditarImportadora ? undefined : country?.id}
                         onCancel={() => {
                           setShowModalImportadora(false);
                           setShowModalEditarImportadora(false);
                         }} />
      </Modal>
      <Modal open={showModalSubasta}
             closable={false}
             cancelButtonProps={{ style: { display: "none" } }}
             okButtonProps={{ style: { display: "none" } }}
             title={'Agregar Subasta'}>
        <SubastaForm onCancel={() => setShowModalSubasta(false)}
                     afterSave={handleInsertarSubasta} />
      </Modal>
    </>
  );
}

export default SolicitudForm;
