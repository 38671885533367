import {Button, Col, Form, Input, Row, Switch} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle, faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {editCar, vinDecode} from "../Servicios/VehicleService";
import {errorMessage, successMessage} from "../utils/toast-message";
import Spinner from "../shared/spinner";

const VehicleWarehouseUpdate = ({ vehiculo, onUpate, onClose }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (vehiculo) {
      form.setFieldsValue({
        year: vehiculo.year,
        make: vehiculo.make,
        model: vehiculo.model,
        color: vehiculo.color,
        vin: vehiculo.vin,
        weight: vehiculo.weight,
        length: vehiculo.length,
        height: vehiculo.height,
        width: vehiculo.width,
        Storage: vehiculo.storage,
        lote: vehiculo.lote,
        titulo: vehiculo.titulo,
        bio: vehiculo.bio,
        llave: vehiculo.llave,
        catalitico: vehiculo.catalitico,
      });
    }
  }, [vehiculo]);

  const editVehiculo = async (value) => {
    const input = {
      ...vehiculo,
      year: value.year,
      make: value.make,
      model: value.model,
      color: value.color,
      vin: value.vin,
      weight: value.weight ? value.weight.toString() : '',
      length: value.length ? value.length.toString() : '',
      height: value.height ? value.height.toString() : '',
      width: value.width ? value.width.toString() : '',
      storage: value.Storage,
      titulo: value.titulo,
      bio: value.bio,
      llave: value.llave,
      catalitico: value.catalitico,
    };

    setIsLoading(true);
    const data = await editCar(vehiculo.vehiculoId, input);
    setIsLoading(false);

    if (data && data.status) {
      if (onUpate) onUpate(data.data);
    } else {
      errorMessage(data.message);
    }
  };

  const handleVerificarVIN = async () => {
    setIsLoading(true);
    const data = await vinDecode(form.getFieldValue('vin'));
    setIsLoading(false);

    if (data && data.status) {
      form.setFieldsValue({
        year: data.data.year,
        make: data.data.make,
        model: data.data.model,
        width: data.data.width,
        height: data.data.height,
        length: data.data.length,
        weight: +(data.data.weight * 0.453592).toFixed(3) // libras a kilogramos
      });
      successMessage('Datos del vehículo modificados, click en "Actualizar" para guardar los datos');
    } else {
      errorMessage(data.message);
    }
  }

  return(
    <Spinner loading={isLoading}>
      <Form
        layout="vertical"
        form={form}
        onFinish={editVehiculo}
        name="form_in_modal"
      >
        <Row gutter={16}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item className="mb-0" label="VIN" name="vin">
              <Input/>
            </Form.Item>
            <p className="mt-2 mb-1 fw-bold">
              <FontAwesomeIcon className="text-primary" icon={faExclamationCircle}/> Verificar VIN
            </p>
            <p className={"mb-0"}>
              <Button className="text-primary me-2" onClick={handleVerificarVIN}>Click aquí</Button> para verificar
              el VIN del vehículo
            </p>
            <hr/>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="LOTE" name="lote">
              <Input/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="Marca" name="make">
              <Input/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="Modelo" name="model">
              <Input/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="Año" name="year">
              <Input/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="Titulo" name="titulo">
              <Switch defaultChecked={vehiculo?.titulo}/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="BioH" name="bio">
              <Switch defaultChecked={vehiculo?.bio}/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="Llave" name="llave">
              <Switch defaultChecked={vehiculo?.llave}/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="Catalitico" name="catalitico">
              <Switch defaultChecked={vehiculo?.catalitico}/>
            </Form.Item>
          </Col>

          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="Peso (Kg)" name="weight">
              <Input/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="Largo (In)" name="length">
              <Input/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="Alto (In)" name="height">
              <Input/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="Ancho (In)" name="width">
              <Input/>
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item label="Color" name="color">
              <Input/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Button onClick={onClose} block>
              Cancelar
            </Button>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Button type="primary" htmlType="submit" block>
              <FontAwesomeIcon icon={faFloppyDisk} className="me-2"/> Actualizar
            </Button>
          </Col>
        </Row>
      </Form>
    </Spinner>
  );
}

export default VehicleWarehouseUpdate;
