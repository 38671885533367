import {ApiService} from "./ApiService";
import {server} from "../environments/environment";

export const crearScheduleB = async (input) => {
  const response = await ApiService.post(`${server}ScheduleB`, input);
  const data = await response.json();

  if (data.status) {
    return data.data;
  } else {
    throw new Error(data.message);
  }
}
