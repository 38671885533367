import {Button, Dropdown, Table, Tag} from "antd";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCheckDouble,
  faExclamationTriangle, faInfoCircle,
  faPencil,
  faTriangleExclamation, faTruck
} from "@fortawesome/free-solid-svg-icons";
import {exportationContainerStateEnum} from "../../../shared/enums/exportation-container-state.enum";
import DetalleExportationTable from "../DetalleExportationTable";
import {
  getExportationContainerById,
  updateExportationContainer,
  updateExportationContainerLoaded
} from "../../../Servicios/ExportationContainerService";
import {errorMessage, successMessage, warningMessage} from "../../../utils/toast-message";
import {useState} from "react";
import DetailExportationDrawer from "../../../Componentes/DetailExportationDrawer";
import DetalleExportInfo from "./DetalleExportInfo";
import {validarPermisos} from "../../../Servicios/AuthService";
import {permisos} from "../../../utils/permisos";
import ShowWhen from "../../../Componentes/ShowWhen/ShowWhen";
import {enviarEmail} from "../../../Servicios/SolicitudesService";
import {NotificacionCargaEmail} from "../../../Resources/HtmlEmailBody/NotificacionCarga.email";
import environment from "../../../environments/environment";
import {getExportationDetailByContainerList} from "../../../Servicios/ExportationServices";
import Spinner from "../../../shared/spinner";

const ExportationContainerTable = ({ filters,
                                     currentUser,
                                     totalElements,
                                     exportationContainers,
                                     onUpdate,
                                     onPageChange,
                                     onUpdateExportationContainer,
                                     onUpdateExportationContainerSequence,
                                     fechaSeleccionada }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expendadedKey, setExpandedKey] = useState([]);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [exportationDetails, setExportationDetails] = useState([]);
  const [dataExportationDrawer, setDataExportationDrawer] = useState({});

  const items = element => {
    const items = [
      {
        key: "1",
        icon: <FontAwesomeIcon icon={faPencil} />,
        label: "Modificar",
        onClick: () => buscarExportationContainer(element).then((data) => onUpdateExportationContainer(data)),
        isVisible: validarPermisos(permisos.PLANIFICACION_CONTENEDOR_AGREGAR)
      },
      {
        key: "2",
        icon: <FontAwesomeIcon icon={faPencil} />,
        label: "Modificar Secuencia",
        onClick: () => buscarExportationContainer(element).then((data) => onUpdateExportationContainerSequence(data)),
        isVisible: validarPermisos(permisos.PLANIFICACION_CONTENEDOR_AGREGAR)
      },
      {
        key: "3",
        icon: <FontAwesomeIcon icon={faCheckDouble} />,
        label: "Marcar como cargado",
        onClick: () => buscarExportationContainer(element).then((data) => handleMarcarTerminado(data)),
        isVisible: validarPermisos(permisos.PLANIFICACION_CONTENEDOR_AGREGAR) && 
        element.loadedAt === null
      },
      {
        key: "4",
        icon: <FontAwesomeIcon icon={faExclamationTriangle} />,
        label: "Marcar como Coneado",
        onClick: () => buscarExportationContainer(element).then((data) => handleConedExportationContainer(data)),
        isVisible: !element.conePlaced && validarPermisos(permisos.PLANIFICACION_CONTENEDOR_CONEAR)
      },
      {
        key: "5",
        icon: <FontAwesomeIcon icon={faTriangleExclamation} />,
        label: "Marcar como NO coneado",
        onClick: () => buscarExportationContainer(element).then((data) => handleConedExportationContainer(data)),
        isVisible: element.conePlaced && validarPermisos(permisos.PLANIFICACION_CONTENEDOR_CONEAR)
      },
      {
        key: "6",
        icon: <FontAwesomeIcon icon={faInfoCircle} />,
        label: "Ver Detalle",
        onClick: () => {
          buscarExportationContainer(element).then((data) => {
            setOpen(true);
            setDataExportationDrawer(data);
          });
        },
        isVisible: true
      },
      {
        key: "7",
        icon: <FontAwesomeIcon icon={faTruck} />,
        label: "Enviar Notificación de Carga",
        onClick: () => buscarExportationContainer(element).then((data) => handleEnviarNotificacionCarga(data)),
        isVisible: validarPermisos(permisos.PLANIFICACION_CONTENEDOR_AGREGAR)
      }
    ];

    return items.filter(e => e.isVisible).map(e => ({
      key: e.key,
      icon: e.icon,
      label: e.label,
      onClick: e.onClick,
    }));
  };

  const columns = [
    {
      title: "",
      dataIndex: "options",
      key: "options",
      fixed: 'left',
      width: '50px',
      render: (_, element) => (
        <Dropdown trigger={['click']}
                  menu={{ items: items(element) }}
                  placement="bottomLeft" >
          <Button shape="circle" type={'primary'}><FontAwesomeIcon size={'sm'} icon={faBars} /></Button>
        </Dropdown>
      ),
    },
    {
      key: 'sequence',
      dataIndex: 'sequence',
      title: 'Sec.',
      width: '80px',
      render: (_, element) => element?.sequence
        ? element?.sequence
        : 'N/A'
    },
    {
      key: 'colorCone',
      dataIndex: 'colorCone',
      title: 'Color de Cono',
      width: '110px'
    },
    {
      title: "# Items",
      dataIndex: "numberItems",
      key: "numberItems",
      width: '70px',
    },
    {
      title: "Coneado",
      dataIndex: "conePlaced",
      key: "conePlaced",
      width: '100px',
      render: (text, record) =>
        record?.conePlaced ? (
          <Tag color="green">SI</Tag>
        ) : (
          <Tag color="red">NO</Tag>
        ),
    },
    {
      title: "#Ref",
      dataIndex: "shipmentName",
      key: "shipmentName",
      width: '140px',
    },
    {
      key: 'containerNumber',
      dataIndex: 'containerNumber',
      title: '# de Contenedor',
      width: '150px',
    },
    {
      title: "Capitán",
      dataIndex: "captain",
      key: "captain",
      width: '140px',
    },
    {
      key: 'state',
      dataIndex: 'state',
      title: 'Estado',
      width: '120px',
      render: (_, element) => (
        <>
          <ShowWhen show={element?.state === exportationContainerStateEnum.creado}>
            <div className="badge bg-primary">{element?.state}</div>
          </ShowWhen>
          <ShowWhen show={element?.state === exportationContainerStateEnum.asignado}>
            <div className="badge bg-info">{element?.state}</div>
          </ShowWhen>
          <ShowWhen show={element?.state === exportationContainerStateEnum.cargado}>
            <div className="badge bg-success">CARGADO</div>
          </ShowWhen>
        </>
      )
    },
    {
      key: 'containerName',
      dataIndex: 'containerName',
      title: 'Tipo Cont.',
      width: '150px',
    },
    {
      key: 'sealNumber',
      dataIndex: 'sealNumber',
      title: '# de Sello',
      width: '100px',
    },
    {
      title: "Trucker",
      dataIndex: "truckerName",
      key: "truckerName",
      width: '140px',
    },
    {
      key: 'carrierName',
      dataIndex: 'carrierName',
      title: 'Naviera',
      width: '150px',
    },
    {
      title: "Barco",
      dataIndex: "vesselNameFlag",
      key: "vesselNameFlag",
      width: '140px',
    },
    {
      title: "Viaje",
      dataIndex: "voyageIdentification",
      key: "voyageIdentification",
      width: '120px',
    },
    {
      key: 'aduanaName',
      dataIndex: 'aduanaName',
      title: 'Destino',
      width: '150px',
      render: (_, element) => !element.aduanaName
        ? '---'
        : <div>
          <p className="mb-0">{element?.countryName}</p>
          <p className="mb-0">{element?.aduanaName}</p>
        </div>
    },
    {
      title: "Hora de Carga",
      dataIndex: "loadedAt",
      key: "loadedAt",
      width: '140px',
      render: (_, element) => element?.loadedAt
        ? <>
          <p className="mb-0">{moment(element?.loadedAt).format('DD-MM-yyyy')}</p>
          <p className="mb-0">{moment(element?.loadedAt).format('HH:mm:ss')}</p>
        </>
        : '---',
    }
  ];

  const buscarExportationContainer = (element) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true);
        const data = await getExportationContainerById(element.idExportationContainer);
        setLoading(false);

        resolve(data);
      } catch (ex) {
        errorMessage(ex.toString());
        reject();
      }
    });
  }

  const handleMarcarTerminado = async record => {
    try {
      const input = {
        loadedBy: currentUser.fullName,
        loadedNotes: record?.loadedNotes ?? "",
      };

      setLoading(true);
      await updateExportationContainerLoaded(record?.idExportationContainer, input);
      setLoading(false);

      successMessage("Hora de carga editada con exito");
      if (onUpdate) onUpdate();
    } catch (ex) {
      errorMessage(ex.toString());
    }
  };

  const handleConedExportationContainer = async record => {
    try {
      const input = {
        ...record,
        werehouses: null, // null para que no modifique la lista de detalleExport asociados
        conePlaced: !record?.conePlaced,
      };

      setLoading(true);
      const resp = await updateExportationContainer(record.idExportationContainer, input);
      setLoading(false);

      if (resp?.conePlaced) {
        successMessage("Coneado con exito");
      } else {
        warningMessage("Desconado con exito");
      }

      if (onUpdate) onUpdate();
      setOpen(false);
      setDataExportationDrawer(undefined);
    } catch (ex) {
      errorMessage(ex.toString());
    }
  }

  const handleEnviarNotificacionCarga = async (exportationContainer) => {
    if (!exportationContainer?.exportation || !exportationContainer?.exportation?.preCarriageBy) {
      errorMessage('No se ha asignado ningún transportista a este embarque');
      return;
    }

    if (exportationContainer?.exportation?.preCarriageBy?.correosList?.length === 0) {
      errorMessage('No se ha configurado una lista de correos para este transportista');
      return;
    }

    if (!environment.production) {
      exportationContainer.exportation.preCarriageBy.correosList = ['manuelpc2495@gmail.com'];
    }

    setLoading(true);
    await enviarEmail({
      to: exportationContainer?.exportation?.preCarriageBy?.correosList,
      subject: `Aviso de contenedor listo para recolección REF# ${exportationContainer?.shipmentName}`,
      body: NotificacionCargaEmail(exportationContainer),
    });
    setLoading(false);
    await handleMarcarTerminado(exportationContainer);
    successMessage('Correo enviado con exito');
  }

  const handleExpandRow = async (expanded, exportation) => {
    try {
      if (expanded) {
        setExpandedKey([exportation.key]);
        setLoadingDetails(true);
        const data = await getExportationDetailByContainerList(exportation.idExportationContainer);
        setLoadingDetails(false);
        setExportationDetails(data.list);
      } else {
        setExpandedKey([]);
        setExportationDetails([]);
      }
    } catch (ex) {
      setLoadingDetails(false);
      errorMessage(ex.toString());
    }
  }

  return(
    <>
      <Table size={'small'}
             loading={loading}
             columns={columns}
             scroll={{x: 1300, y: 600}}
             dataSource={exportationContainers}
             expandable={{
               onExpand: handleExpandRow,
               expandedRowKeys: expendadedKey,
               expandedRowRender: (record) => {
                 return(
                   <div>
                     <p className="mb-0 fw-bold">Notas de Carga:</p>
                     <p className="mb-0">{record.loadedNotes}</p>
                     <hr/>
                     <div className="d-none d-md-block">
                       <Spinner loading={loadingDetails}>
                         <DetalleExportationTable detalleExportations={exportationDetails} />
                       </Spinner>
                     </div>
                     <div className="d-block d-md-none">
                       {
                         (exportationDetails ?? []).map((detalle, index) => (
                           <div key={index}><DetalleExportInfo detalleExport={detalle} /><hr/></div>
                         ))
                       }
                     </div>
                   </div>
                 )
               }
             }}
             pagination={{
               position: ["bottomRight"],
               total: totalElements,
               showSizeChanger: true,
               showTotal: total => `${total} elementos`,
               current: filters.currentPage,
               pageSize: filters.pageCount,
               pageSizeOptions: [10, 20, 50, 100, 150, 200, 500],
               onChange: onPageChange
             }}
             footer={() => {
               // motrar la suma de la columna de total de vehiculos (vehicleCount)
               const total = exportationContainers?.reduce(
                 (acc, item) => acc + item?.numberItems, 0
               );

               return (
                 <div className="d-flex align-items-center">
                   <div className="my-2">
                     <p className="m-0 fw-bold">TOTAL DE VEHÍCULOS: {total}</p>
                     <ShowWhen show={fechaSeleccionada}>
                       <p className="m-0 fw-bold">FECHA: {fechaSeleccionada}</p>
                     </ShowWhen>
                   </div>
                 </div>
               );
             }}/>

      <DetailExportationDrawer open={open}
                               isLoading={loading}
                               onClose={() => {
                                 setOpen(false);
                                 setDataExportationDrawer(undefined);
                               }}
                               conear={() => handleConedExportationContainer(dataExportationDrawer)}
                               exportationContainer={dataExportationDrawer} />
    </>
  );
}

export default ExportationContainerTable;
