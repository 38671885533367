import React, { useState, useEffect } from "react";
import { Card } from "antd";
import ReactApexChart from "react-apexcharts";
import { totalRequestMount } from "../../../../Servicios/Home";
const RequestAnalysisAnnual = ({ user }) => {
  const [stateLineal, setStateLineal] = useState({
    series: [],
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "month",
        categories: [
          "Ene",
          "Feb",
          "Mar",
          "Abr",
          "May",
          "Jun",
          "Jul",
          "Ago",
          "Sep",
          "Oct",
          "Nov",
          "Dic",
        ],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            chart: {
              height: 350,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  const totalRequestMountData = async () => {
    const data = await totalRequestMount();
    data.reverse();

    const meses = data.map(x => x.monthName);
    const dataPerCountry = data.reduce((list, current) => {
      if (list.length === 0) {
        list = current.paises.map(e => {
          return {
            name: e.pais,
            data: [e.total]
          }
        })
      } else {
        current.paises.forEach((e, index) => {
          list[index].data.push(e.total);
        });
      }

      return list;
    }, []);

    setStateLineal({
      series: dataPerCountry,
      options: {
        chart: {
          height: 370,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "month",
          categories: meses,
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              chart: {
                height: 350,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            chart: {
              height: 350,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    });
  };

  useEffect(() => {
    if (user) totalRequestMountData();
  }, [user]);

  return (
    <Card
      title={`Analisis de Solicitudes - Ventas`}
      style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}
    >
      <div
        id="chart"
        style={{
          width: "100%",
        }}
      >
        <ReactApexChart
          options={stateLineal.options}
          series={stateLineal.series}
          type="area"
          height={525}
        />
      </div>
    </Card>
  );
};

export default RequestAnalysisAnnual;
