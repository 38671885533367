import React, { useEffect, useState } from "react";
import "../hojas-de-estilo/style.css";
import ClienteTable from "./ClienteTable/ClienteTable";
import {useNavigate, useParams} from "react-router-dom";
import { viewCountries } from "../Servicios/ComponedoresService";
import {Row, Col, Button} from "antd";
import { Content } from "antd/es/layout/layout";
import {validarPermisos} from "../Servicios/AuthService";
import {permisos} from "../utils/permisos";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEarthAmericas, faUserPlus} from "@fortawesome/free-solid-svg-icons";

const ClientePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);

  const puedeAgregarCliente = validarPermisos(permisos.AGREGAR_CLIENTE);
  const puedeAgregarImport = validarPermisos(permisos.AGREGAR_IMPORTADORA);

  useEffect(() => {
    viewCountries(id).then((pais) => {
      setCountries(pais);
    });
  }, [id]);

  return (
    <Content>
      <Row>
        <Col xs={24} md={24}>
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0">Cartera de Clientes - { countries?.nameCountry }</h5>
            <div className="d-flex align-items-center justify-content-end gap-2">
              {
                puedeAgregarCliente ?
                    <Button type={'primary'} onClick={() => navigate(`/addClient/${id}`)}>
                      <FontAwesomeIcon className="me-2" icon={faUserPlus}/> Crear Nuevo Cliente
                    </Button>
                    : <></>
              }
              {
                puedeAgregarImport ?
                    <Button type={'primary'} onClick={() => navigate('/importer')}>
                      <FontAwesomeIcon className="me-2" icon={faEarthAmericas}/> Ver/Agregar Importadoras
                    </Button> : <></>
              }
            </div>
          </div>
          <hr/>
        </Col>
      </Row>
      <ClienteTable />
    </Content>
  );
};

export default ClientePage;
