import {Button, Col, Form, InputNumber, Row} from "antd";
import {useEffect} from "react";
import TextArea from "antd/lib/input/TextArea";

const DetalleExportationBillLadingForm = ({ detalleExport, onSubmit }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (detalleExport) {
      form.setFieldsValue({
        blFlete: detalleExport?.blFlete,
        blGrua: detalleExport?.blGrua,
        blQuantity: detalleExport?.blQuantity ?? 1,
        blDescription: detalleExport?.blDescription ?? `${detalleExport?.werehouse?.vehiculoName}\nVIN: ${detalleExport?.werehouse?.vin}`,
        blWeight: detalleExport?.blWeight ?? +(detalleExport?.werehouse?.weight ?? 0).toFixed(2),
        volumen: +(detalleExport?.werehouse?.volumeMtq ?? 0).toFixed(2),
      });
    } else {
      form.resetFields();
    }
  }, [detalleExport]);

  const handleFinish = (values) => {
    onSubmit({
      blDescription: values.blDescription,
      blFlete: values.blFlete ?? 0, // 0: no mostrar en BL
      blGrua: values.blGrua ?? 0, // 0: no mostrar en BL
      blQuantity: values.blQuantity ?? 0, // 0: no mostrar en BL
      blWeight: values.blWeight ?? 0, // 0: no mostrar en BL
      volumenMtq: values.volumenMtq
    });
  }

  return(
    <>
      <Form form={form} layout={'vertical'} onFinish={handleFinish}>
        <p className="mb-0 fw-bold">Descripción</p>
        <hr/>
        <Form.Item name={'blQuantity'} label={'Cantidad'}>
          <InputNumber className="w-100"/>
        </Form.Item>
        <Form.Item name={'blDescription'}
                   label={'Descripción'}
                   help={<>
                     <p className="mb-0">Este campo hace referencia a la sección 'DESCRIPTION OF COMMODITIES'</p>
                     <p className="mb-0">Separe por lineas si quiere incluir mas de un item a la vez</p>
                     <p className="mb-0">Este campo actualiza tanto el BL individual como el BL Master</p>
                   </>}>
          <TextArea className="w-100" rows={4} />
        </Form.Item>
        <Row gutter={16}>
          <Col sm={12}>
            <Form.Item name={'blWeight'} label={'Peso (Kg)'}>
              <InputNumber className="w-100"/>
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item name={'volumenMtq'} label={'Volumen (Metros Cubicos)'}>
              <InputNumber className="w-100"/>
            </Form.Item>
          </Col>
        </Row>
        <p className="mb-0 fw-bold">Costos</p>
        <hr/>
        <Row gutter={16}>
          <Col sm={12}>
            <Form.Item name={'blFlete'} label={'Flete'}>
              <InputNumber className="w-100"/>
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item name={'blGrua'} label={'Grua'}>
              <InputNumber className="w-100"/>
            </Form.Item>
          </Col>
        </Row>
        <Button type={'primary'} htmlType={'submit'}>Guardar</Button>
      </Form>
    </>
  );
}

export default DetalleExportationBillLadingForm;
