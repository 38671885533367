import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Button as ButtonAnt} from "antd";
import InputGroup from "react-bootstrap/InputGroup";
import React, {useEffect, useState} from "react";
import {showAduana, showCountries, showSegmento} from "../Servicios/CombosService";
import {addClient, deleteClient, editClient} from "../Servicios/ClienteService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFloppyDisk, faXmark} from "@fortawesome/free-solid-svg-icons";
import {toast, ToastContainer} from "react-toastify";
import {Col} from "react-bootstrap";
import {ClienteTypeEnum} from "../shared/enums/cliente-type.enum";
import Spinner from "../shared/spinner";
import {errorMessage, successMessage} from "../utils/toast-message";
import ImportadoraForm from "../Componentes/ImportadoraForm/ImportadoraForm";
import InputSearchImportadora from "../Componentes/InputSearchImportadora/InputSearchImportadora";
import {ClienteClassPay} from "../shared/constants/cliente-class-pay.const";
import {getUserSellers} from "../Servicios/UsuarioService";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import {hasRol, validarPermisos} from "../Servicios/AuthService";
import {permisos} from "../utils/permisos";
import roles from "../utils/roles";
import EliminarClienteBtn from "./EliminarClienteBtn";
import {Modal} from "antd";

const moment = require('moment');
const now = moment().format();

const newCustomer = {
  id: "",
  segmentoId: "0",
  aduanaId: "0",
  asignacion: "",
  importadoraId: "",
  name1: "",
  name2: "",
  apellido1: "",
  apellido2: "",
  identificacion: "",
  expIdent: null,
  contNum: "",
  email: "",
  numbCop: 0,
  numbIaai: 0,
  userCop: "",
  passCop: "",
  fechaCreacion: now,
  noRegistro: "",
  poa: false,
  venciPoa: null,
  numDos: "",
  coDos: "",
  adjuntos: "",
  comercialName: "",
  buyDealer: false,
  merchGen: false,
  userIaai: "",
  passIaai: "",
  birth: null,
  countryId: "0",
  classPay: "0",
  clienteVip: false,
  asignacionId: "0"
};

const ClienteForm = ({ cliente, importadora, countryId, shortSaved, finalClient, submitCliente, onCancel, onDeleteCliente, showDeleteClient }) => {
  const today = new Date();
  const fechaActual = (`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`);
  const puedeModificarCliente = validarPermisos(permisos.MODIFICAR_CLIENTE);

  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [segmentos, setSegmentos] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [aduanas, setAduana] = useState([]);

  const [importadoraSeleccionada, setImportadoraSeleccionada] = useState(null);

  const [isSubmitted, setSubmitted] = useState(false);
  const [showImportadoraModal, setShowImportadoraModal] = useState(false);
  const [showModificarImportadoraModal, setModificarShowImportadoraModal] = useState(false);
  
  const [nuevoCliente, setNuevoCliente] = useState(true);
  const [clienteSeleccionado, setClienteSeleccionado] = useState({...newCustomer});

  const [segmentoDefault, setSegmentoDefault] = useState(undefined);

  const puedeEliminarCliente = validarPermisos(permisos.ELIMINAR_CLIENTE);

  useEffect(() => {
    if (importadora) {
      setImportadoraSeleccionada(importadora);
    }
  }, [importadora]);

  useEffect(() => {
    inicializar().then(() => {
      if (cliente) {
        setNuevoCliente(false);

        setClienteSeleccionado({
          id: cliente.id,
          segmentoId: cliente.segmentoId,
          classPay: cliente.classPay,
          aduanaId: cliente.aduanaId,
          asignacion: cliente.asignacion,
          importadoraId: cliente.importadoraId,
          name1: cliente.name1,
          name2: cliente.name2,
          apellido1: cliente.apellido1,
          apellido2: cliente.apellido2,
          identificacion: cliente.identificacion,
          expIdent: cliente.expIdent,
          contNum: cliente.contNum,
          email: cliente.email,
          numbCop: cliente.numbCop,
          numbIaai: cliente.numbIaai,
          userCop: cliente.userCop,
          passCop: cliente.passCop,
          fechaCreacion: cliente.fechaCreacion,
          noRegistro: cliente.noRegistro,
          poa: cliente.poa,
          venciPoa: cliente.venciPoa,
          numDos: cliente.numDos,
          coDos: cliente.coDos,
          adjuntos: cliente.adjuntos,
          comercialName: cliente.comercialName,
          buyDealer: cliente.buyDealer,
          merchGen: cliente.merchGen,
          userIaai: cliente.userIaai,
          passIaai: cliente.passIaai,
          birth: cliente.birth,
          countryId: cliente.countryId.toString(),
          clienteVip: cliente.clienteVip ?? false,
          asignacionId: cliente.asignacionId,
        });

        setImportadoraSeleccionada(cliente.importadora);
      }
    });
  }, [cliente]);

  useEffect(() => {
    if (countryId && countryId !== 0) {
      setClienteSeleccionado({
        ...clienteSeleccionado,
        countryId: countryId.toString()
      });
    }
  }, [countryId]);

  useEffect(() => {
    if (segmentoDefault && clienteSeleccionado.segmentoId === '0') {
      setClienteSeleccionado({
        ...clienteSeleccionado,
        segmentoId: segmentoDefault?.id?.toString() ?? '0'
      });
    }
  }, [segmentoDefault]);

  const inicializar = async () => {
    await loadCountries();
    await loadSegmentos();
    await loadSellers();
    await loadAduana();
  }

  const loadSellers = async () => {
    const data = await getUserSellers();
    setSellers(data);
  }

  const loadCountries = async () => {
    const data = await showCountries();
    setCountries(data.filter(x => !x.defaultValue));
  }

  const loadSegmentos = async () => {
    const data = await showSegmento();
    setSegmentos(data);
    setSegmentoDefault(data.find(e => e.isDefault));
  }

  const loadAduana = async () => {
    const data = await showAduana();
    setAduana(data);
  }

  const handleCloseA = () => {
    setShowImportadoraModal(false);
    setModificarShowImportadoraModal(false);
  };

  const handleShowA = () => setShowImportadoraModal(true);

  const handleChange = e => {
    const {name, value} = e.target;

    const cliente = {
      ...clienteSeleccionado,
      [name]: value
    }

    if (name === 'asignacionId') {
      const user = sellers.find(e => +e.idUser === +value);
      cliente.asignacion = `${user.firstName} ${user.lastName}`;
    }

    setClienteSeleccionado(cliente);
  }

  const handleOnChangeP = (e) => {
    setClienteSeleccionado({
      ...clienteSeleccionado,
      poa: e.target.checked,
    });
  }

  const handleOnChangeClienteVip = (e) => {
    setClienteSeleccionado({
      ...clienteSeleccionado,
      clienteVip: e.target.checked,
    });
  }

  const handleSelectChange = (importadora) => {
    const temp = {...clienteSeleccionado, importadoraId: importadora.id};
    setClienteSeleccionado(temp)
    setImportadoraSeleccionada(importadora);
  }

  const handleOnChangeD = (e) => {
    setClienteSeleccionado({
      ...clienteSeleccionado,
      buyDealer: e.target.checked,
    });
  }
  const handleOnChangeM = (e) => {
    setClienteSeleccionado({
      ...clienteSeleccionado,
      merchGen: e.target.checked,
    });
  }

  const notify = () => toast.success("Cliente guardado correctamente", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const notifyImp = () => toast.success("Importadora guardada correctamente", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const formularioEsValido = () => {
    if (shortSaved) {
      return clienteSeleccionado.name1 !== '' &&
        clienteSeleccionado.apellido1 !== '' &&
        (importadora || clienteSeleccionado.importadoraId !== 0) &&
        clienteSeleccionado.countryId !== '0';
    } else {
      return clienteSeleccionado.name1 !== '' &&
        clienteSeleccionado.apellido1 !== '' &&
        (importadora || clienteSeleccionado.importadoraId !== 0) &&
        clienteSeleccionado.aduanaId !== '0' &&
        clienteSeleccionado.segmentoId !== '0' &&
        clienteSeleccionado.countryId !== '0';
    }

  }

  const editar = async () => {
    setSubmitted(true);
    if (!formularioEsValido()) {
      return;
    }

    // validar correo
    if (clienteSeleccionado.email !== "" && (clienteSeleccionado.email.split("@").length - 1) > 1) {
      errorMessage("Por favor, en el campo 'Correo Principal' colocar un solo correo electrónico");
      return;
    }

    setIsLoading(true);
    const data = await editClient(cliente.id, {
      ...clienteSeleccionado,
      apellido1: clienteSeleccionado.apellido1 ?? "",
      numbCop: +clienteSeleccionado.numbCop,
      numbIaai: +clienteSeleccionado.numbIaai,
      segmentoId: +clienteSeleccionado.segmentoId,
      importadoraId: +clienteSeleccionado.importadoraId,
      aduanaId: +clienteSeleccionado.aduanaId,
      countryId: +clienteSeleccionado.countryId,
      asignacionId: clienteSeleccionado.asignacionId === '0' || clienteSeleccionado.asignacionId === 0 || clienteSeleccionado.asignacionId === null
        ? null
        : +clienteSeleccionado.asignacionId,
      type: finalClient
        ? ClienteTypeEnum.typeFinalCustomer
        : ClienteTypeEnum.typeCustomer
    });

    setIsLoading(false);
    if (data && data.status) {
      submitCliente(data.data);
      setSubmitted(false);
      setClienteSeleccionado(newCustomer);
    } else {
      errorMessage(data.message);
    }
  }

  const insertar = async () => {
    try {
      setSubmitted(true);

      let segmentoId = clienteSeleccionado.segmentoId ? +clienteSeleccionado.segmentoId : null;
      let aduanaId = clienteSeleccionado.aduanaId ? +clienteSeleccionado.aduanaId : null;

      if (shortSaved) {
        const segmento = segmentos.find(e => e.segName === "CLIENTE PARTICULAR");
        const aduana = aduanas.find(e => e.nameAduana === "SIN ESPECIFICAR" && e.countryId === +clienteSeleccionado.countryId);

        if (segmento) segmentoId = segmento.id;
        if (aduana) aduanaId = aduana.id;

        setClienteSeleccionado({
          ...clienteSeleccionado,
          segmentoId: segmento ? segmento.id.toString() : 0,
          aduanaId: aduana ? aduana.id.toString() : 0
        });
      }

      if (!formularioEsValido()) {
        return;
      }

      // validar correo
      if (clienteSeleccionado.email !== "" && (clienteSeleccionado.email.split("@").length - 1) > 1) {
        errorMessage("Por favor, en el campo 'Correo Principal' colocar un solo correo electrónico");
        return;
      }

      setIsLoading(true);
      const data = await addClient({
        ...clienteSeleccionado,
        numbCop: +clienteSeleccionado.numbCop,
        numbIaai: +clienteSeleccionado.numbIaai,
        segmentoId: segmentoId,
        importadoraId: importadoraSeleccionada
          ? importadoraSeleccionada.id
          : +clienteSeleccionado.importadoraId,
        aduanaId: aduanaId,
        countryId: +clienteSeleccionado.countryId,
        asignacionId: +clienteSeleccionado.asignacionId,
        type: finalClient
          ? ClienteTypeEnum.typeFinalCustomer
          : ClienteTypeEnum.typeCustomer
      });

      notify();
      setIsLoading(false);
      submitCliente(data);
      setSubmitted(false);
      setClienteSeleccionado(newCustomer);
    } catch (ex) {
      setIsLoading(false);
      errorMessage(ex.toString());
    }
  }

  const handleEliminarCliente = async () => {
    setIsLoading(true);
    const data = await deleteClient(cliente.id);
    setIsLoading(false);

    if (data && data.status) {
      successMessage('El cliente ha sido eliminado');
      onDeleteCliente();
    } else {
      errorMessage(data.message);
    }
  }

  const onSubmit = () => {
    if (!nuevoCliente) {
      editar().then();
    } else {
      insertar().then();
    }
  }

  const handleSubmitImportadora = (importadora) => {
    notifyImp();
    handleCloseA();

    setImportadoraSeleccionada(importadora);
    setClienteSeleccionado({...clienteSeleccionado, importadoraId: importadora.id})
  }

  return(
    <>
      <Spinner loading={isLoading}>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Informacion de Cliente</Accordion.Header>
            <Accordion.Body>
              {
                !shortSaved &&
                <Row>
                  <div className="col-lg-3 col-md-6 col-sm-12 mt-lg-0 mt-md-0 mt-0">
                    <Form.Label className='text-aling-left'>Fecha de Creación</Form.Label>
                    <Form.Control disabled
                                  readOnly
                                  name='fechaCreacion'
                                  value={!nuevoCliente ? clienteSeleccionado.fechaCreacion : fechaActual}
                                  type="text"
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"/>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 mt-lg-0 mt-md-3 mt-3">
                    <Form.Label className='text-aling-left'>Fecha vencimiento POA</Form.Label>
                    <Form.Control onChange={handleChange} name='venciPoa'
                                  value={clienteSeleccionado ? clienteSeleccionado?.venciPoa : ''} type="date"
                                  aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-lg-0 mt-md-3 mt-3">
                    <div className="d-flex gap-5 mt-4">
                      <Form.Check
                        label="POA"
                        name='poa'
                        checked={clienteSeleccionado && clienteSeleccionado?.poa}
                        onChange={handleOnChangeP}
                        type="checkbox"
                        id="poa"/>

                      <Form.Check
                        label="Cliente Vip"
                        name='clienteVip'
                        checked={clienteSeleccionado && clienteSeleccionado?.clienteVip}
                        onChange={handleOnChangeClienteVip}
                        type="checkbox"
                        id="clienteVip"/>
                    </div>
                  </div>
                </Row>
              }
              <Row>
                <ShowWhen show={!shortSaved}>
                  <div className={'col-lg-3 col-md-6 col-sm-12 mt-3'}>
                    <Form.Label
                      className={`text-aling-left ${isSubmitted && (clienteSeleccionado?.segmentoId === '' || clienteSeleccionado?.segmentoId === 0) ? 'required' : ''}`}>
                      Segmento
                    </Form.Label>
                    <Form.Select onChange={handleChange}
                                 name='segmentoId'
                                 value={clienteSeleccionado ? clienteSeleccionado?.segmentoId : '0'}
                                 aria-label="Small"
                                 aria-describedby="inputGroup-sizing-sm">
                      <option disabled value={'0'}>Seleccione</option>
                      {
                        segmentos && segmentos.length && segmentos.map((segmento, index) => {
                          return <option key={index} value={segmento.id}>{segmento.segName}</option>
                        })
                      }
                    </Form.Select>
                    {
                      isSubmitted && (clienteSeleccionado?.segmentoId === '0' || clienteSeleccionado?.segmentoId === 0) &&
                      <p className={`m-0 font-12 text-danger font-weight-bold`}>Campo Requerido</p>
                    }
                  </div>
                </ShowWhen>
                <div className={shortSaved ? 'col-lg-6 col-md-6 col-sm-12 mt-3' : 'col-lg-3 col-md-6 col-sm-12 mt-3'}>
                  <Form.Label className='text-aling-left'>Clasificacion de Pago</Form.Label>
                  <Form.Select onChange={handleChange}
                               aria-label="Small"
                               name='classPay'
                               value={clienteSeleccionado ? clienteSeleccionado?.classPay : '0'}
                               disabled={!nuevoCliente && !(puedeModificarCliente && hasRol(roles.COBROS, roles.ADMIN))}
                               aria-describedby="inputGroup-sizing-sm">
                    <option disabled value="0">Seleccione</option>
                    {ClienteClassPay.map(e => <option key={e.value} value={e.value}>{e.label}</option>)}
                  </Form.Select>
                </div>
                <ShowWhen show={!shortSaved}>
                  <div className={'col-lg-3 col-md-6 col-sm-12 mt-3'}>
                    <Form.Label
                      className={`text-aling-left ${isSubmitted && (clienteSeleccionado?.aduanaId === '' || clienteSeleccionado?.aduanaId === 0) ? 'required' : ''}`}>
                      Aduana de Preferencia
                    </Form.Label>
                    <Form.Select onChange={handleChange}
                                 name='aduanaId'
                                 value={clienteSeleccionado ? clienteSeleccionado?.aduanaId : '0'}
                                 aria-label="Small"
                                 aria-describedby="inputGroup-sizing-sm">
                      <option disabled value="0">Seleccione</option>
                      {
                        aduanas && aduanas.length && aduanas.map((aduanas, index) => {
                          return <option key={index} value={aduanas.id}>{aduanas.nameAduana}</option>
                        })
                      }
                    </Form.Select>
                    {
                      isSubmitted && (clienteSeleccionado?.aduanaId === '0' || clienteSeleccionado?.aduanaId === 0) &&
                      <p className={`m-0 font-12 text-danger font-weight-bold`}>Campo Requerido</p>
                    }
                  </div>
                </ShowWhen>
                <ShowWhen show={!shortSaved}>
                  <div className="col-lg-3 col-md-6 col-sm-12 mt-3">
                    <Form.Label className='text-aling-left'>Asignación</Form.Label>
                    <Form.Select onChange={handleChange}
                                 aria-label="Small"
                                 name='asignacionId'
                                 disabled={!nuevoCliente && !(puedeModificarCliente && hasRol(roles.SUPERVISOR, roles.ADMIN))}
                                 value={clienteSeleccionado && clienteSeleccionado?.asignacionId}
                                 aria-describedby="inputGroup-sizing-sm">
                      <option disabled value="0">Seleccione</option>
                      {
                        sellers.map(e => <option value={e.idUser}>{e.firstName} {e.lastName}</option>)
                      }
                    </Form.Select>
                  </div>
                </ShowWhen>
              </Row>
              <Row>
                {
                  !importadora &&
                  <div className={"col-md-12 mt-3"}>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <Form.Label className='text-aling-left m-0'>Nombre Importadora</Form.Label>
                      <div className="d-flex gap-2">
                        <Button onClick={handleShowA} className="btn-sm">Agregar Nueva</Button>
                        <Button disabled={!importadoraSeleccionada}
                                onClick={() => setModificarShowImportadoraModal(true)}
                                className="btn-sm">Modificar</Button>
                      </div>
                    </div>
                    <InputSearchImportadora selectedImportadora={importadoraSeleccionada}
                                            onSelectImportadora={handleSelectChange}/>
                    {
                      isSubmitted && (clienteSeleccionado.importadoraId === '' || clienteSeleccionado.importadoraId === 0) &&
                      <p className={`m-0 font-12 text-danger font-weight-bold`}>Campo Requerido</p>
                    }
                  </div>
                }
                {
                  !shortSaved &&
                  <>
                    <div className="col-md-12 mt-3">
                      <br/>
                      <Form.Check
                        label="Compra con Cuenta Dealer"
                        name='buyDealer'
                        checked={clienteSeleccionado && clienteSeleccionado?.buyDealer}
                        onChange={handleOnChangeD}
                        type="checkbox"
                      />
                    </div>
                    <div className="col-md-12">
                      <br/>
                      <Form.Check
                        label="Envia Mercaderia General"
                        name='merchGen'
                        checked={clienteSeleccionado && clienteSeleccionado?.merchGen}
                        onChange={handleOnChangeM}
                        type="checkbox"
                      />
                    </div>
                  </>
                }
              </Row>
              <Row>
                {
                  !shortSaved &&
                  <div className="col-lg-6 col-sm-12 mt-3">
                    <Form.Label className='text-aling-left'>Nombre Comercial/Negocio</Form.Label>
                    <Form.Control onChange={handleChange} name='comercialName'
                                  value={clienteSeleccionado && clienteSeleccionado?.comercialName}
                                  aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                  </div>
                }
                {
                  !shortSaved &&
                  <div className="col-lg-3 col-md-6 col-sm-12 mt-3">
                    <Form.Label className='text-aling-left'>Numero Registro</Form.Label>
                    <Form.Control onChange={handleChange} name='noRegistro'
                                  value={clienteSeleccionado && clienteSeleccionado?.noRegistro}
                                  aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                  </div>
                }

                <div className={shortSaved ? 'col-lg-6 col-md-6 col-sm-12 mt-3' : 'col-lg-3 col-md-6 col-sm-12 mt-3'}>
                  <Form.Label className='text-aling-left'>País</Form.Label>
                  <Form.Select onChange={handleChange}
                               name='countryId'
                               value={clienteSeleccionado ? clienteSeleccionado?.countryId : '0'}
                               aria-label="Small"
                               aria-describedby="inputGroup-sizing-sm">
                    <option disabled value="0">Seleccione</option>
                    {
                      countries && countries.length && countries.map((country, index) => {
                        return <option key={'country-' + index} value={country.id}>{country.id} {country.nameCountry}</option>
                      })
                    }
                  </Form.Select>
                  {
                    isSubmitted && (clienteSeleccionado.countryId === '0' || clienteSeleccionado.countryId === 0) &&
                    <p className={`m-0 font-12 text-danger font-weight-bold`}>Campo Requerido</p>
                  }
                </div>
              </Row>
              <Row>
                <div className={shortSaved ? 'col-lg-6 col-md-6 col-sm-12 mt-3' : 'col-lg-3 col-md-6 col-sm-12 mt-3'}>
                  <Form.Label className='text-aling-left'>Primer Nombre</Form.Label>
                  <Form.Control onChange={handleChange} name='name1'
                                value={clienteSeleccionado && clienteSeleccionado?.name1} aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"/>
                  {
                    isSubmitted && (clienteSeleccionado?.name1 === '') &&
                    <p className={`m-0 font-12 text-danger font-weight-bold`}>Campo Requerido</p>
                  }
                </div>
                {
                  !shortSaved &&
                  <div className="col-lg-3 col-md-6 col-sm-12 mt-3">
                    <Form.Label className='text-aling-left'>Segundo Nombre</Form.Label>
                    <Form.Control onChange={handleChange} name='name2'
                                  value={clienteSeleccionado && clienteSeleccionado?.name2} aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"/>
                  </div>
                }
                <div className={shortSaved ? 'col-lg-6 col-md-6 col-sm-12 mt-3' : 'col-lg-3 col-md-6 col-sm-12 mt-3'}>
                  <Form.Label className='text-aling-left'>Primer Apellido</Form.Label>
                  <Form.Control onChange={handleChange} name='apellido1'
                                value={clienteSeleccionado && clienteSeleccionado?.apellido1}
                                aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                  {
                    isSubmitted && (clienteSeleccionado?.apellido1 === '') &&
                    <p className={`m-0 font-12 text-danger font-weight-bold`}>Campo Requerido</p>
                  }
                </div>
                {
                  !shortSaved &&
                  <div className="col-lg-3 col-md-6 col-sm-12 mt-3">
                    <Form.Label className='text-aling-left'>Segundo Apellido</Form.Label>
                    <Form.Control onChange={handleChange} name='apellido2'
                                  value={clienteSeleccionado && clienteSeleccionado?.apellido2}
                                  aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                  </div>
                }
              </Row>
              <Row>
                <div className={shortSaved ? "col-lg-6 col-md-6 col-sm-12 mt-3" : "col-lg-3 col-md-6 col-sm-12 mt-3"}>
                  <Form.Label className='text-aling-left'>Numero de ID</Form.Label>
                  <Form.Control onChange={handleChange} name='identificacion'
                                value={clienteSeleccionado ? clienteSeleccionado?.identificacion : ''}
                                aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                </div>
                {
                  !shortSaved &&
                  <>
                    <div className={"col-lg-3 col-md-6 col-sm-12 mt-3"}>
                      <Form.Label className='text-aling-left'>Fecha vencimiento ID</Form.Label>
                      <Form.Control onChange={handleChange} name='expIdent'
                                    value={clienteSeleccionado ? clienteSeleccionado?.expIdent : ''} type="date"
                                    aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mt-3">
                      <Form.Label className='text-aling-left'>Cumpleaños</Form.Label>
                      <Form.Control onChange={handleChange} name='birth'
                                    value={clienteSeleccionado ? clienteSeleccionado?.birth : ''} type="date"
                                    aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                    </div>
                  </>
                }
              </Row>
              {
                !shortSaved &&
                <Row>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                    <Form.Label className='text-aling-left'>No. Buyer Copart</Form.Label>
                    <Form.Control onChange={handleChange} name='numbCop'
                                  value={clienteSeleccionado && clienteSeleccionado?.numbCop}
                                  aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                    <Form.Label className='text-aling-left'>No. Buyer IAAI</Form.Label>
                    <Form.Control onChange={handleChange} name='numbIaai'
                                  value={clienteSeleccionado && clienteSeleccionado?.numbIaai}
                                  aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                  </div>
                </Row>
              }
              <Row>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                  <Form.Label className='text-aling-left'>Numero de Contacto Principal</Form.Label>
                  <InputGroup size="sm">
                    <InputGroup.Text id="inputGroup-sizing-sm">#1</InputGroup.Text>
                    <Form.Control onChange={handleChange} name='contNum'
                                  value={clienteSeleccionado && clienteSeleccionado?.contNum}
                                  aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                  </InputGroup>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                  <Form.Label className='text-aling-left'>Numero de Contacto Adicional</Form.Label>
                  <InputGroup size="sm">
                    <InputGroup.Text id="inputGroup-sizing-sm">#2</InputGroup.Text>
                    <Form.Control onChange={handleChange} name='numDos'
                                  value={clienteSeleccionado && clienteSeleccionado?.numDos}
                                  aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                  </InputGroup>
                </div>
              </Row>
              <Row>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                  <Form.Label className='text-aling-left'>Correo Principal</Form.Label>
                  <InputGroup size="sm">
                    <InputGroup.Text id="inputGroup-sizing-sm">@1</InputGroup.Text>
                    <Form.Control onChange={handleChange} name='email'
                                  value={clienteSeleccionado && clienteSeleccionado?.email}
                                  aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                  </InputGroup>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                  <Form.Label className='text-aling-left'>Correo Secundario</Form.Label>
                  <InputGroup size="sm">
                    <InputGroup.Text id="inputGroup-sizing-sm">@2</InputGroup.Text>
                    <Form.Control onChange={handleChange} name='coDos'
                                  value={clienteSeleccionado && clienteSeleccionado?.coDos}
                                  aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                  </InputGroup>
                </div>
              </Row>
              {
                !shortSaved &&
                <Row>
                  <div className="col-lg-6 col-md-12 col-sm-12 mt-3">
                    <Form.Label className='text-aling-left'>Link de Archivos Adjuntos</Form.Label>
                    <Form.Control onChange={handleChange} name='adjuntos'
                                  value={clienteSeleccionado && clienteSeleccionado?.adjuntos}
                                  aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                  </div>
                </Row>
              }
              {
                finalClient &&
                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                  <Form.Label className='text-aling-left'>Dirección</Form.Label>
                  <Form.Control onChange={handleChange}
                                rows={3}
                                as="textarea"
                                name='address'
                                value={clienteSeleccionado ? clienteSeleccionado?.address : ''}
                                aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                </div>
              }
            </Accordion.Body>
          </Accordion.Item>
          {
            !shortSaved &&
            <Accordion.Item eventKey="1">
              <Accordion.Header>Accesos</Accordion.Header>
              <Accordion.Body>
                <div className='general'>

                  <div className="row">
                    <div className="col-md-4">
                      <h4>Accesos Copart</h4>
                      <Form.Label className='text-aling-left'>Usuario</Form.Label>
                      <Form.Control onChange={handleChange} name='userCop'
                                    value={clienteSeleccionado && clienteSeleccionado?.userCop} aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <Form.Label className='text-aling-left'>Contraseña</Form.Label>
                      <Form.Control onChange={handleChange} name='passCop'
                                    value={clienteSeleccionado && clienteSeleccionado?.passCop} aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <h4>Accesos IAAI</h4>
                      <Form.Label className='text-aling-left'>Usuario</Form.Label>
                      <Form.Control onChange={handleChange} name='userIaai'
                                    value={clienteSeleccionado && clienteSeleccionado?.userIaai} aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <Form.Label className='text-aling-left'>Contraseña</Form.Label>
                      <Form.Control onChange={handleChange} name='passIaai'
                                    value={clienteSeleccionado && clienteSeleccionado?.passIaai} aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"/>
                    </div>
                  </div>

                </div>
              </Accordion.Body>
            </Accordion.Item>
          }
        </Accordion>

        <Row className="justify-content-md-center">
          <Col xs={12} lg="3">
            {
              isSubmitted && (!formularioEsValido()) &&
              <p className={`m-0 font-12 text-danger font-weight-bold`}>**Verificar Campos Requeridos**</p>
            }
          </Col>
        </Row>

        <div className='text-center mt-3'>
          <ShowWhen show={showDeleteClient && puedeEliminarCliente}>
            <EliminarClienteBtn onCancelar={handleEliminarCliente} />
          </ShowWhen>
          <ButtonAnt className="ms-3 me-3" onClick={() => {
            setSubmitted(false);
            setClienteSeleccionado({...newCustomer});
            onCancel();
          }} danger type={'primary'}>
            <FontAwesomeIcon icon={faXmark}/>&nbsp;Cancelar
          </ButtonAnt>
          <ButtonAnt onClick={() => onSubmit()} type={'primary'}>
            <FontAwesomeIcon icon={faFloppyDisk}/>&nbsp;Guardar
          </ButtonAnt>
          <ToastContainer/>
        </div>
      </Spinner>

      <Modal
        open={showImportadoraModal || showModificarImportadoraModal}
        title={showModificarImportadoraModal ? 'Modificar Importadora' : 'Agregar Nueva Importadora'}
        onCancel={handleCloseA}
        okButtonProps={{style: {display: "none"}}}
        cancelButtonProps={{style: {display: "none"}}}>
          <ImportadoraForm importadora={showModificarImportadoraModal ? importadoraSeleccionada : undefined}
                           onCancel={handleCloseA}
                           afterSubmit={handleSubmitImportadora} />
      </Modal>
    </>
  );
}

export default ClienteForm;
