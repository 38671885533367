import {Button, Space, Table, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {getPortCode} from "../../Servicios/ExportationServices";
import InputSearch from "../../Componentes/InputSearch/InputSearch";
import {EditOutlined} from "@ant-design/icons";
import PortCodeForm from "./PortCodeForm";
import LoadingSpinner from "../../Componentes/LoadingSpinner";

const PortCodePage = () => {
  const [portCodeList, setPortCodeList] = useState([]);
  const [filterPortCodeList, setFilterPortCodeList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedPort, setSelectedPort] = useState(undefined);

  const columns = [
    { title: 'Port', dataIndex: 'port', key: 'port', width: 180 },
    { title: 'Country', dataIndex: 'country', key: 'country', width: 180 },
    { title: 'State', dataIndex: 'countryState', key: 'country', width: 100 },
    { title: 'Name', dataIndex: 'name', key: 'name', width: 180 },
    { title: 'Type', dataIndex: 'typeState', key: 'typeState', width: 180 },
    { title: 'Port Unlocode', dataIndex: 'portUnlocode', key: 'portUnlocode', width: 180 },
    {
      title: 'Options',
      key: 'options',
      dataIndex: 'options',
      width: 100,
      render: (_, element) => (
        <>
          <Space size="middle" align="center">
            <Tooltip title="Edit">
              <Button type="primary" onClick={() => setSelectedPort(element)}><EditOutlined /></Button>
            </Tooltip>
          </Space>
        </>
      )
    }
  ];

  useEffect(() => {
    init().then();
  }, []);

  const init = async () => {
    setLoading(true);
    const data = await getPortCode();
    setLoading(false);

    setPortCodeList(data);
    setFilterPortCodeList(data);
  }

  const handleInputSearch = (event) => {
    const data = portCodeList
      .filter(e => event === "" || `${e.port}-${e.name}-${e.portUnlocode}`.toLowerCase().indexOf(event.toLowerCase()) !== -1);

    setFilterPortCodeList(data);
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return(
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
        <h5 className="m-0">Port Code</h5>
        <Button type="primary" onClick={() => setOpenModal(true)}>Add new Port Code</Button>
      </div>

      <div className="mb-3">
        <InputSearch placeholder={'Search for port or name'}
                     doChange={handleInputSearch}/>
      </div>

      <Table dataSource={filterPortCodeList}
             columns={columns}/>

      <PortCodeForm open={openModal || selectedPort}
                    portCode={selectedPort}
                    onCancel={() => {
                      setOpenModal(false);
                      setSelectedPort(undefined);
                    }}
                    onSave={() => {
                      setOpenModal(false);
                      setSelectedPort(undefined);

                      init().then();
                    }} />
    </>
  );
}

export default PortCodePage;
